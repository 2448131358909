import React, { Fragment } from 'react'
import { Grid } from 'semantic-ui-react'
import Tags from '../Resources/Tags'
import InternalAdvisorTags from '../Advisors/AdvisorTags'

const AdvisorTags = ({ advisor }) => {
    return (
        <Grid.Row>
            <Grid.Column>
                <Grid.Row>Advisor tags: </Grid.Row>
                <Grid.Row>
                    <Tags
                        tags={advisor.expert.functions.concat(
                            advisor.expert.organization.type,
                            advisor.expert.medical_specialties,
                            advisor.expert.technology_tags
                        )}
                        internal_tags={[]}
                    />
                </Grid.Row>
                <Fragment>
                    <Grid.Row>Analyst tags: </Grid.Row>
                    <Grid.Row>
                        <InternalAdvisorTags
                            advisor={advisor}
                            showInternalOnly={true}
                        />
                    </Grid.Row>
                </Fragment>
            </Grid.Column>
        </Grid.Row>
    )
}

export default AdvisorTags
