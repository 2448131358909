import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react'
import { Form, Popup, Icon } from 'semantic-ui-react'
import { convertRichTextToPlainText } from '../../../utils//helpers'

const Details = (props, ref) => {
    const { project } = props

    const [keywords, setKeywords] = useState([])
    const [description, setDescription] = useState('')

    useEffect(() => {
        if (project.keywords) {
            setKeywords(
                project.keywords.map((value) => ({
                    key: value,
                    text: value,
                    value,
                }))
            )
        }
        if (project.description) {
            setDescription(convertRichTextToPlainText(project.description))
        }
    }, [project])

    useImperativeHandle(ref, () => {
        return {
            state: { keywords, description },
        }
    })

    return (
        <>
            <Form.Dropdown
                label={
                    <label>
                        Project tags
                        <Popup
                            content="Project tags are used to push relevant experts to the top of the list"
                            trigger={
                                <Icon
                                    name="info circle"
                                    style={{ marginLeft: '10px' }}
                                />
                            }
                        />
                    </label>
                }
                options={keywords}
                icon={null}
                placeholder="Enter project tags"
                selection
                search
                fluid
                multiple
                allowAdditions
                noResultsMessage={null}
                value={keywords.map((obj) => obj.value)}
                onChange={(e, { value }) =>
                    setKeywords(
                        value.map((item) => ({
                            key: item,
                            text: item,
                            value: item,
                        }))
                    )
                }
            />
            <Form.TextArea
                required
                label="Project details"
                placeholder="Please briefly describe the type(s) of experts you are looking to speak with and what you would like to discuss with them. Some sample questions might be helpful too, if appropriate."
                value={description}
                onChange={(e, { value }) => setDescription(value)}
                readOnly={project.status === 'Completed'}
            />
        </>
    )
}

export default forwardRef(Details)
