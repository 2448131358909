/* eslint-disable react-hooks/rules-of-hooks */
import React, { Fragment, useState, useEffect, useRef, useContext } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Button, List, Loader } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import Alert from '../../Alert'
import RescheduleContent from './RescheduleContent'
import CancelContent from './CancelContent'
import { LOAD_ME_QUERY } from '../../../graphql/user'
import {
    LOAD_MEETING_INFO_QUERY,
    RESCHEDULE_MEETING,
    CANCEL_JOB,
} from '../../../graphql/meeting'
import { LOAD_SINGLE_PROJECT_QUERY } from '../../../graphql/project'
import DataContext from '../../../contexts/DataContext'
import './styles.css'

const ScheduleTimeModal = ({ onShow, onHide, job }) => {
    if (!job) {
        return <div></div>
    }

    const { user } = useContext(DataContext)

    const [loadMeetingInfo, { data, called, loading, error }] = useLazyQuery(
        LOAD_MEETING_INFO_QUERY
    )

    useEffect(() => {
        if (job && onShow) {
            loadMeetingInfo({
                variables: {
                    id: job.id,
                },
            })
        }
    }, [called, job, onShow, loadMeetingInfo])

    const id = job.id || ''
    const advisor = job.user || {}
    const jobProject = job.project || {}
    const meetingAt = job.meetingAt || ''

    const [type, setType] = useState('INDEX')
    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')
    const [onProcess, setOnProcess] = useState(false)

    const cancelRef = useRef(null)

    const [rescheduleMeeting] = useMutation(RESCHEDULE_MEETING, {
        refetchQueries: [
            {
                query: LOAD_SINGLE_PROJECT_QUERY,
                variables: { id: jobProject.id },
            },
            { query: LOAD_ME_QUERY },
        ],
        awaitRefetchQueries: true,
        onError: (error) => {
            console.log(error)
            setShowAlert(true)
            setAlertHeader('Error')
            setAlertBody(
                `You can not reschedule this project: ${
                    error.message.split('GraphQL error: ')[1]
                }`
            )
        },
    })
    const [cancelJob] = useMutation(CANCEL_JOB, {
        refetchQueries: [
            {
                query: LOAD_SINGLE_PROJECT_QUERY,
                variables: { id: jobProject.id },
            },
            { query: LOAD_ME_QUERY },
        ],
        awaitRefetchQueries: true,
        onError: (error) => {
            console.log(error)
            setShowAlert(true)
            setAlertHeader('Error')
            setAlertBody(
                `You can not cancel this project: ${
                    error.message.split('GraphQL error: ')[1]
                }`
            )
        },
    })

    const meetingTime = momentTZ.tz(meetingAt, user.timeZone).format('lll z')

    const onCancel = () => {
        const { reason, otherText } = cancelRef.current.state

        const reasonText = `${reason}: ${otherText}`

        setOnProcess(true)

        cancelJob({
            variables: {
                jobId: id,
                reason: reasonText,
            },
            update: (cache) => {
                onHide()
                setOnProcess(false)
            },
        })
    }

    const onReschedule = () => {
        setOnProcess(true)
        rescheduleMeeting({
            variables: {
                jobId: id,
                reason: '(none)',
            },
            update: (cache) => {
                onHide()
                setOnProcess(false)
            },
        })
    }

    const renderContent = () => {
        switch (type) {
            case 'RESCHEDULE':
                return <RescheduleContent />
            case 'CANCEL':
                return (
                    <CancelContent
                        ref={cancelRef}
                        name={advisor.name}
                        title={jobProject.title}
                        meetingTime={meetingTime}
                    />
                )
            default:
                return (
                    <Modal.Body>
                        <List>
                            <List.Item>
                                <strong>Expert: </strong>
                                {advisor.fullName || advisor.email}
                            </List.Item>
                            <List.Item>
                                <strong>Project: </strong>
                                {jobProject.title}
                            </List.Item>
                            <List.Item>
                                <strong>Meeting time: </strong>
                                {meetingTime}
                            </List.Item>
                            <List.Item>
                                <strong>Meeting information: </strong>
                                <br />
                                <br />
                                <pre style={{ color: '#8C92A0' }}>
                                    {data.job.zoomMeeting.meetingInvite}
                                </pre>
                            </List.Item>
                        </List>
                        {data &&
                            data.job.zoomMeeting.meetingReport.status ===
                                'Complete' && (
                                <Fragment>
                                    <label>Meeting Participants:</label>
                                    <List size="tiny" bulleted>
                                        {data.job.zoomMeeting.meetingReport.participants.map(
                                            (participant, index) => {
                                                return (
                                                    <List.Item key={index}>
                                                        {`
			                                            ${participant.name} ${
                                                            participant.email
                                                                ? `(${participant.email})`
                                                                : ''
                                                        }: 
			                                            ${moment(
                                                            participant.joinedAt
                                                        ).format(
                                                            'LT'
                                                        )} - ${moment(
                                                            participant.leftAt
                                                        ).format('LT')}
			                                            (${participant.numMinutes} minutes)
			                                        `}
                                                    </List.Item>
                                                )
                                            }
                                        )}
                                    </List>
                                    <br />
                                </Fragment>
                            )}
                        {job.overall_status !== 'Completed' &&
                            job.overall_status !== 'Cancel' &&
                            moment(job.meetingAt).diff(moment(), 'minutes') >=
                                30 &&
                            job.interviewer.id === user.id && (
                                <div className="link">
                                    <a
                                        href="# "
                                        onClick={() => setType('CANCEL')}
                                    >
                                        I would like to cancel this meeting
                                    </a>
                                    <br />
                                    <a
                                        href="# "
                                        onClick={() => setType('RESCHEDULE')}
                                    >
                                        I need to reschedule this meeting
                                    </a>
                                </div>
                            )}
                    </Modal.Body>
                )
        }
    }

    const renderFooter = () => {
        switch (type) {
            case 'RESCHEDULE':
                return (
                    <Modal.Footer>
                        <Button onClick={() => setType('INDEX')}>Cancel</Button>
                        <Button
                            loading={onProcess}
                            disabled={onProcess}
                            onClick={onReschedule}
                        >
                            Confirm
                        </Button>
                    </Modal.Footer>
                )
            case 'CANCEL':
                return (
                    <Modal.Footer>
                        <Button onClick={() => setType('INDEX')}>Cancel</Button>
                        <Button
                            loading={onProcess}
                            disabled={onProcess}
                            onClick={onCancel}
                        >
                            Submit
                        </Button>
                    </Modal.Footer>
                )
            default:
                return (
                    <Modal.Footer>
                        <Button onClick={onHide}>Close</Button>
                    </Modal.Footer>
                )
        }
    }

    if (!called || !onShow) return <div></div>
    if (called && loading) return <Loader active />
    if (error) {
        console.log(error)
        return <div></div>
    }

    return (
        <Modal
            show={onShow}
            onHide={onHide}
            size="lg"
            centered
            style={{ zIndex: 9999 }}
            className="invite-modal"
        >
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <Modal.Header closeButton>
                <Modal.Title>
                    {`${advisor.fullName || advisor.email} | ${
                        jobProject.title
                    } at ${meetingTime}`}
                </Modal.Title>
            </Modal.Header>
            {renderContent()}
            {renderFooter()}
        </Modal>
    )
}

export default ScheduleTimeModal
