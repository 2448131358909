import React from 'react'
import { Icon, Pagination } from 'semantic-ui-react'

const PaginationComponent = ({
    defaultActivePage,
    totalPages,
    onPageChange,
}) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Pagination
                boundaryRange={0}
                defaultActivePage={defaultActivePage}
                ellipsisItem={{
                    content: <Icon name="ellipsis horizontal" />,
                    icon: true,
                }}
                firstItem={{
                    content: <Icon name="angle double left" />,
                    icon: true,
                }}
                lastItem={{
                    content: <Icon name="angle double right" />,
                    icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                siblingRange={1}
                totalPages={totalPages}
                onPageChange={(e, { activePage }) => onPageChange(activePage)}
            />
        </div>
    )
}

export default PaginationComponent
