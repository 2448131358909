/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Label, Icon, Input, Button, Placeholder } from 'semantic-ui-react'
import {
    LOAD_EXPERT_TAGS,
    UPDATE_EXPERT_INTERNAL_TAGS,
} from '../../graphql/user'

const AdvisorTags = ({ advisor, highlightTags = [], showInternalOnly }) => {
    const [internalTag, setInternalTag] = useState('')
    const [removeable, setRemoveable] = useState(false)

    const { data, loading } = useQuery(LOAD_EXPERT_TAGS, {
        variables: { id: advisor.id },
    })

    const [updateTags] = useMutation(UPDATE_EXPERT_INTERNAL_TAGS, {
        refetchQueries: [
            {
                query: LOAD_EXPERT_TAGS,
                variables: { id: advisor.id },
            },
        ],
    })

    const addInternalTag = (e) => {
        e.preventDefault()
        const tempInternalTags = [...data.user.expert.internal_tags]
        updateTags({
            variables: {
                id: advisor.id,
                internalTags: tempInternalTags.concat(internalTag),
            },
            update: () => {
                setInternalTag('')
            },
        })
    }

    const removeInternalTag = (e, item) => {
        e.preventDefault()
        const tempInternalTags = [...data.user.expert.internal_tags]
        updateTags({
            variables: {
                id: advisor.id,
                internalTags: tempInternalTags.filter((x) => x !== item),
            },
        })
    }

    if (loading)
        return (
            <Placeholder>
                <Placeholder.Paragraph>
                    <Placeholder.Line />
                </Placeholder.Paragraph>
            </Placeholder>
        )

    const advisorTags = data.user.expert.functions.concat(
        advisor.expert.organization.type,
        advisor.expert.medical_specialties,
        advisor.expert.technology_tags
    )

    return (
        <div className="tags-section">
            <div
                style={{
                    marginBottom: '3%',
                    maxHeight: '100px',
                    overflow: 'auto',
                }}
            >
                {!showInternalOnly &&
                    advisorTags.map((item, index) => (
                        <Label
                            key={index}
                            color={
                                highlightTags.includes(item) ? 'yellow' : null
                            }
                            className="customize-tags"
                        >
                            {item}
                        </Label>
                    ))}
                {data.user.expert.internal_tags.map((item, index) => (
                    <Label
                        key={index + 1000}
                        color={highlightTags.includes(item) ? 'yellow' : null}
                        className="customize-tags"
                    >
                        {removeable && (
                            <a
                                href="# "
                                onClick={(e) => removeInternalTag(e, item)}
                            >
                                <Icon name="remove" />
                            </a>
                        )}
                        {item}
                    </Label>
                ))}
            </div>
            <div style={{ float: 'right' }}>
                <form
                    style={{ display: 'inline', marginRight: 5 }}
                    onSubmit={addInternalTag}
                >
                    <Input
                        size="mini"
                        value={internalTag}
                        placeholder="Add internal tags"
                        onFocus={() => setRemoveable(false)}
                        onChange={(e, { value }) => setInternalTag(value)}
                    />
                </form>
                <Button size="small" onClick={addInternalTag}>
                    Enter
                </Button>
                <Button
                    size="small"
                    icon
                    onClick={() => setRemoveable(!removeable)}
                >
                    Edit
                </Button>
            </div>
        </div>
    )
}

export default AdvisorTags
