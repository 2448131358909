import React from 'react'
import { Grid } from 'semantic-ui-react'

const Logo = () => {
    return (
        <Grid.Row className="panel-section panel-logo">
            DEXTER | Review Extractions
        </Grid.Row>
    )
}

export default Logo
