import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import { UPDATE_PROJECT_MUTATION } from '../../graphql/project'
import { SingleProjectContext } from '../../Context'

const ChangeToActiveModal = ({ onShow, onHide }) => {
    const { setProject } = useContext(SingleProjectContext)

    const { id } = useParams()

    const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION)

    const changeToActive = () => {
        updateProject({
            variables: {
                id,
                input: {
                    status: 'Active',
                },
            },
            update: (cache, { data: { updateProject } }) => {
                setProject(updateProject)
                onHide()
            },
        })
    }

    return (
        <Modal show={onShow} onHide={onHide} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Project status will change to &quot;Active&quot;
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    You will no longer be able to edit fields that are visible
                    to the invited experts (e.g., project title, duration,
                    project details, survey link). Do you wish to continue ?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="success"
                    style={{ marginRight: '35%' }}
                    className="tool-btn"
                    onClick={changeToActive}
                >
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChangeToActiveModal
