export const categories = [
    {
        name: 'Organization Types',
        type: 'organizationType',
        tags: [
            {
                header: 'Business',
                icon: 'suitcase',
                items: [
                    'Biopharma',
                    'Biotech',
                    'Applied Industry',
                    'CRO',
                    'CMO / CDMO',
                    'OEM',
                    'Consultancy',
                    'Medical Devices',
                    'Manufacturer',
                    'Reagants Provider',
                    'Professional Services',
                    'Financial Services',
                    'Life Science Research Tools',
                    'Diagnostics',
                    'Digital Health',
                    'Software',
                    'Oil and Gas',
                    'Cosmetics',
                    'Supply Chain',
                    'Procurement',
                ],
            },
            {
                header: 'Research',
                icon: 'flask',
                items: ['Research Institute', 'Reference Lab', 'Hospital Lab'],
            },
            {
                header: 'Academic',
                icon: 'graduation cap',
                items: ['University'],
            },
            {
                header: 'Clinical',
                icon: 'stethoscope',
                items: [
                    'Academic Medical Center',
                    'Community Hospital',
                    'Private Practice',
                    'Private Practice / Concierge',
                    'Intensive Care / ICU',
                    'Urgent Care',
                    'Ambulatory',
                    'Nursing Home',
                    'Government Hospital – Academic Medical Center',
                    'Government Hospital – Community Hospital',
                    'Government Hospital – VA',
                    'Hospital',
                    'Independent Community Hospital',
                    'Integrated Delivery Network Hospital',
                    'Other Network Hospital',
                    'Cancer Center',
                    'Medical Clinic',
                    'Mental Health Care',
                ],
            },
            {
                header: 'Government / Other',
                icon: 'building',
                items: [
                    'Government',
                    'Insurance / Payor',
                    'Regulatory Body',
                    'FDA',
                    'CDC',
                    'Legal',
                    'Quality Control',
                    'Regulatory Body',
                    'Professional Association',
                    'Philanthropic Fundraising Services',
                ],
            },
        ],
    },
    {
        name: 'Functions',
        type: 'functions',
        tags: [
            {
                header: 'Business',
                icon: 'suitcase',
                items: [
                    'C-Suite',
                    'VP',
                    'Executive / Head',
                    'Director',
                    'Manager',
                    'Consultant',
                    'Analyst',
                    'Founder',
                    'Investor',
                    'Partner',
                ],
            },
            {
                header: 'Research',
                icon: 'flask',
                items: [
                    'Principal Investigator',
                    'Senior Scientist',
                    'Researcher',
                    'Lab Director / Manager',
                    'Lab Technician',
                    'Scientist',
                ],
            },
            {
                header: 'Academic',
                icon: 'graduation cap',
                items: ['Professor', 'Assistant Professor', 'Post-doc'],
            },
            {
                header: 'Clinical',
                icon: 'stethoscope',
                items: [
                    'Physician',
                    'Non-Physician Clinician',
                    "Physician's Assistant",
                    'Nurse Navigator',
                    'Nurse / Phlebotomist',
                    'Case Manager',
                    'Practice Administrator',
                    'Medical Director',
                ],
            },
            {
                header: 'Digital / Technical',
                icon: 'desktop',
                items: [
                    'IT specialist',
                    'Bioinformatician',
                    'Computational Biologist',
                    'Statistician',
                    'Engineer',
                    'Regulatory Specialist',
                    'Medical Reimbursment Specialist',
                    'Software Engineer',
                ],
            },
        ],
    },
    {
        name: 'Geography',
        type: 'continent',
        tags: [
            'Africa',
            'Antarctica',
            'Asia',
            'Europe',
            'North America',
            'Oceania',
            'South America',
            'European Union',
            'Asia-Pacific',
        ],
    },
    {
        name: 'Country',
        type: 'country',
        tags: [
            'Afghanistan',
            'Aland Islands',
            'Albania',
            'Algeria',
            'American Samoa',
            'Andorra',
            'Angola',
            'Anguilla',
            'Antigua',
            'Argentina',
            'Armenia',
            'Aruba',
            'Australia',
            'Austria',
            'Azerbaijan',
            'Bahamas',
            'Bahrain',
            'Bangladesh',
            'Barbados',
            'Belarus',
            'Belgium',
            'Belize',
            'Benin',
            'Bermuda',
            'Bhutan',
            'Bolivia',
            'Bosnia',
            'Botswana',
            'Bouvet Island',
            'Brazil',
            'British Virgin Islands',
            'Brunei',
            'Bulgaria',
            'Burkina Faso',
            'Burma',
            'Burundi',
            'Caicos Islands',
            'Cambodia',
            'Cameroon',
            'Canada',
            'Cape Verde',
            'Cayman Islands',
            'Central African Republic',
            'Chad',
            'Chile',
            'China',
            'Christmas Island',
            'Cocos Islands',
            'Colombia',
            'Comoros',
            'Congo',
            'Congo Brazzaville',
            'Cook Islands',
            'Costa Rica',
            'Cote Divoire',
            'Croatia',
            'Cuba',
            'Cyprus',
            'Czech Republic',
            'Denmark',
            'Djibouti',
            'Dominica',
            'Dominican Republic',
            'Ecuador',
            'Egypt',
            'El Salvador',
            'Equatorial Guinea',
            'Eritrea',
            'Estonia',
            'Ethiopia',
            'Europeanunion',
            'Falkland Islands',
            'Faroe Islands',
            'Fiji',
            'Finland',
            'France',
            'French Guiana',
            'French Polynesia',
            'French Territories',
            'Gabon',
            'Gambia',
            'Georgia',
            'Germany',
            'Ghana',
            'Gibraltar',
            'Greece',
            'Greenland',
            'Grenada',
            'Guadeloupe',
            'Guam',
            'Guatemala',
            'Guinea',
            'Guinea-Bissau',
            'Guyana',
            'Haiti',
            'Heard Island',
            'Honduras',
            'Hong Kong',
            'Hungary',
            'Iceland',
            'India',
            'Indian Ocean Territory',
            'Indonesia',
            'Iran',
            'Iraq',
            'Ireland',
            'Israel',
            'Italy',
            'Jamaica',
            'Jan Mayen',
            'Japan',
            'Jordan',
            'Kazakhstan',
            'Kenya',
            'Kiribati',
            'Kuwait',
            'Kyrgyzstan',
            'Laos',
            'Latvia',
            'Lebanon',
            'Lesotho',
            'Liberia',
            'Libya',
            'Liechtenstein',
            'Lithuania',
            'Luxembourg',
            'Macau',
            'Macedonia',
            'Madagascar',
            'Malawi',
            'Malaysia',
            'Maldives',
            'Mali',
            'Malta',
            'Marshall Islands',
            'Martinique',
            'Mauritania',
            'Mauritius',
            'Mayotte',
            'Mexico',
            'Micronesia',
            'Moldova',
            'Monaco',
            'Mongolia',
            'Montenegro',
            'Montserrat',
            'Morocco',
            'Mozambique',
            'Namibia',
            'Nauru',
            'Nepal',
            'Netherlands',
            'Netherlandsantilles',
            'New Caledonia',
            'New Guinea',
            'New Zealand',
            'Nicaragua',
            'Niger',
            'Nigeria',
            'Niue',
            'Norfolk Island',
            'North Korea',
            'Northern Mariana Islands',
            'Norway',
            'Oman',
            'Pakistan',
            'Palau',
            'Palestine',
            'Panama',
            'Paraguay',
            'Peru',
            'Philippines',
            'Pitcairn Islands',
            'Poland',
            'Portugal',
            'Puerto Rico',
            'Qatar',
            'Reunion',
            'Romania',
            'Russia',
            'Rwanda',
            'Saint Helena',
            'Saint Kitts and Nevis',
            'Saint Lucia',
            'Saint Pierre',
            'Saint Vincent',
            'Samoa',
            'San Marino',
            'Sandwich Islands',
            'Sao Tome',
            'Saudi Arabia',
            'Scotland',
            'Senegal',
            'Serbia',
            'Seychelles',
            'Sierra Leone',
            'Singapore',
            'Slovakia',
            'Slovenia',
            'Solomon Islands',
            'Somalia',
            'South Africa',
            'South Korea',
            'Spain',
            'Sri Lanka',
            'Sudan',
            'Suriname',
            'Swaziland',
            'Sweden',
            'Switzerland',
            'Syria',
            'Taiwan',
            'Tajikistan',
            'Tanzania',
            'Thailand',
            'Timorleste',
            'Togo',
            'Tokelau',
            'Tonga',
            'Trinidad',
            'Tunisia',
            'Turkey',
            'Turkmenistan',
            'Tuvalu',
            'United Arab Emirates',
            'Uganda',
            'Ukraine',
            'United Kingdom',
            'United States',
            'Uruguay',
            'US Minor Islands',
            'US Virgin Islands',
            'Uzbekistan',
            'Vanuatu',
            'Vatican City',
            'Venezuela',
            'Vietnam',
            'Wales',
            'Wallis and Futuna',
            'Western Sahara',
            'Yemen',
            'Zambia',
            'Zimbabwe',
        ],
    },
    {
        name: 'Technology Tags',
        type: 'technology_tags',
        tags: [
            {
                header: 'Sequencing',
                icon: 'dna',
                items: [
                    'NGS',
                    'CE/Sanger',
                    'Single Cell Sequencing',
                    'Single-Cell RNA-Seq',
                    'Single-Cell ATAC-Seq',
                    'Nanopore Sequencing',
                    'Methylomics / Epigenetics',
                    'Transcriptomics',
                    'Spatial-Omics',
                    'RNA-Seq',
                    'Proteomics',
                    'Metabolomics',
                    'ctDNA / cfDNA',
                ],
            },
            {
                header: 'Primer/Oligos Assays',
                icon: 'flask',
                items: [
                    'Standard PCR',
                    'qPCR',
                    'dPCR',
                    'ddPCR',
                    'Reverse Transcriptase-PCR',
                    'Real Time-PCR',
                    'Singleplex PCR',
                    'Mulitplex PCR',
                    'RNAi',
                    'Microarray',
                ],
            },
            {
                header: 'IA/Cell Assays',
                icon: 'eye dropper',
                items: [
                    'Antibodies',
                    'FISH',
                    'RNA ISH',
                    'IHC',
                    'Flow Cytometry',
                    'ELISA',
                    'Immunoblot / Immunofluorescence',
                    'Antigen Test',
                    'Antibody Test',
                    'Phage Display',
                    'Lateral Flow Assay',
                    'Proximity Extension Assay (PEA)',
                    'Circulating Tumor Cells (CTCs)',
                    'Cell Culture',
                    'Molecule Characterization',
                    'Mass Spec',
                    'Mass Cytometry',
                    'High Performance Liquid Chromatography (HPLC)',
                    'Gas chromatography (GC)',
                    'Asymmetrical Flow-Field Flow Fractionation',
                ],
            },
            {
                header: 'Cell/Gene-Editing',
                icon: 'cut',
                items: [
                    'CRISPR',
                    'Transposases',
                    'ZFN',
                    'Exosomes',
                    'Lipid Nanoparticles',
                    'Viral Vectors',
                    'Non-Viral Vector',
                    'RNPs',
                    'Base Editing',
                    'Prime Editing',
                ],
            },
            {
                header: 'Imaging/Measurement',
                icon: 'expand',
                items: [
                    'UV-Vis',
                    'Microscopy',
                    'Spectrophotometry',
                    'Computerized microtomography (microCT)',
                    'MRI',
                    'CT Scan',
                    'PET Scan',
                    'Ultrasound',
                    'Calorimetry',
                    'High content imaging',
                ],
            },
            {
                header: 'Digital',
                icon: 'desktop',
                items: [
                    'Alexa',
                    'Google Assistant',
                    'Siri',
                    'C++',
                    'Databases',
                    'NLP',
                    'AI',
                    'Ontology',
                    'EKG',
                    'Health Accessories',
                    'Digital pathology',
                    'Electronic Health Records',
                ],
            },
            {
                header: 'Other',
                icon: 'tags',
                items: [
                    'Animal studies',
                    'High Throughput Screening',
                    'Membrane Fluidity',
                    'Protein Biomarker',
                    'IGRA',
                ],
            },
        ],
    },
    {
        name: 'Medical specialties and/or Disease/Research Areas',
        type: 'medical_specialties',
        tags: [
            'Anesthesiology',
            'Autoimmune',
            'Dermatology',
            'Cardiology',
            'Aging Disease',
            'Allergy/Immunology',
            'COVID',
            'Arthritis',
            'Medical Oncology',
            'Internal Medicine',
            'Oncology - Lung',
            'Microbiology',
            'Oncology - Gyn/Uterine/Cervical/Ovarian',
            'Neurological',
            'Nutrition',
            'NIPT',
            'Geriatrics',
            'Hematology',
            'Oncology',
            'OB/GYN',
            'Oncology - Prostate',
            'Emergency Medicine',
            'Medical Genetics',
            'Oncology - Blood Disorder',
            'Oncology - CRC',
            'Endocrinology/Diabetes/Metabolism',
            'Oncology - Breast',
            'Oncology - Surgical',
            'Oncology - Radiation',
            'Endometriosis',
            'Genetic Disorder',
            'Oncology - General',
            'Family/General Practice',
            'GVHD',
            'Infectious Disease',
            'Ophthalmology',
            'Orthopedics',
            'Otolaryngology',
            'Pathology',
            'Pediatrics',
            'Physical Medicine & Rehab',
            'Plastic Surgey',
            'Primary Care',
            'Psychiatry',
            'Radiology',
            'Rare Disease',
            'Respiratory',
            'STIs / STDs',
            'Surgery',
            'Urology',
            'Virology',
            "Women's Health",
        ],
    },
    {
        name: 'Status',
        type: 'status',
        tags: ['Active', 'Do Not Contact'],
    },
]
