import React, { useCallback, useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Grid } from 'semantic-ui-react'
import { useHotkeys } from 'react-hotkeys-hook'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { EXPERTDATAWORK } from '../graphql/datawork'
import { LOAD_MANAGE_EXPERTS_QUERY } from '../graphql/admin'
import { ExtractionReviewContext } from '../Context'
import Panel from '../components/ExtractionReview/Panel'
import ReviewBody from '../components/ExtractionReview/ReviewBody'
import Confirmation from '../components/ExtractionReview/Confirmation'
import LinkedInInfo from '../components/ExtractionReview/LinkedInInfo'
import LoadingSpinner from '../components/LoadingSpinner'
import NotFoundPage from '../components/NotFoundPage'
import Shortcut from '../components/ExtractionReview/Shortcut'
import { AUTO_THRESHOLD, CATEGORY_THRESHOLD } from '../constants/review'
import '../components/ExtractionReview/styles.css'

const SIDEBAR_WIDTH = 400 // px
const CATEGORIES = [
    'title',
    'organization',
    'medical_specialties',
    'technology_tags',
    'education',
    'function',
]

const ExtractionReview = () => {
    const { id } = useParams()
    const pageHistory = useHistory()

    const {
        data: dataDatawork,
        loading: loadingDatawork,
        error: errorDatawork,
    } = useQuery(EXPERTDATAWORK, {
        variables: { expertId: id },
    })

    const {
        data: dataExperts,
        loading: loadingExperts,
        error: errorExperts,
    } = useQuery(LOAD_MANAGE_EXPERTS_QUERY)

    const [history, setHistory] = useState([])
    const [queue, setQueue] = useState([])
    const [currExtraction, setCurrExtraction] = useState(null)
    const [sidebarOpen, setSidebarOpen] = useState(true)

    const reviewBodyWidth = sidebarOpen
        ? `calc(100% - ${SIDEBAR_WIDTH}px)`
        : '100%'

    // Populate history and queue based on category probabilities
    useEffect(() => {
        if (dataDatawork) {
            const q = []
            setHistory(
                dataDatawork.expertDatawork.tagExtractions.reduce(
                    (acc, curr) => {
                        let found = false
                        curr.categoryProbabilities.every(
                            (categoryProbability) => {
                                if (
                                    categoryProbability.confidence >=
                                    AUTO_THRESHOLD
                                ) {
                                    acc.unshift({
                                        auto: true,
                                        category: categoryProbability.category,
                                        decision: 'accepted',
                                        extraction: curr,
                                    })
                                    found = true
                                    return false
                                }
                                return true
                            }
                        )
                        if (!found) {
                            q.push(curr)
                        }
                        return acc
                    },
                    []
                )
            )
            updateQueue(q)
        }
    }, [dataDatawork])

    const updateQueue = (q) => {
        setQueue(q)
        // Set top of queue as current extraction
        if (q.length > 0 && q.at(0)) {
            let category = null
            q.at(0).categoryProbabilities.every((categoryProbability) => {
                if (categoryProbability.confidence >= CATEGORY_THRESHOLD) {
                    category = categoryProbability.category
                    return false
                }
                return true
            })
            setCurrExtraction({
                auto: false,
                category,
                decision: null,
                extraction: q.at(0),
            })
        }
    }

    const decide = useCallback(
        (category) => {
            let extraction = null
            if (CATEGORIES.includes(category)) {
                extraction = {
                    ...currExtraction,
                    category,
                    decision: 'accepted',
                }
            } else {
                extraction = {
                    ...currExtraction,
                    category: null,
                    decision: category,
                }
            }
            setHistory([extraction, ...history])
            updateQueue(queue.slice(1))
        },
        [currExtraction, history, queue]
    )

    const undoExtraction = useCallback(
        (index) => {
            const temp = history.slice()
            const last = temp.splice(index, 1).at(0)
            setHistory(temp)
            updateQueue([last.extraction].concat(queue))
        },
        [history, queue]
    )

    useHotkeys('p', () => setSidebarOpen(!sidebarOpen))

    if (loadingDatawork || loadingExperts) {
        return <LoadingSpinner />
    } else if (
        errorDatawork ||
        errorExperts ||
        dataDatawork.expertDatawork.status !== 'scrape-success'
    ) {
        return <NotFoundPage history={pageHistory} />
    }

    const getExpertListData = () => {
        const options = dataExperts.adminPortal.actionableExperts.map((e) => ({
            text: e.expert.fullName,
            value: e.expert.id,
            key: e.expert.id,
        }))
        const length = options.length
        const currIndex = options.findIndex(
            (o) => o.value === dataDatawork.expertDatawork.expert.id
        )
        const nextIndex =
            length > 1
                ? currIndex !== length - 1
                    ? currIndex + 1
                    : currIndex - 1
                : null
        return {
            options,
            currIndex,
            nextIndex,
            length,
        }
    }

    return (
        <ExtractionReviewContext.Provider
            value={{
                datawork: dataDatawork.expertDatawork,
                expertListData: getExpertListData(),
                history,
                queue,
                currExtraction,
                reviewEnabled: currExtraction !== null,
                confirmationEnabled: queue.length === 0,
                undoExtraction,
                decide,
            }}
        >
            <Grid className="review">
                <Grid.Row
                    className="review-container"
                    style={{
                        minWidth: reviewBodyWidth,
                        maxWidth: reviewBodyWidth,
                    }}
                >
                    <Grid.Column width={4} className="panel-wrapper">
                        <Panel />
                    </Grid.Column>
                    <Grid.Column width={12} className="body-wrapper">
                        <div
                            className="sidebar-button"
                            onClick={() => setSidebarOpen((state) => !state)}
                        >
                            {sidebarOpen ? (
                                <MdKeyboardArrowRight size={28} />
                            ) : (
                                <MdKeyboardArrowLeft size={28} />
                            )}
                            <Shortcut shortcut={'P'} />
                        </div>
                        {queue.length > 0 ? <ReviewBody /> : <Confirmation />}
                        <LinkedInInfo
                            sidebarOpen={sidebarOpen}
                            width={SIDEBAR_WIDTH}
                            extractions={
                                queue.length > 0
                                    ? [
                                          currExtraction
                                              ? currExtraction.extraction
                                              : null,
                                      ]
                                    : history
                                          .filter(
                                              (h) => h.decision === 'accepted'
                                          )
                                          .map((h) => h.extraction)
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </ExtractionReviewContext.Provider>
    )
}

export default ExtractionReview
