export const getAfterPaymentMessageMarkup = (
    salutation,
    firstName,
    lastName,
    projectTitle,
    paymentAmount,
    analystName
) => `
	<p>Hi ${
        salutation && lastName
            ? `${salutation} ${lastName}`
            : firstName || 'there'
    },<p>
	<p>
		Thanks again for your input on ${projectTitle}. As of moments ago, I initiated the direct deposit from Dexter, so your honorarium payment is on its way. (You'll be receiving $${paymentAmount} for your participation in this project).
	</p>
	<p>
		Please let me know if you have any questions, and if not, I hope you stay well and healthy until our next engagement!	
	</p>
	<p>
		Cheers,<br/>${analystName || ''}
	</p> 
`
