import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react'
import DatePicker from 'react-datepicker'
import { Form } from 'semantic-ui-react'
import Alert from '../../Alert'
import { PROJECT_TYPE_OPTIONS } from '../../../utils/options'
import 'react-datepicker/dist/react-datepicker.css'

const Information1 = (props, ref) => {
    const { project, error } = props

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(
        new Date(new Date().setMonth(new Date().getMonth() + 1))
    )
    const [projectType, setProjectType] = useState('')
    const [surveyLink, setSurveyLink] = useState('')
    const [calendlyLink, setCalendlyLink] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')

    useEffect(() => {
        if (project.id) {
            setStartDate(project.start_date || '')
            setEndDate(project.end_date || '')
            setProjectType(project.type || '')
            setSurveyLink(project.surveyLink || '')
            setCalendlyLink(project.calendly_link || '')
        }
    }, [project])

    useImperativeHandle(ref, () => {
        return {
            state: {
                startDate,
                endDate,
                projectType,
                surveyLink,
                calendlyLink,
            },
        }
    })

    const onSetStartDate = (date) => {
        if (date < endDate) {
            setStartDate(date)
        } else {
            setShowAlert(true)
            setAlertHeader('Error')
            setAlertBody('Start date can not be greater than end date')
        }
    }

    const datePickerAttr = {
        showTimeSelect: false,
        dateFormat: 'MMMM d, yyy',
        disabled: project.status === 'Completed',
    }

    return (
        <>
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <Form.Field>
                <label>Duration</label>
                <Form.Group widths={2}>
                    <Form.Field>
                        <label>Start</label>
                        <DatePicker
                            {...datePickerAttr}
                            selected={
                                startDate ? new Date(startDate) : new Date()
                            }
                            onChange={(date) => onSetStartDate(date)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>End</label>
                        <DatePicker
                            {...datePickerAttr}
                            selected={
                                startDate ? new Date(endDate) : new Date()
                            }
                            onChange={(date) => setEndDate(date)}
                        />
                    </Form.Field>
                </Form.Group>
            </Form.Field>
            <Form.Group widths={2}>
                <Form.Dropdown
                    error={error && error.projectType}
                    required
                    label="Project type"
                    placeholder="Select type"
                    fluid
                    selection
                    options={PROJECT_TYPE_OPTIONS}
                    value={projectType}
                    onChange={(e, { value }) => setProjectType(value)}
                    disabled={project.status && project.status !== 'Draft'}
                />
                {projectType === 'Interview' ? (
                    <Form.Input
                        required
                        label="Calendly Link"
                        value={calendlyLink}
                        onChange={(e, { value }) => setCalendlyLink(value)}
                    />
                ) : (
                    <Form.Input
                        error={error && error.surveyLink}
                        required
                        label="Survey Link"
                        value={surveyLink}
                        onChange={(e, { value }) => setSurveyLink(value)}
                    />
                )}
            </Form.Group>
        </>
    )
}

export default forwardRef(Information1)
