import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Header, List, Button, Icon } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import Alert from '../../Alert'
import { PAY_EXPERT } from '../../../graphql/transaction'
import { LOAD_MANAGE_JOBS_QUERY } from '../../../graphql/admin'
import { SEND_EMAIL_MUTATION } from '../../../graphql/email'
import { UPDATE_JOB_MUTATION } from '../../../graphql/job'

const Confirm = ({ job, payment, updatePage }) => {
    const [error, setError] = useState(null)
    const [payExpert, { loading }] = useMutation(PAY_EXPERT, {
        refetchQueries: [
            {
                query: LOAD_MANAGE_JOBS_QUERY,
            },
        ],
        onError: (err) => {
            setError({
                header: 'Unsuccessful payment',
                body: `
                    Failed to process the payment due to the following reason: ${err.message}. 
                    Please try again or contact your site administrator if the problem persists
                `,
            })
        },
    })
    const [sendEmail] = useMutation(SEND_EMAIL_MUTATION)
    const [updateJob] = useMutation(UPDATE_JOB_MUTATION)

    const onPay = () => {
        const { additionalMessage, ...rest } = payment
        const paymentInput = { ...rest }
        payExpert({
            variables: {
                jobId: job.id,
                payment: paymentInput,
            },
            onCompleted: () => {
                updatePage('SUCCESS')
                // Record email logs to job & send additional message
                const emailLogs = [
                    {
                        subject: 'Dexter: You just got paid',
                        sent_at: new Date(),
                    },
                ]
                // Send addtional message
                if (additionalMessage) {
                    emailLogs.push({
                        subject: `Re: Your Dexter engagement on ${job.project.title}`,
                        sent_at: new Date(),
                    })
                    const emailInput = {
                        to: {
                            email: job.user.email,
                        },
                        from: {
                            email: 'info@dexterexperts.com',
                            name: 'Dexter Team',
                        },
                        subject: `Re: Your Dexter engagement on ${job.project.title}`,
                        text: 'After payment email',
                        html: additionalMessage,
                    }
                    sendEmail({
                        variables: {
                            input: emailInput,
                        },
                        onCompleted: () => {
                            updateJob({
                                variables: {
                                    userId: job.user.id,
                                    projectId: job.project.id,
                                    job: {
                                        email_logs:
                                            job.email_logs.concat(emailLogs),
                                    },
                                },
                            })
                        },
                    })
                }
            },
        })
    }

    return (
        <Modal.Body>
            {error && (
                <Alert
                    show={true}
                    header={error.header}
                    body={error.body}
                    onClose={() => setError(null)}
                />
            )}
            <Header as="h6">To be processed:</Header>
            <List>
                <List.Item>
                    {`${
                        payment.method === 'Stripe'
                            ? `- Pay ${job.user.fullName || job.user.email} $${
                                  payment.amount
                              }`
                            : `- Record an external payment of $${
                                  payment.amount
                              } to ${job.user.fullName || job.user.email}`
                    } with the payment method, ${payment.method}`}
                </List.Item>
            </List>
            <Modal.Footer>
                <Button icon basic onClick={() => updatePage('PAYMENT')}>
                    <Icon name="angle left" />
                </Button>
                <Button loading={loading} disabled={loading} onClick={onPay}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal.Body>
    )
}

export default Confirm
