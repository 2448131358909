import React from 'react'
import { useQuery } from '@apollo/client'
import { Form, Header, Loader } from 'semantic-ui-react'
import {
    EXTERNAL_PAYMENT_METHODS,
    LATEST_PAYMENT,
} from '../../../../graphql/transaction'

const PaymentMethods = ({ payment, updatePayment, job }) => {
    const { data, loading } = useQuery(EXTERNAL_PAYMENT_METHODS)
    const { data: latestPaymentData, loading: fetchingLatestPayment } =
        useQuery(LATEST_PAYMENT, {
            variables: {
                userId: job.user.id,
            },
            skip: !!job.user.expert.stripe_account,
        })

    const externalPaymentMethods = data?.externalPaymentMethods || []

    if (fetchingLatestPayment) return <Loader active />
    return (
        <div className="payment-info-field">
            <Header as="h6">Record external payment method</Header>
            {latestPaymentData.latestJobPayment && (
                <div>
                    Last paid with: {latestPaymentData.latestJobPayment.method}
                </div>
            )}
            <div>
                Note that you are still required to submit an Honoraria Google
                Form response for this payment
            </div>
            <Form.Dropdown
                loading={loading}
                selection
                placeholder="Select a payment method"
                value={payment.method}
                options={externalPaymentMethods.map((method) => ({
                    key: method,
                    value: method,
                    text: method,
                }))}
                onChange={(e, { value }) =>
                    updatePayment({
                        method: value,
                    })
                }
            />
        </div>
    )
}

export default PaymentMethods
