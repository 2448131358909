import React from 'react'
import { useQuery } from '@apollo/client'
import { LOAD_ME_QUERY } from '../graphql/user'
import LoadingSpinner from '../components/LoadingSpinner'
import User from '../models/User'

const DataContext = React.createContext({ advisor: null })
export const DataProvider = ({ children }) => {
    const { data, loading } = useQuery(LOAD_ME_QUERY)

    if (loading) return <LoadingSpinner />

    return (
        <DataContext.Provider value={{ user: new User(data.me) }}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext
