import React, { useState, useEffect, useContext } from 'react'
import { Input, Button, Icon, Checkbox } from 'semantic-ui-react'
import ExpertDropdowns from './ExpertDropdowns'
import SliderFilter from './SliderFilter'
import { categories } from '../../constants/categories'
import { AdvisorContext } from '../../Context'

const AdvisorFilters = ({ type }) => {
    const { filters, setFilters, loadAdvisor } = useContext(AdvisorContext)

    const [showFilter, setShowFilter] = useState(false)
    const [query, setQuery] = useState('')
    const [extraFilters, setExtraFilters] = useState({})

    useEffect(() => {
        setQuery(filters.query || '')
        if (!filters['country']) {
            setExtraFilters({})
        }
    }, [filters])

    const clearAllFilters = (e) => {
        e.preventDefault()
        setFilters({ hourlyRate: [0, 9999] })
        setExtraFilters({})
        setQuery('')
        setShowFilter(false)
        loadAdvisor()
    }

    const updateFilter = (type, value) => {
        let newFilters = filters
        if (value.length || (type === 'isBlocked' && value === true)) {
            newFilters[type] = value
        } else {
            delete newFilters[type]
        }
        setFilters(newFilters)
    }

    const applyFilter = (e) => {
        e.preventDefault()
        loadAdvisor()
        setShowFilter(false)
    }

    const enterPressed = (event) => {
        const code = event.keyCode || event.which
        if (code === 13) {
            applyFilter(event)
        }
    }

    return (
        <div>
            <form className="ui form filter-section shadow">
                <div className="two fields">
                    <div className="fourteen wide field">
                        <Input
                            icon="search"
                            placeholder="Search Name, Project, Job Title, Organization or Tags"
                            value={query}
                            onChange={(e, { value }) => {
                                updateFilter('query', value)
                                setQuery(value)
                            }}
                            onKeyPress={enterPressed}
                        />
                    </div>
                    {type !== 'MANAGE_ADVISOR' && (
                        <div className="four wide field">
                            <Button
                                icon
                                size="tiny"
                                className="filter-button"
                                labelPosition="left"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowFilter(!showFilter)
                                }}
                            >
                                <Icon name="gitter" />
                                {showFilter ? 'Hide ' : 'Show '} filters
                            </Button>
                        </div>
                    )}
                </div>
                {showFilter && (
                    <div>
                        {categories.map((list, index) => {
                            return (
                                <div className="inline field" key={index}>
                                    <ExpertDropdowns
                                        key={index}
                                        list={list}
                                        filters={filters}
                                        extraFilters={extraFilters}
                                        setExtraFilters={setExtraFilters}
                                        updateFilter={updateFilter}
                                    />
                                </div>
                            )
                        })}
                        <div className="inline field">
                            <label>Show all blocked experts</label>
                            <div className="ui input filters">
                                <Checkbox
                                    toggle
                                    checked={filters.isBlocked}
                                    onChange={(e, { checked }) => {
                                        updateFilter('isBlocked', checked)
                                        applyFilter(e)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="inline field">
                            <label>Expert hourly rate</label>
                            <div className="ui input filters">
                                <SliderFilter
                                    type="hourlyRate"
                                    range={[0, 9999]}
                                    filters={filters}
                                    updateFilter={updateFilter}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="field" style={{ marginTop: '1%' }}>
                    <Button color="blue" onClick={applyFilter}>
                        Apply filters
                    </Button>
                    <Button color="red" onClick={clearAllFilters}>
                        Reset filters
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default AdvisorFilters
