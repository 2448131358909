import React, { Fragment } from 'react'
import GetStarted from './GetStarted'
import ProjectApproval from './ProjectApproval'
import ManageTeammates from './ManageTeammates'
import ManageMeeting from './ManageMeeting'
import ManageAvailability from './ManageAvailability'
import HowToAddCredit from './HowToAddCredit'
import ManageProjects from './ManageProjects'
import DexterUseCase from './DexterUseCase'
import ExpertNoShow from './ExpertNoShow'

const AnchorComponent = ({ component: Component, name }) => {
    return (
        // eslint-disable-next-line
        <a
            name={name}
            style={{
                color: 'hsl(90, 14%, 11%, 0.7)',
                cursor: 'default',
                margin: '1%',
            }}
        >
            <Component />
        </a>
    )
}

const DividerExampleHidden = () => (
    <Fragment>
        <AnchorComponent name="get-started" component={GetStarted} />
        <AnchorComponent name="project-approval" component={ProjectApproval} />
        <AnchorComponent name="manage-teammates" component={ManageTeammates} />
        <AnchorComponent name="manage-meeting" component={ManageMeeting} />
        <AnchorComponent
            name="manage-availability"
            component={ManageAvailability}
        />
        <AnchorComponent name="add-credit" component={HowToAddCredit} />
        <AnchorComponent name="manage-project" component={ManageProjects} />
        <AnchorComponent name="dexter-usecase" component={DexterUseCase} />
        <AnchorComponent name="expert-no-show" component={ExpertNoShow} />
    </Fragment>
)

export default DividerExampleHidden
