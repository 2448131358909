import React, { Fragment, useState, useContext } from 'react'
import { Card, Grid, Sidebar } from 'semantic-ui-react'
import AdvisorDetail from '../Sidebar'
import AdvisorListTools from './AdvisorListTools'
import AdvisorCard from './AdvisorCard'
import AdvisorFilter from '../Filters/AdvisorFilter'
import AddToProject from './Toolbox/AddToProject'
import InviteToProject from './Toolbox/InviteToProject'
import OnboardingForm from './Toolbox/OnboardingForm'
import OnboardingMessage from './Toolbox/OnboardingMessage'
import FilterTags from './Toolbox/FilterTags'
import InviteModal from '../Modals/ProjectInvite/InviteModal'
import Pagination from '../Pagination'
import { AdvisorContext, SingleProjectContext } from '../../Context'
import './styles.css'

const Advisors = () => {
    const singleProjectContext = useContext(SingleProjectContext)
    const { advisors, currentPage, setCurrentPage, totalCount, perPage, jobs } =
        useContext(AdvisorContext)

    const [userId, setUserId] = useState(null)
    const [showSidebar, setShowSidebar] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isSelectAll, setIsSelectAll] = useState(false)
    const [selectedAdvisors, setSelectedAdvisors] = useState([])

    const onSelect = (advisor) => {
        const selectedAdvisorIds = selectedAdvisors.map((advisor) => advisor.id)
        let typeIndex = selectedAdvisorIds.indexOf(advisor.id)
        if (typeIndex === -1) selectedAdvisors.push(advisor)
        else {
            selectedAdvisors.splice(typeIndex, 1)
        }
        setSelectedAdvisors(() => [...selectedAdvisors])
    }

    const onSelectAll = (project) => {
        if (isSelectAll) {
            setSelectedAdvisors([])
            setIsSelectAll(false)
        } else {
            setSelectedAdvisors(advisors)
            setIsSelectAll(true)
        }
    }

    const { pathname } = window.location
    let type = 'ADVISOR'
    if (pathname.includes('/projects')) {
        if (pathname.includes('/advisors')) {
            type = 'SELECT_ADVISOR'
        } else if (pathname.includes('/manage-advisors')) {
            type = 'MANAGE_ADVISOR'
        }
    }

    const project = singleProjectContext ? singleProjectContext.project : {}

    return (
        <Sidebar.Pushable>
            <Sidebar
                animation="overlay"
                onHide={() => setShowSidebar(false)}
                direction="right"
                visible={showSidebar}
                style={{ background: 'white', width: '50%', zIndex: 2999 }}
            >
                <AdvisorDetail
                    userId={userId}
                    onSelect={onSelect}
                    selectedAdvisors={selectedAdvisors}
                />
            </Sidebar>
            {type === 'SELECT_ADVISOR' && <OnboardingForm isSideForm={true} />}
            <Sidebar.Pusher>
                <div className={`${type === 'ADVISOR' ? 'advisor-box' : ''}`}>
                    <FilterTags />
                </div>
                <div className="project-advisors-page">
                    <AdvisorFilter type={type} />
                    <div className="advisors-lower-section">
                        {type !== 'ADVISOR' && (
                            <InviteModal
                                onShow={showModal}
                                onHide={() => setShowModal(false)}
                                advisors={advisors}
                                selectedAdvisors={selectedAdvisors}
                                setSelectedAdvisors={setSelectedAdvisors}
                                project={project}
                            />
                        )}
                        {type === 'SELECT_ADVISOR' && (
                            <AddToProject
                                selectedAdvisors={selectedAdvisors}
                                setSelectedAdvisors={setSelectedAdvisors}
                                setShowModal={setShowModal}
                            />
                        )}
                        {type === 'MANAGE_ADVISOR' && (
                            <Fragment>
                                <InviteToProject
                                    selectedAdvisors={selectedAdvisors}
                                    setSelectedAdvisors={setSelectedAdvisors}
                                    setShowModal={setShowModal}
                                    onSelectAll={onSelectAll}
                                    isSelectAll={isSelectAll}
                                    setIsSelectAll={setIsSelectAll}
                                    jobs={jobs}
                                />
                                <OnboardingMessage />
                            </Fragment>
                        )}
                        <AdvisorListTools type={type} />
                        <Card.Group>
                            <Grid stackable columns={1}>
                                {type === 'SELECT_ADVISOR' &&
                                advisors.length === 0 ? (
                                    <OnboardingForm isSideForm={false} />
                                ) : (
                                    advisors.map((advisor, index) => {
                                        return (
                                            <Grid.Column key={index}>
                                                <AdvisorCard
                                                    advisor={advisor}
                                                    setUserId={setUserId}
                                                    setShowSidebar={
                                                        setShowSidebar
                                                    }
                                                    selectedAdvisors={
                                                        selectedAdvisors
                                                    }
                                                    onSelect={onSelect}
                                                    type={type}
                                                    job={
                                                        jobs
                                                            ? jobs.filter(
                                                                  (j) =>
                                                                      j.user
                                                                          .id ===
                                                                      advisor.id
                                                              )[0]
                                                            : null
                                                    }
                                                />
                                            </Grid.Column>
                                        )
                                    })
                                )}
                            </Grid>
                        </Card.Group>
                        <br />
                        <Pagination
                            defaultActivePage={currentPage}
                            totalPages={Math.ceil(totalCount / perPage)}
                            onPageChange={setCurrentPage}
                        />
                    </div>
                </div>
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    )
}

export default Advisors
