import React, { Fragment } from 'react'
import { useQuery } from '@apollo/client'
import { LOAD_MANAGE_EXPERTS_QUERY } from '../../../graphql/admin'
import ExpertTable from './ExpertTable'
import LoadingSpinner from '../../LoadingSpinner'

const Experts = () => {
    const { data, loading } = useQuery(LOAD_MANAGE_EXPERTS_QUERY)

    if (loading) return <LoadingSpinner />

    return (
        <Fragment>
            <h2>Review Expert extractions</h2>
            <p>Click on an expert to review their LinkedIn tag extractions</p>
            <ExpertTable dataworks={data.adminPortal.actionableExperts} />
        </Fragment>
    )
}

export default Experts
