import React from 'react'
import { Divider, Header } from 'semantic-ui-react'

const ProjectApproval = () => (
    <>
        <Header as="h2">Why do projects need to be approved?</Header>
        All new projects need to be first reviewed and approved by Dexter staff.
        This process takes 1-2 business days upon submission. During this time,
        QC measures are taken to ensure the project is correctly set up and all
        necessary information has been provided. Once approved, requests will
        automatically be sent to the selected experts. The request status can be
        monitored in real-time in the project page.
        <Divider />
    </>
)

export default ProjectApproval
