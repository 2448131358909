import React from 'react'
import { Grid } from 'semantic-ui-react'

const PanelSubheader = ({
    label,
    data,
    direction = 'col',
    labelStyle = 'text-subheader',
}) => {
    if (direction === 'row')
        return (
            <Grid.Row verticalAlign="middle" style={{ flexFlow: 'row nowrap' }}>
                <Grid.Column floated="left" width={5} className={labelStyle}>
                    {label}
                </Grid.Column>
                <Grid.Column floated="right" width={5}>
                    {data}
                </Grid.Column>
            </Grid.Row>
        )
    else if (direction === 'col')
        return (
            <Grid.Row verticalAlign="middle" style={{ flexFlow: 'row nowrap' }}>
                <Grid.Column>
                    <div className={labelStyle}>{label}</div>
                    <div>{data && data !== '' ? data : 'None'}</div>
                </Grid.Column>
            </Grid.Row>
        )
    else return null
}

export default PanelSubheader
