import { gql } from '@apollo/client'
import { MANAGE_JOB_MODEL } from './job'

const TRANSACTION_MODEL = `
    id
    type
    amount
    credits
    paymentId
    description
    createdAt
`

export const LOAD_TRANSACTIONS_QUERY = gql`
    query Transactions($teamId: ID, $pagination: Pagination, $startDate: Date, $endDate: Date) {
        transactions(teamId: $teamId, pagination: $pagination, startDate: $startDate, endDate: $endDate) {
            total
            transactions {
                ${TRANSACTION_MODEL}
                method
                enteredBy
                team {
                    id
                    name
                    creditBalance
                }
                job {
                    id
                    user {
                        id
                        email
                        fullName
                    }
                    expert {
                        fullName
                        stripe_account
                        country
                    }
                    project {
                        id
                        title
                        type
                        service_type
                        code
                    }
                }
            }
        }
    }
`

export const DEDUCT_CREDIT = gql`
    mutation DeductCredit($jobId: ID!, $payment: PaymentInput!) {
        deductCredit(jobId: $jobId, payment: $payment) {
            ${MANAGE_JOB_MODEL}
        }
    }
`

export const PAY_EXPERT = gql`
    mutation PayExpert($jobId: ID!, $payment: PaymentInput!) {
        payExpert(jobId: $jobId, payment: $payment) {
            ${MANAGE_JOB_MODEL}
        }
    }
`

export const ADD_TEAM_CREDIT_MUTATION = gql`
    mutation AddTeamCredit($teamId: ID!, $amount: Float!, $credits: Float!, $description: String!) {
        addTeamCredit(teamId: $teamId, amount: $amount, credits: $credits, description: $description) {
            ${TRANSACTION_MODEL}
            team {
                id
                creditBalance
            }
        }
    }
`

export const TRANSFER_CREDIT = gql`
    mutation TransferTeamCredit(
        $fromTeamId: ID!
        $toTeamId: ID!
        $credits: Float!
        $description: String
    ) {
        transferTeamCredit(
            fromTeamId: $fromTeamId
            toTeamId: $toTeamId
            credits: $credits
            description: $description
        ) {
            id
            team {
                id
                creditBalance
            }
        }
    }
`
export const EXTERNAL_PAYMENT_METHODS = gql`
    query ExternalPaymentMethods {
        externalPaymentMethods
    }
`

export const CHECK_PAYMENT_INFO_FIELDS = gql`
    query checkPaymentInfoFields {
        checkPaymentInfoFields
    }
`

export const LATEST_CHECK_PAYMENT = gql`
    query latestCheckPaymentInfo($expertId: ID!) {
        latestCheckPaymentInfo(expertId: $expertId)
    }
`

export const LATEST_PAYMENT = gql`
    query latestJobPayment($userId: ID!) {
        latestJobPayment(userId: $userId) {
            createdAt
            method
        }
    }
`
