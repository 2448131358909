/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
    Container,
    Header,
    Form,
    Input,
    Button,
    Table,
    Dropdown,
    Icon,
} from 'semantic-ui-react'
import {
    LOAD_TEAM_MEMBERS_QUERY,
    ADD_MEMBER_MUTATION,
} from '../../graphql/team'
import Alert from '../Alert'
import { getValidatedEmails } from '../../utils/helpers'
import { MEMBER_OPTIONS } from './options'

const AddMember = ({ team, onCancel }) => {
    const [page, setPage] = useState(1)
    const [emailListString, setEmailListString] = useState('')
    const [memberList, setMemberList] = useState([])
    const [adding, setAdding] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')

    const [addMember] = useMutation(ADD_MEMBER_MUTATION, {
        refetchQueries: [
            {
                query: LOAD_TEAM_MEMBERS_QUERY,
                variables: { id: team.id },
            },
        ],
        awaitRefetchQueries: true,
        onError: (error) => {
            setShowAlert(true)
            setAlertHeader('Error')
            setAlertBody(
                `You can not add the member: ${
                    error.message.split('GraphQL error: ')[1]
                }`
            )
        },
    })

    const onAdd = () => {
        setAdding(true)
        const promises = []
        memberList.forEach((member) => {
            promises.push(
                addMember({
                    variables: {
                        teamId: team.id,
                        email: member.email,
                        role: member.role,
                    },
                })
            )
        })
        Promise.all(promises).then(() => {
            setAdding(false)
            setPage(1)
            setMemberList([])
            setEmailListString('')
            onCancel()
        })
    }

    const renderEmailInput = () => {
        return (
            <Input
                fluid
                placeholder="Email address(es)"
                value={emailListString}
                onChange={(e, { value }) => {
                    setEmailListString(value)
                    setMemberList(
                        getValidatedEmails(value).map((email) => {
                            return { email, role: 'User' }
                        })
                    )
                }}
            />
        )
    }

    const renderMemberTable = () => {
        if (memberList.length === 0) return null
        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Email address</Table.HeaderCell>
                        <Table.HeaderCell>Role</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {memberList.map((member, index) => {
                        return (
                            <Table.Row key={index}>
                                <Table.Cell>{member.email}</Table.Cell>
                                <Table.Cell>
                                    <Dropdown
                                        options={MEMBER_OPTIONS}
                                        value={member.role}
                                        onChange={(e, { value }) => {
                                            var tempMemberList =
                                                memberList.slice()
                                            var memberEmails =
                                                tempMemberList.map(
                                                    (member) => member.email
                                                )
                                            var findIndex =
                                                memberEmails.indexOf(
                                                    member.email
                                                )
                                            if (findIndex !== -1) {
                                                tempMemberList[findIndex].role =
                                                    value
                                            }
                                            setMemberList(tempMemberList)
                                        }}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        )
    }

    const renderNextButton = () => {
        return (
            <Button
                icon
                circular
                floated="right"
                disabled={emailListString === ''}
                onClick={() => setPage(2)}
            >
                <Icon name="arrow alternate circle right" />
            </Button>
        )
    }

    const renderConfirmButton = () => {
        return (
            <div>
                <Button icon circular floated="left" onClick={() => setPage(1)}>
                    <Icon name="arrow alternate circle left" />
                </Button>
                <div style={{ float: 'right' }}>
                    <Button loading={adding} onClick={onAdd}>
                        Add
                    </Button>
                    <Button
                        onClick={() => {
                            setPage(1)
                            setEmailListString('')
                            setMemberList([])
                            onCancel()
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <Container className="side-panel">
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <Header size="huge">
                <Header.Content>Add members</Header.Content>
            </Header>
            <Header>
                <Header.Subheader>
                    {page === 1
                        ? 'Add a new user to your Dexter account with an email address'
                        : 'Roles'}
                </Header.Subheader>
            </Header>
            <Form>{page === 1 ? renderEmailInput() : renderMemberTable()}</Form>
            {page === 1 ? renderNextButton() : renderConfirmButton()}
        </Container>
    )
}

export default AddMember
