import React, { useState, useEffect } from 'react'
import { Dropdown, Header, Icon } from 'semantic-ui-react'
import COUNTRY_BY_CONT from '../../constants/country_by_cont.json'
import './styles.css'

const ExpertDropdowns = ({
    list,
    filters,
    extraFilters,
    setExtraFilters,
    updateFilter,
}) => {
    const { name, type, tags } = list
    const [values, setValues] = useState([])

    useEffect(() => {
        if (type === 'continent') {
            setValues(extraFilters[type] || [])
        } else {
            setValues(filters[type] || [])
        }
    }, [filters, extraFilters, type])

    const updateValues = (vals) => {
        setValues(vals)
        if (type === 'continent') {
            const countryGroup = vals.map((continent) => {
                return COUNTRY_BY_CONT[continent]
            })
            const countries = [].concat(...countryGroup)
            if (vals.length === 0) {
                setExtraFilters({})
            } else {
                setExtraFilters({ continent: vals })
            }
            updateFilter('country', Array.from(new Set(countries)))
        } else {
            updateFilter(type, vals)
        }
    }

    const renderFilterOptions = () => {
        if (
            type === 'organizationType' ||
            type === 'functions' ||
            type === 'technology_tags'
        ) {
            return tags
                .map((obj, index) => {
                    const header = {
                        key: `${obj.header}${index}`,
                        text: obj.header,
                        value: obj.header,
                        content: (
                            <Header
                                style={{
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    fontSize: '12px',
                                }}
                            >
                                <Icon name={obj.icon} color="black" />
                                {obj.header}
                            </Header>
                        ),
                        disabled: true,
                        style: { opacity: 1 },
                    }
                    const items = obj.items.map((item, index) => ({
                        key: `${item}${index}`,
                        text: item,
                        value: item,
                    }))
                    return [header, ...items]
                })
                .flat(1)
        }
        return tags.map((item) => ({
            key: `${type}${item}`,
            text: item,
            value: item,
        }))
    }

    return (
        <Dropdown
            placeholder={`Select ${name}`}
            fluid
            multiple
            search
            selection
            options={renderFilterOptions()}
            value={values}
            onChange={(e, { value }) => {
                updateValues(value)
            }}
        />
    )
}

export default ExpertDropdowns
