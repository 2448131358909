/* eslint-disable no-irregular-whitespace */
export const getInvitationEmail = (
    name,
    project_title,
    project_note,
    project_end_date,
    compensation
) => {
    return `
		<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
		<html data-editor-version="2" class="sg-campaigns" xmlns="http://www.w3.org/1999/xhtml">
		  <head>
		    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
		    <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1" /><!--[if !mso]><!-->
		    <meta http-equiv="X-UA-Compatible" content="IE=Edge" /><!--<![endif]-->
		    <!--[if (gte mso 9)|(IE)]>
		    <xml>
		    <o:OfficeDocumentSettings>
		    <o:AllowPNG/>
		    <o:PixelsPerInch>96</o:PixelsPerInch>
		    </o:OfficeDocumentSettings>
		    </xml>
		    <![endif]-->
		    <!--[if (gte mso 9)|(IE)]>
		    <style type="text/css">
		      body {width: 600px;margin: 0 auto;}
		      table {border-collapse: collapse;}
		      table, td {mso-table-lspace: 0pt;mso-table-rspace: 0pt;}
		      img {-ms-interpolation-mode: bicubic;}
		    </style>
		    <![endif]-->
		​
		    <style type="text/css">
		      body, p, div {
		        font-family: arial;
		        font-size: 14px;
		      }
		      body {
		        color: #000;
		      }
		      body a {
		        color: #1188E6;
		        text-decoration: none;
		      }
		      p { margin: 0; padding: 0; }
		      table.wrapper {
		        width:100% !important;
		        table-layout: fixed;
		        -webkit-font-smoothing: antialiased;
		        -webkit-text-size-adjust: 100%;
		        -moz-text-size-adjust: 100%;
		        -ms-text-size-adjust: 100%;
		      }
		      img.max-width {
		        max-width: 100% !important;
		      }
		      .column.of-2 {
		        width: 50%;
		      }
		      .column.of-3 {
		        width: 33.333%;
		      }
		      .column.of-4 {
		        width: 25%;
		      }
		      .description {
		      	white-space: pre-wrap;
		      }
		      @media screen and (max-width:480px) {
		        .preheader .rightColumnContent,
		        .footer .rightColumnContent {
		            text-align: left !important;
		        }
		        .preheader .rightColumnContent div,
		        .preheader .rightColumnContent span,
		        .footer .rightColumnContent div,
		        .footer .rightColumnContent span {
		          text-align: left !important;
		        }
		        .preheader .rightColumnContent,
		        .preheader .leftColumnContent {
		          font-size: 80% !important;
		          padding: 5px 0;
		        }
		        table.wrapper-mobile {
		          width: 100% !important;
		          table-layout: fixed;
		        }
		        img.max-width {
		          height: auto !important;
		          max-width: 480px !important;
		        }
		        a.bulletproof-button {
		          display: block !important;
		          width: auto !important;
		          font-size: 80%;
		          padding-left: 0 !important;
		          padding-right: 0 !important;
		        }
		        .columns {
		          width: 100% !important;
		        }
		        .column {
		          display: block !important;
		          width: 100% !important;
		          padding-left: 0 !important;
		          padding-right: 0 !important;
		          margin-left: 0 !important;
		          margin-right: 0 !important;
		        }
		      }
		    </style>
		    <!--user entered Head Start-->
		    
		     <!--End Head user entered-->
		  </head>
		  <body>
		    <center class="wrapper" data-link-color="#1188E6" data-body-style="font-size: 18px; font-family: arial,helvetica,sans-serif; color: ##000; background-color: #ffffff;">
		      <div class="webkit">
		        <table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="#ffffff">
		          <tr>
		            <td valign="top" bgcolor="#ffffff" width="100%">
		              <table width="100%" role="content-container" class="outer" align="center" cellpadding="0" cellspacing="0" border="0">
		                <tr>
		                  <td width="100%">
		                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
		                      <tr>
		                        <td>
		                          <!--[if mso]>
		                          <center>
		                          <table><tr><td width="600">
		                          <![endif]-->
		                          <table width="100%" cellpadding="0" cellspacing="0" border="0" style="width: 100%; max-width:600px;" align="center">
		                            <tr>
		                              <td role="modules-container" style="padding: 0px 0px 0px 0px; color: ##000; text-align: left;" bgcolor="#ffffff" width="100%" align="left">
		                                
		    <table class="module preheader preheader-hide" role="module" data-type="preheader" border="0" cellpadding="0" cellspacing="0" width="100%"
		           style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
		      <tr>
		        <td role="module-content">
		          <p>You've been invited to participate in a new project on Dexter...</p>
		        </td>
		      </tr>
		    </table>
		  
		    <table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
		      <tr>
		        <td style="font-size:6px;line-height:10px;padding:0px 0px 0px 0px;" valign="top" align="center">
							<a href="${
                                process.env.REACT_APP_EXPERT_BASE_URL
                            }"><img class="max-width" border="0" style="display:block;color:#000000;text-decoration:none;font-family:Helvetica, arial, sans-serif;font-size:16px;max-width:100% !important;width:35%;height:auto !important;" src="https://dexter-resource.s3.us-west-1.amazonaws.com/Dexter_Black.png" alt="" data-proportionally-constrained="true" width="564"></a>
						</td>
		      </tr>
		    </table>
		  
		    <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
		      <tr>
		        <td style="font-size:18px; font-family:arial,helvetica,sans-serif; padding:18px 0px 18px 0px;line-height:25px;text-align:inherit;"
		            height="100%"
		            valign="top"
		            bgcolor="">
		            <div>
		<div>
		<div>
		<p><span style="color:#000000;"><strong><span style="font-family:arial;"><span style="font-size:26px;">You have a new project!</span></span></strong></span></p>
		</div>
		</div>
		</div>
		​<p>Hi ${name},</p>
		<div>
		<div>
			${project_note && project_note === '' ? '' : `${project_note}`}
		</div>
		<br/>
		${
            compensation
                ? `
				<p>
					We are offering $${compensation} USD per hour (pro-rated) for your time and insights.
					Please note that this interview will be moderated by the client. 
					Please take a moment to review our <a href="https://dexterexperts.com/compliance" target="_blank">compliance framework</a> ahead of the interview.
				</p>
				<br/>
				<p>
					By agreeing to participate in this call, you are agreeing to Dexter <a href="https://dexterexperts.com/user-agreements">User Agreement</a>, 
					including, without limitation, the prohibition on providing confidential information contained therein.
				</p>
			`
                : ''
        }
		<br/>
		<p>If you would like to participate, please head over to Dexter to &nbsp;</span><a href="${
            process.env.REACT_APP_EXPERT_BASE_URL
        }/projects">respond t</a></span><a href="${
            process.env.REACT_APP_EXPERT_BASE_URL
        }/projects">o the invitation</a></span><span style="color:000;">.</span></p>
		</div>​
		        </td>
		      </tr>
		    </table>
		  <table border="0" cellPadding="0" cellSpacing="0" class="module" data-role="module-button" data-type="button" role="module" style="table-layout:fixed" width="100%"><tbody><tr><td align="center" class="outer-td" style="padding:00px 0px 0px 0px"><table border="0" cellPadding="0" cellSpacing="0" class="button-css__deep-table___2OZyb wrapper-mobile" style="text-align:center"><tbody><tr><td align="center" bgcolor="#13532f" class="inner-td" style="border-radius:6px;font-size:16px;text-align:center;background-color:inherit"><a style="background-color:#13532f;border:1px solid #333333;border-color:#ffffff;border-radius:6px;border-width:1px;color:#ffffff;display:inline-block;font-family:arial;font-size:16px;font-weight:normal;letter-spacing:1px;line-height:16px;padding:20px 18px 20px 18px;text-align:center;text-decoration:none" href="${
              process.env.REACT_APP_EXPERT_BASE_URL
          }/projects" target="_blank">Respond to Project Invitation  👉</a></td></tr></tbody></table></td></tr></tbody></table>
		    <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
		      <tr>
		        <td style="padding:18px 0px 18px 0px;line-height:22px;text-align:inherit;"
		            height="100%"
		            valign="top"
		            bgcolor="">
		<p dir="ltr"><span style="color:000;"><span style="font-family: arial;"><span style="font-size: 14px;">More questions? Read through our </span></span></span><span style="font-size:14px;"><span style="color:000;">Dexter</span><a href="https://www.dexterexperts.com/faq"><span style="color:000;">&nbsp;</span></a><span style="font-family: arial; font-size: 14px;"><a href="https://www.dexterexperts.com/faq"><span style="color:000;">FAQs</span></a><span style="color:000;"> and please&nbsp;feel free to respond to this email with any questions that may come to mind.</span></span></span><br />
		<span style="color:000;"><span style="font-size:14px;"><span style="font-family:arial;">See you on Dexter! 👋</span></span></span></p>
		<p><span style="color:000;"><span style="font-size:14px;"><span style="font-family:arial;">Your Dexter Team</span></span></span></p>
		        </td>
		      </tr>
		    </table>
		  <div data-role="module-unsubscribe" class="module unsubscribe-css__unsubscribe___2CDlR" role="module" data-type="unsubscribe" style="color:#444444;font-size:12px;line-height:20px;padding:16px 16px 16px 16px;text-align:center"><div class="Unsubscribe--addressLine"><p class="Unsubscribe--senderName" style="font-family:Arial,Helvetica, sans-serif;font-size:12px;line-height:20px">Dexter</p><p style="font-family:Arial,Helvetica, sans-serif;font-size:12px;line-height:20px"><span class="Unsubscribe--senderAddress">10203 Santa Monica Blvd Suite 400</span>, <span class="Unsubscribe--senderCity">Los Angeles</span>, <span class="Unsubscribe--senderState">CA</span> <span class="Unsubscribe--senderZip">90067</span> </p></div><p style="font-family:Arial,Helvetica, sans-serif;font-size:12px;line-height:20px"><a class="Unsubscribe--unsubscribeLink" href="<%asm_group_unsubscribe_raw_url%>">Unsubscribe</a> - <a class="Unsubscribe--unsubscribePreferences" href="<%asm_preferences_raw_url%>">Unsubscribe Preferences</a></p></div>
		                              </td>
		                            </tr>
		                          </table>
		                          <!--[if mso]>
		                          </td></tr></table>
		                          </center>
		                          <![endif]-->
		                        </td>
		                      </tr>
		                    </table>
		                  </td>
		                </tr>
		              </table>
		            </td>
		          </tr>
		        </table>
		      </div>
		    </center>
		  </body>
		</html>
		`
}
