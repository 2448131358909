// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link a {
  color: black;
  text-decoration: underline;
}

.invite-modal {
  margin-top: 5%;
}

.invite-modal .modal-lg {
  max-width: 90%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modals/Reschedule/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".link a {\n  color: black;\n  text-decoration: underline;\n}\n\n.invite-modal {\n  margin-top: 5%;\n}\n\n.invite-modal .modal-lg {\n  max-width: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
