import React from 'react'
import { round } from '../../../utils/helpers'
import '../styles.css'

const AcceptanceStats = ({ radius, stroke, progress }) => {
    const normalizedRadius = radius - stroke * 2
    const circumference = normalizedRadius * 2 * Math.PI
    const strokeDashoffset = circumference - (progress / 100) * circumference

    return (
        <div style={{ height: radius * 2 }}>
            <svg height={radius * 2} width={radius * 2} className="ring">
                <circle
                    stroke="#666"
                    fill="none"
                    strokeWidth={stroke}
                    strokeDasharray={circumference + ' ' + circumference}
                    strokeDashoffset={0}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                    strokeLinecap="round"
                />
                <circle
                    stroke="#00B828"
                    fill="none"
                    strokeWidth={stroke}
                    strokeDasharray={circumference + ' ' + circumference}
                    strokeDashoffset={strokeDashoffset}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                    strokeLinecap="round"
                />
            </svg>
            <div className="ring-percent">{round(progress)}%</div>
        </div>
    )
}

export default AcceptanceStats
