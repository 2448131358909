import React, { useState } from 'react'
import Template from './ViewEmailTemplate'
import EditTemplate from './EditEmailTemplate'
import { EmailTemplateContext } from '../../../Context'

const EmailTemplate = () => {
    const [showEdit, setShowEdit] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editingTemplate, setEditingTemplate] = useState(null)

    return (
        <EmailTemplateContext.Provider
            value={{
                setShowEdit,
                isEdit,
                setIsEdit,
                editingTemplate,
                setEditingTemplate,
            }}
        >
            {showEdit ? <EditTemplate /> : <Template />}
        </EmailTemplateContext.Provider>
    )
}

export default EmailTemplate
