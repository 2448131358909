import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'
import { Modal } from 'react-bootstrap'
import { Button, Form, Header } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import { CSVLink } from 'react-csv'
import { LOAD_TRANSACTIONS_QUERY } from '../../graphql/transaction'

const ExportTransactionButton = ({ team }) => {
    const [open, setOpen] = useState(false)
    const [dateRange, setDateRange] = useState([])
    const [csvData, setCsvData] = useState([])

    const [fetchTransactions, { loading }] = useLazyQuery(
        LOAD_TRANSACTIONS_QUERY
    )

    const updateDateRange = (dates) => {
        setDateRange(dates)
        if (dates[0] && dates[1]) {
            const startDate = moment(dates[0]).startOf('day').valueOf()
            const endDate = moment(dates[1]).endOf('day').valueOf()
            fetchTransactions({
                variables: { teamId: team.id, startDate, endDate },
                onCompleted: ({ transactions }) => {
                    setCsvData([
                        // Headers
                        [
                            'Transaction Date',
                            'Payment Type',
                            'Service Type',
                            'Payer',
                            'Number of Credits',
                            'Expert Name',
                            'Expert Email',
                            'Project Name',
                            'Project Type',
                            'Project Code',
                            'Payment Method',
                            'Description',
                            'Amount',
                        ],
                        ...transactions.transactions.map((transaction) => [
                            moment(transaction.createdAt).format('l'),
                            transaction.type,
                            transaction.job?.project.service_type || 'N/A',
                            transaction.enteredBy,
                            transaction.credits,
                            transaction.job?.user.fullName || 'N/A',
                            transaction.job?.user.email || 'N/A',
                            transaction.job?.project.title || 'N/A',
                            transaction.job?.project.type || 'N/A',
                            transaction.job?.project.code || 'N/A',
                            transaction.method,
                            transaction.description.replace(/[\r\n]+/g, ' '),
                            `$${transaction.amount}`,
                        ]),
                    ])
                },
            })
        }
    }
    const validDateRange = dateRange[0] && dateRange[1]
    return (
        <>
            <Modal size="sm" centered show={open} onHide={() => setOpen(false)}>
                <Modal.Header>Select a date range</Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Field>
                            {validDateRange && (
                                <Header as="h5">
                                    {`${moment(dateRange[0]).format(
                                        'l'
                                    )} - ${moment(dateRange[1]).format('l')}`}
                                </Header>
                            )}
                            <DatePicker
                                inline
                                selectsRange
                                startDate={dateRange[0]}
                                endDate={dateRange[1]}
                                maxDate={new Date()}
                                onChange={updateDateRange}
                                dateFormat="MMMM d, yyyy"
                            />
                        </Form.Field>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <CSVLink
                        className={`ui primary-btn small button ${
                            !validDateRange || loading ? 'disabled' : ''
                        }`}
                        filename={`transactions-${moment(dateRange[0]).format(
                            'l'
                        )}-${moment(dateRange[1]).format('l')}.csv`}
                        data={csvData}
                    >
                        {loading ? 'Loading data...' : 'Export'}
                    </CSVLink>
                </Modal.Footer>
            </Modal>
            <Button
                floated="right"
                content="Export History"
                onClick={() => setOpen(true)}
            />
        </>
    )
}

export default ExportTransactionButton
