import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Alert from '../../Alert'
import ProcessSuccess from './ProcessSuccess'
import Credit from './Credit'
import PaymentInfo from './PaymentInfo'
import Confirm from './Confirm'
import { lowerCase } from 'lodash'
import CheckPayment from './CheckPayment'
import './style.css'

const Payment = ({ onHide, job }) => {
    const defaultHonorarium = job.offerRate || job.user.expert.hourlyRate
    const [error, setError] = useState(null)
    const [payment, setPayment] = useState({
        type: 'Job Payment',
        amount: defaultHonorarium,
        description: `$${defaultHonorarium} for ${lowerCase(job.project.type)}`,
        method: job.user.expert.stripe_account ? 'Stripe' : null,
        credits: job.project.type === 'Interview' ? 1 : 0.25,
        checkPaymentInfo: null,
        // Additional payment data that should not include in mutation payment input
        additionalMessage: null,
        callMinutes: 60,
        hourlyRate: defaultHonorarium,
    })
    const [modalPage, setModalPage] = useState('PAYMENT')

    const closeModal = () => {
        setModalPage('PAYMENT')
        onHide()
    }

    const updatePayment = (data) => setPayment({ ...payment, ...data })
    const updatePage = (page) => (page ? setModalPage(page) : closeModal())

    const PAGES = {
        PAYMENT: (
            <PaymentInfo
                job={job}
                payment={payment}
                updatePayment={updatePayment}
                updatePage={updatePage}
            />
        ),
        CHECK: (
            <CheckPayment
                job={job}
                payment={payment}
                updatePayment={(data) => setPayment({ ...payment, ...data })}
                updatePage={updatePage}
            />
        ),
        CREDIT: (
            <Credit
                job={job}
                payment={payment}
                updatePayment={(data) => setPayment({ ...payment, ...data })}
                updatePage={updatePage}
            />
        ),
        CONFIRM: (
            <Confirm job={job} payment={payment} updatePage={updatePage} />
        ),
        SUCCESS: <ProcessSuccess />,
    }

    return (
        <Modal show onHide={closeModal} centered style={{ zIndex: 9999 }}>
            {error && (
                <Alert
                    show={true}
                    header={error.header}
                    body={error.body}
                    onClose={() => setError(null)}
                />
            )}
            <Modal.Header closeButton>
                <Modal.Title>
                    {`${job.user.fullName || job.user.email} | ${
                        job.project.title
                    }`}
                </Modal.Title>
            </Modal.Header>
            {PAGES[modalPage]}
        </Modal>
    )
}

export default Payment
