// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pushable:not(body) {
  transform: none;
}

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed;
}

.pending-card-group {
  width: 100%;
}

.pending-cards {
  padding: 0;
  font-size: 10px !important;
  margin-bottom: 1% !important;
}

.pending-cards .feed-summary-title,
.pending-cards .feed-comment {
  font-size: 14px !important;
}

.pending-cards a {
  color: black;
}

.expertise-description {
  border: 1px solid black;
  width: 100%;
}

.expertise-summary a {
  color: black;
  text-decoration: underline;
}

.sidebar-container {
  padding: 5%;
  padding-left: 10%;
}

.sidebar-container > .expert-info > .row {
  padding-top: 1rem !important;
}

.summary-container {
  max-height: 50vh;
  width: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #fff;
  border-radius: 0.2rem;
  box-shadow:
    0 1px 3px 0 #d4d4d5,
    0 0 0 1px #d4d4d5;
}

.summary-container h3 {
  font-size: 18px;
}

.span-link {
  color: blue;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;;;EAGE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;EACV,0BAA0B;EAC1B,4BAA4B;AAC9B;;AAEA;;EAEE,0BAA0B;AAC5B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;EACrB;;qBAEmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".pushable:not(body) {\n  transform: none;\n}\n\n.pushable:not(body) > .ui.sidebar,\n.pushable:not(body) > .fixed,\n.pushable:not(body) > .pusher:after {\n  position: fixed;\n}\n\n.pending-card-group {\n  width: 100%;\n}\n\n.pending-cards {\n  padding: 0;\n  font-size: 10px !important;\n  margin-bottom: 1% !important;\n}\n\n.pending-cards .feed-summary-title,\n.pending-cards .feed-comment {\n  font-size: 14px !important;\n}\n\n.pending-cards a {\n  color: black;\n}\n\n.expertise-description {\n  border: 1px solid black;\n  width: 100%;\n}\n\n.expertise-summary a {\n  color: black;\n  text-decoration: underline;\n}\n\n.sidebar-container {\n  padding: 5%;\n  padding-left: 10%;\n}\n\n.sidebar-container > .expert-info > .row {\n  padding-top: 1rem !important;\n}\n\n.summary-container {\n  max-height: 50vh;\n  width: 100vh;\n  overflow-x: hidden;\n  overflow-y: scroll;\n  background: #fff;\n  border-radius: 0.2rem;\n  box-shadow:\n    0 1px 3px 0 #d4d4d5,\n    0 0 0 1px #d4d4d5;\n}\n\n.summary-container h3 {\n  font-size: 18px;\n}\n\n.span-link {\n  color: blue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
