const renderOrgField = (field, organization) => {
    if (field === 'location' && organization.location)
        return `${organization.location.city}, ${organization.location.country}`
    else if (field === 'experts' && organization.experts)
        return `${
            organization.experts.length
        } expert(s), including ${organization.experts
            .slice(0, 5)
            .map((el) => `${el.first_name} ${el.last_name}`)
            .join(', ')} 
            ${organization.experts.length > 5 ? 'and others' : ''}`
    else return organization[field]
}
export default renderOrgField
