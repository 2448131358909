import React, { Fragment } from 'react'
import { Divider, Header } from 'semantic-ui-react'

const ExpertNoshow = () => (
    <Fragment>
        <Header as="h2">
            What do I do if the expert didn&apos;t show up to the call?
        </Header>
        If the expert did not show up 5 minutes past the scheduled time, please
        contacct us as soon as possible at{' '}
        <a href="mailto:jessica.lee@decibio.com" style={{ color: 'black' }}>
            jessica.lee@decibio.com
        </a>{' '}
        so we can take further action. If the expert is unable to make it to the
        scheduled meeting, we will do our best to coordinate with the expert to
        reschedule a meeting.
        <Divider />
    </Fragment>
)

export default ExpertNoshow
