import React, { Fragment, useState } from 'react'
import { useMutation } from '@apollo/client'
import { withRouter, Link } from 'react-router-dom'
import moment from 'moment'
import {
    Card,
    Image,
    Grid,
    Popup,
    Statistic,
    Progress,
    Icon,
} from 'semantic-ui-react'
import Alert from '../Alert'
import {
    COMPLETE_PROJECT_MUTATION,
    DELETE_PROJECT_MUTATION,
} from '../../graphql/project'

const ProjectCard = ({ project, refetch, match }) => {
    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')

    const [completeProject] = useMutation(COMPLETE_PROJECT_MUTATION, {
        onCompleted: refetch,
        onError: (error) => {
            setShowAlert(true)
            setAlertHeader('Error')
            setAlertBody(
                `You can not archive this project: ${
                    error.message.split('GraphQL error: ')[1]
                }`
            )
        },
    })
    const [deleteProject] = useMutation(DELETE_PROJECT_MUTATION, {
        onCompleted: refetch,
        onError: (error) => {
            setShowAlert(true)
            setAlertHeader('Error')
            setAlertBody(
                `You can not delete this project: ${
                    error.message.split('GraphQL error: ')[1]
                }`
            )
        },
    })

    const onDelete = () => {
        deleteProject({
            variables: {
                id: project.id,
            },
        })
    }

    const onComplete = () => {
        completeProject({
            variables: {
                id: project.id,
            },
        })
    }

    const { url } = match
    const isActiveTab = url === '/projects'
    const isDraftTab = url === '/projects/draft'

    return (
        <Grid.Column className="project-cards">
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <Card>
                <Card.Content>
                    <Popup
                        trigger={
                            <Image
                                floated="right"
                                size="mini"
                                src={
                                    project.type === 'Interview'
                                        ? '/logos/interview.svg'
                                        : '/logos/survey.svg'
                                }
                            />
                        }
                        content={`${project.type}`}
                        position="top left"
                    />
                    {isActiveTab && (
                        <Popup
                            trigger={
                                <a
                                    href="# "
                                    style={
                                        moment().diff(
                                            moment(project.end_date),
                                            'days'
                                        ) >= 0 ||
                                        project.numCompleted >= project.targetN
                                            ? { float: 'right' }
                                            : { display: 'none' }
                                    }
                                    onClick={(e) => {
                                        e.preventDefault()
                                        onComplete()
                                    }}
                                >
                                    <Icon
                                        name="archive"
                                        style={{ fontSize: '20px' }}
                                    />
                                </a>
                            }
                            content="Complete this project"
                            position="top left"
                        />
                    )}
                    {isDraftTab && (
                        <div style={{ float: 'right' }}>
                            <a
                                href="# "
                                onClick={(e) => {
                                    e.preventDefault()
                                    onDelete()
                                }}
                            >
                                <Icon
                                    name="trash alternate outline"
                                    style={{ fontSize: '20px' }}
                                />
                            </a>
                        </div>
                    )}
                    <Card.Header>
                        <Link to={`/projects/${project.id}/manage-advisors`}>
                            {project.title}
                        </Link>
                    </Card.Header>
                    <Card.Meta>
                        <Fragment>{`${project.code} | `}</Fragment>
                        {`${moment(project.start_date).format('l')} - ${moment(
                            project.end_date
                        ).format('l')}`}{' '}
                        | {project.owners[0].email}
                    </Card.Meta>
                    <Card.Description className="content-description">
                        <Statistic.Group widths={3}>
                            {isActiveTab && (
                                <Statistic horizontal>
                                    <Statistic.Value>
                                        {project.numAccepted}
                                    </Statistic.Value>
                                    <Statistic.Label>Accepted</Statistic.Label>
                                </Statistic>
                            )}
                            {isActiveTab && (
                                <Statistic horizontal>
                                    <Statistic.Value>
                                        {project.responseRate}%
                                    </Statistic.Value>
                                    <Statistic.Label>
                                        Response Rate
                                    </Statistic.Label>
                                </Statistic>
                            )}
                            <Statistic style={{ width: '100%' }} horizontal>
                                <Statistic.Value
                                    style={{
                                        height: '35px',
                                        width: '100%',
                                    }}
                                >
                                    <Progress
                                        value={project.numCompleted}
                                        total={project.targetN}
                                        progress="ratio"
                                        indicating
                                    />
                                </Statistic.Value>
                                <Statistic.Label>Completed</Statistic.Label>
                            </Statistic>
                        </Statistic.Group>
                    </Card.Description>
                </Card.Content>
            </Card>
        </Grid.Column>
    )
}

export default withRouter(ProjectCard)
