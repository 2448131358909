import React, { Fragment } from 'react'
import { Grid, Card } from 'semantic-ui-react'
import { getHTMLFromEditorState } from '../../utils/helpers'

const AdvisorExpertise = ({ advisor }) => {
    const summary =
        advisor.expert.infoFromLinkedin &&
        advisor.expert.infoFromLinkedin !== 'NaN'
            ? advisor.expert.infoFromLinkedin
            : advisor.expert.description

    return (
        <Grid.Row>
            {summary ? (
                <Fragment>
                    <span>About the advisor</span>
                    <br />
                    <div className="summary-container">
                        <Card fluid>
                            <Card.Content>
                                <Card.Description className="expertise-summary">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: getHTMLFromEditorState(
                                                summary
                                            ),
                                        }}
                                    ></div>
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                </Fragment>
            ) : null}
        </Grid.Row>
    )
}

export default AdvisorExpertise
