import React from 'react'
import { Button } from 'semantic-ui-react'

const NavigateButtons = ({
    onCancel,
    disabled,
    hidden = false,
    step,
    setStep,
}) => {
    const TOTAL_STEPS = 3

    const next = () => {
        if (step < TOTAL_STEPS - 1) {
            setStep(step + 1)
        }
    }
    const prev = () => {
        if (step > 0) {
            setStep(step - 1)
        }
    }
    return (
        <div className="nav-buttons">
            {step > 0 && (
                <>
                    <Button floated="left" icon="left chevron" onClick={prev} />
                    <Button floated="left" onClick={onCancel}>
                        Cancel
                    </Button>
                </>
            )}
            {step < TOTAL_STEPS - 1 && (
                <Button
                    floated="right"
                    icon="right chevron"
                    onClick={next}
                    disabled={disabled}
                    hidden={hidden}
                />
            )}
        </div>
    )
}

export default NavigateButtons
