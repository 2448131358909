class User {
    constructor(user) {
        Object.assign(this, user)
        this.currentTeam = user.membership[0].teamId
    }

    isSuperUser() {
        if (!this.membership) return false
        return this.membership.some(
            (membership) =>
                membership.teamName === 'Dexter' && membership.role === 'Admin'
        )
    }

    isTeamAdmin() {
        if (!this.membership) return false
        const currentMembership = this.membership.filter(
            (membership) => membership.teamId === this.currentTeam
        )[0]
        if (!currentMembership) return false
        return currentMembership.role === 'Admin'
    }

    isTeamMember() {
        if (!this.membership) return false
        return (
            this.isSuperUser() ||
            this.membership.some(
                (membership) => membership.teamId === this.currentTeam
            )
        )
    }

    isDecibio() {
        return this.membership.some((obj) => obj.teamName === 'Decibio')
    }

    setCurrentTeam(teamId) {
        this.currentTeam = teamId
    }
}

export default User
