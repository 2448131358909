import React, { useState } from 'react'
import { Popup, Checkbox, Icon, Button } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'

const OnboardingCheckbox = ({ onShow, onHide, onPreview }) => {
    const [isCompleted, setIsCompleted] = useState(false)

    return (
        <Modal show={onShow} onHide={onHide} centered>
            <Modal.Body>
                <Checkbox
                    label="Check if the expert has completed the project already"
                    checked={isCompleted}
                    onChange={(e, { checked }) => setIsCompleted(checked)}
                />
                <Popup
                    content="The project will be added to the expert dashboard as a completed project"
                    trigger={
                        <Icon
                            name="info circle"
                            style={{ marginLeft: '5px' }}
                        />
                    }
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onPreview(isCompleted)}>
                    Preview email
                </Button>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OnboardingCheckbox
