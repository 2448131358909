import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Form, Search } from 'semantic-ui-react'
import { AUTO_COMPLETE_QUERY } from '../../../../graphql/organizations'

const Autocomplete = ({
    context,
    onChange,
    value,
    onSelect,
    readOnly,
    exclude,
    ...rest
}) => {
    const [results, setResults] = useState([])

    const { loading } = useQuery(AUTO_COMPLETE_QUERY, {
        variables: { context, query: value },
        onCompleted: (data) => {
            const autocompleteResults = data.autocomplete
                .filter((result) => !exclude.includes(result.value))
                .map((result) => ({
                    id: result.value,
                    title: result.display,
                    organization: result.organization,
                }))

            setResults(autocompleteResults)
        },
    })

    const setSelection = (query, organizationId, organization = null) => {
        onChange(query)
        onSelect({ organizationId, organization })
    }

    if (readOnly) {
        return (
            <Form.Input
                {...rest}
                icon={{
                    name: 'close',
                    link: true,
                    color: 'red',
                    onClick: () => setSelection('', null),
                }}
                value={value}
                readOnly
            />
        )
    }
    return (
        <Form.Field
            required={rest.required}
            width={rest.width}
            error={rest.error}
        >
            <Search
                fluid
                showNoResults={false}
                minCharacters={3}
                loading={loading}
                placeholder={rest.placeholder}
                onSearchChange={(e, { value }) => onChange(value)}
                onResultSelect={(e, { result }) =>
                    setSelection(result.title, result.id, result.organization)
                }
                results={results}
                value={value}
            />
        </Form.Field>
    )
}

export default Autocomplete
