import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Modal } from 'react-bootstrap'
import { Form, Dropdown, TextArea } from 'semantic-ui-react'

const CancelContent = (props, ref) => {
    const { name, title, meetingTime } = props

    const [reason, setReason] = useState('Project scope change')
    const [otherText, setOtherText] = useState('')

    const cancelOption = [
        {
            key: 'Project scope change',
            value: 'Project scope change',
            text: 'Project scope change',
        },
        {
            key: 'Other',
            value: 'Other',
            text: 'Other',
        },
    ]

    useImperativeHandle(ref, () => {
        return {
            state: {
                reason,
                otherText,
            },
        }
    })

    return (
        <Modal.Body>
            <Form>
                <Form.Field>
                    <label>Reason for cancellation</label>
                    <Dropdown
                        placeholder="Select reason"
                        selection
                        fluid
                        value={reason}
                        options={cancelOption}
                        onChange={(e, { value }) => setReason(value)}
                    />
                </Form.Field>
                <Form.Field
                    control={TextArea}
                    label="Briefly explain the reason"
                    value={otherText}
                    onChange={(e, { value }) => setOtherText(value)}
                />
            </Form>
            <p>
                If you hit &quot;Submit&quot;,
                {`${name} | ${title} at ${meetingTime} `}
                will be canceled
            </p>
        </Modal.Body>
    )
}

export default forwardRef(CancelContent)
