import React from 'react'
import { Divider, Header } from 'semantic-ui-react'

const ManageMeeting = () => (
    <>
        <Header as="h2">Can I cancel or reschedule a meeting?</Header>
        If you need to cancel or request a scheduled meeting, you can do so
        either from the Home page or the project page. In the Home page, find
        the meeting you wish to cancel or reschedule in the upcoming meetings.
        Click on the meeting to open a modal that shows further details about
        the meeting. At the bottom of this modal, you should see an option to
        &quot;Request to cancel&quot; or &quot;Request to reschedule&quot;. If
        you proceed to cancel, you will be asked to provide a reason for
        cancelling. If you proceed to reschedule, you will be asked to make sure
        you update your availability as soon as possible. Please give at least
        48 hours notice to cancel or reschedule any meetings.
        <Divider />
    </>
)

export default ManageMeeting
