import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react'
import { Form, Radio } from 'semantic-ui-react'

const Information2 = (props, ref) => {
    const { project, error } = props

    const [projectCode, setProjectCode] = useState('')
    const [targetN, setTargetN] = useState(1)
    const [referable, setReferable] = useState(false)

    useEffect(() => {
        if (project.id) {
            setProjectCode(project.code)
            setTargetN(project.targetN)
            setReferable(project.referable || false)
        }
    }, [project])

    useImperativeHandle(ref, () => {
        return {
            state: {
                projectCode,
                targetN,
                referable,
            },
        }
    })

    const readonly = project.status && project.status !== 'Draft'
    const isCompleted = project.status && project.status === 'Completed'

    return (
        <>
            <Form.Group widths={2}>
                <Form.Input
                    error={error && error.code}
                    label="Case Code"
                    placeholder="Enter a case code"
                    value={projectCode}
                    onChange={(e, { value }) => setProjectCode(value)}
                    disabled={readonly}
                />
                <Form.Input
                    error={error && error.targetN}
                    label="Target participants N"
                    type="number"
                    value={targetN}
                    onChange={(e, { value }) => setTargetN(value)}
                    disabled={isCompleted}
                />
            </Form.Group>
            <Form.Field>
                <label>Enable referrals</label>
                <Radio
                    toggle
                    checked={referable}
                    label="Allow experts to share this project with other people"
                    onChange={(e, { checked }) => setReferable(checked)}
                />
            </Form.Field>
        </>
    )
}

export default forwardRef(Information2)
