import React, { Fragment } from 'react'
import { Divider, Header } from 'semantic-ui-react'

const DexterUsecase = () => (
    <Fragment>
        <Header as="h2" name="dexter-usecase">
            Can I use Dexter to talk to people at my competitors?
        </Header>
        Dexter is built to enable and facilitate voice-of-customer research. It
        is not designed as a competitive intelligence tool. Our{' '}
        <a
            href="https://dexterexperts.com/user-agreements"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'black' }}
        >
            {' '}
            User Agreement{' '}
        </a>{' '}
        also clearly states that seeking confidential information or
        misrepresenting one&apos;s identity or the purpose of seeking knowledge
        is prohibited. We ask all users to respect and abide by these terms when
        using Dexter.
        <Divider />
    </Fragment>
)

export default DexterUsecase
