import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import ProjectDetails from './ProjectDetails'
import SelectAdvisors from './SelectAdvisors'
import ManageAdvisors from './ManageAdvisors'
import EmailTemplate from './EmailTemplate'

const SingleProjectRoutes = () => {
    return (
        <Switch>
            <Route
                exact
                path="/projects/:id"
                render={() => <Redirect to="/projects/:id/details" />}
            />
            <Route
                exact
                path="/projects/:id/details"
                component={ProjectDetails}
            />
            <Route
                exact
                path="/projects/:id/email-template"
                component={EmailTemplate}
            />
            <Route
                exact
                path="/projects/:id/advisors"
                component={SelectAdvisors}
            />
            <Route
                exact
                path="/projects/:id/manage-advisors"
                component={ManageAdvisors}
            />
            <Route render={() => <Redirect to="/not-found-page" />} />
        </Switch>
    )
}

export default SingleProjectRoutes
