import React from 'react'
import ProjectDetails from '../components/SingleProject/ProjectDetails'

const NewProject = () => (
    <div className="project-section">
        <h4 className="title">Create New Project</h4>
        <ProjectDetails />
    </div>
)

export default NewProject
