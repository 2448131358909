import React, { useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Table, Grid } from 'semantic-ui-react'
import moment from 'moment-timezone'
import '../styles.css'

import Pagination from '../../Pagination'

const PER_PAGE = 10

const ExpertTable = ({ dataworks }) => {
    const [currentPage, setCurrentPage] = useState(1)

    if (dataworks.length === 0) return <div>No experts ready to review</div>

    const end = currentPage * PER_PAGE
    const start = end - PER_PAGE

    return (
        <Fragment>
            <Table celled padded size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Full Name</Table.HeaderCell>
                        <Table.HeaderCell>Join Date</Table.HeaderCell>
                        <Table.HeaderCell>Job Title</Table.HeaderCell>
                        <Table.HeaderCell>Organization</Table.HeaderCell>
                        <Table.HeaderCell>LinkedIn</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {dataworks.slice(start, end).map((dw) => {
                        return (
                            <Table.Row key={dw.id}>
                                <Table.Cell>
                                    <Link
                                        to={`/admin/experts/${dw.expert.id}/review`}
                                        className="link"
                                    >
                                        {dw.expert.fullName}
                                    </Link>
                                </Table.Cell>
                                <Table.Cell>
                                    {moment
                                        .unix(dw.expert.created_at / 1000)
                                        .tz(moment.tz.guess())
                                        .format('MM-DD-YY HH:mm z')}
                                </Table.Cell>
                                <Table.Cell>{dw.expert.title}</Table.Cell>
                                <Table.Cell>
                                    <a
                                        href={dw.expert.organization.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        {dw.expert.organization.name}
                                    </a>
                                </Table.Cell>
                                <Table.Cell>
                                    <a
                                        href={dw.linkedinUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="link wrapword"
                                    >
                                        {dw.linkedinUrl}
                                    </a>
                                </Table.Cell>
                                <Table.Cell>{`${dw.status} (scraped at ${moment
                                    .unix(dw.scrapedAt / 1000)
                                    .tz(moment.tz.guess())
                                    .format('MM-DD-YY HH:mm z')})`}</Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
            <Grid centered style={{ marginBottom: '40px' }}>
                <Grid.Row verticalAlign="middle">
                    <Grid.Column floated="left" width={5}>
                        <p>
                            Showing {start + 1} - {end} of {dataworks.length}{' '}
                            experts
                        </p>
                    </Grid.Column>
                    <Grid.Column floated="right" width={5}>
                        <Pagination
                            defaultActivePage={currentPage}
                            totalPages={Math.ceil(dataworks.length / PER_PAGE)}
                            onPageChange={setCurrentPage}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Fragment>
    )
}

export default ExpertTable
