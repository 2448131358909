import React from 'react'
import { useQuery } from '@apollo/client'
import { Container, Header, Grid } from 'semantic-ui-react'
import { LOAD_USER_QUERY } from '../../graphql/user'
import AdvisorStatus from './AdvisorStatus'
import AdvisorProjects from './AdvisorProjects'
import AdvisorTags from './AdvisorTags'
import AdvisorExpertise from './AdvisorExpertise'
import LoadingSpinner from '../LoadingSpinner'
import './styles.css'

const AdvisorDetail = ({ userId, onSelect, selectedAdvisors }) => {
    const { loading, data } = useQuery(LOAD_USER_QUERY, {
        variables: {
            id: userId,
        },
        skip: !userId,
    })

    if (loading) return <LoadingSpinner />

    if (!data) return <div></div>

    return (
        <Container className="sidebar-container">
            <Header size="huge" textAlign="center">
                <Header.Content>
                    {data.user.fullName || data.user.email}
                    <Header.Subheader>
                        {`${data.user.expert.title} | ${data.user.expert.organization.name}`}
                    </Header.Subheader>
                </Header.Content>
            </Header>
            <Grid className="expert-info">
                <AdvisorStatus
                    advisor={data.user}
                    onSelect={onSelect}
                    selectedAdvisors={selectedAdvisors}
                />
                <AdvisorExpertise advisor={data.user} />
                <AdvisorProjects advisor={data.user} />
                <AdvisorTags advisor={data.user} />
            </Grid>
        </Container>
    )
}

export default AdvisorDetail
