import React, { useContext } from 'react'
import { ExtractionReviewContext } from '../../../../Context'
import PanelHeader from '../PanelHeader'
import PanelSubheader from '../PanelSubheader'

const ExpertInfo = () => {
    const { datawork } = useContext(ExtractionReviewContext)

    return (
        <>
            <PanelHeader label={datawork.expert.fullName} />
            <div className="panel-section-subcol fullwidth">
                <PanelSubheader
                    label="CURRENT TITLE"
                    data={datawork.expert.title}
                />
                <PanelSubheader
                    label="CURRENT ORGANIZATION"
                    data={datawork.expert.organization.name}
                />
                <div className="divider fullwidth" />
                <PanelSubheader
                    label="EDUCATION"
                    data={datawork.expert.education}
                />
                <PanelSubheader
                    label="FUNCTIONS"
                    data={datawork.expert.functions.join('; ')}
                />
                <PanelSubheader
                    label="MEDICAL SPECIALTIES"
                    data={datawork.expert.medical_specialties.join('; ')}
                />
                <PanelSubheader
                    label="TECHNOLOGY TAGS"
                    data={datawork.expert.technology_tags.join('; ')}
                />
                <PanelSubheader
                    label="INTERNAL TAGS"
                    data={datawork.expert.internal_tags.join('; ')}
                />
            </div>
        </>
    )
}

export default ExpertInfo
