import { createContext } from 'react'

// TODO: Manage all context files into a folder similar to Client app
export const AdvisorContext = createContext(null)
export const ProjectContext = createContext(null)
export const SingleProjectContext = createContext(null)
export const EmailTemplateContext = createContext(null)
export const TeamManageContext = createContext(null)
export const DashboardContext = createContext(null)
export const ExtractionReviewContext = createContext(null)
