import React, { useState } from 'react'
import { Button, Sidebar } from 'semantic-ui-react'
import MemberTable from './MemberTable'
import AddMember from './AddMember'
import EditMember from './EditMember'

const TeamMemberPage = ({ team }) => {
    const [selectedMember, setSelectedMember] = useState({})
    const [showPanel, setShowPanel] = useState(false)
    const [panelType, setPanelType] = useState('ADD_MEMBER')

    return (
        <Sidebar.Pushable style={{ width: '100%' }}>
            <Sidebar
                animation="overlay"
                onHide={() => setShowPanel(false)}
                direction="right"
                visible={showPanel}
                style={{ background: 'white', width: '25%', zIndex: 2999 }}
            >
                {panelType === 'ADD_MEMBER' ? (
                    <AddMember
                        team={team}
                        onCancel={() => setShowPanel(false)}
                    />
                ) : (
                    <EditMember
                        member={selectedMember}
                        team={team}
                        onCancel={() => setShowPanel(false)}
                    />
                )}
            </Sidebar>
            <Sidebar.Pusher>
                <Button
                    primary
                    floated="right"
                    style={{ margin: '1% 0 1% 0' }}
                    onClick={() => {
                        setPanelType('ADD_MEMBER')
                        setShowPanel(true)
                    }}
                >
                    Add members
                </Button>
                <MemberTable
                    members={team.members}
                    team={team}
                    onUpdateMember={(member) => {
                        setPanelType('UPDATE_MEMBER')
                        setSelectedMember(member)
                        setShowPanel(true)
                    }}
                />
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    )
}

export default TeamMemberPage
