import React from 'react'
import { Divider, Header } from 'semantic-ui-react'

const ManageAvailability = () => (
    <>
        <Header as="h2">How do I update my availability?</Header>
        You can manage your default availability from the Profile page. You can
        select your default timezone and manage your general availability by day
        of the week. This will then be used as your default availability across
        all of your projects. You will also have a chance to customize and
        fine-tune your availability for each project in the project&apos;s Set
        Availability tab. Your project availability can be updated at any time
        while the project is active.
        <Divider />
    </>
)

export default ManageAvailability
