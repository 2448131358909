// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recommendation {
  font-size: 14px;
  color: red;
}

.bottom-margin {
  margin-bottom: 1rem;
}

.left-margin {
  margin-left: 1rem;
}

.credit-calc-header {
  font-size: 1rem;
}

.top-bottom-margin {
  margin: 1rem 0;
}

.top-margin-one {
  margin-top: 1rem;
}

.top-margin-two {
  margin-top: 2rem;
}

.highlight {
  color: teal;
}

.top-margin-small {
  margin-top: 10;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modals/Payment/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".recommendation {\n  font-size: 14px;\n  color: red;\n}\n\n.bottom-margin {\n  margin-bottom: 1rem;\n}\n\n.left-margin {\n  margin-left: 1rem;\n}\n\n.credit-calc-header {\n  font-size: 1rem;\n}\n\n.top-bottom-margin {\n  margin: 1rem 0;\n}\n\n.top-margin-one {\n  margin-top: 1rem;\n}\n\n.top-margin-two {\n  margin-top: 2rem;\n}\n\n.highlight {\n  color: teal;\n}\n\n.top-margin-small {\n  margin-top: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
