import { gql } from '@apollo/client'

const ORGANIZATION_MODEL = `
  id
  name
  type
  size
  status
  location {
    city
    country
  }
  experts {
    id
    first_name
    last_name
  }
  description
  url
`

export const AUTO_COMPLETE_QUERY = gql`
    query Autocomplete($context: String, $query: String) {
        autocomplete(context: $context, query: $query) {
            value
            display
        }
    }
`

export const MERGE_ORGANIZATION = gql`
  mutation MergeOrganization($removeOrgId: ID!, $remainOrgId: ID!) {
    mergeOrganization(removeOrgId: $removeOrgId, remainOrgId: $remainOrgId) {
        ${ORGANIZATION_MODEL}
    }
  }
`

export const LOAD_SINGLE_ORGANIZATION_QUERY = gql`
  query Organization($organizationId: ID!) {
    organization(id: $organizationId) {
      ${ORGANIZATION_MODEL}
    }
  }
`
