export const MEMBER_OPTIONS = [
    {
        key: 'Admin',
        value: 'Admin',
        text: 'Admin',
    },
    {
        key: 'User',
        value: 'User',
        text: 'User',
    },
]
