import React, { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { Loader } from 'semantic-ui-react'
import _ from 'lodash'
import { LOAD_NUM_EXPERTS, LOAD_EXPERTS_QUERY } from '../../../graphql/user'
import Advisors from '../../Advisors'
import { SingleProjectContext, AdvisorContext } from '../../../Context'

const SelectAdvisors = () => {
    const PER_PAGE = 10

    const { project } = useContext(SingleProjectContext)

    const [filters, setFilters] = useState({ hourlyRate: [0, 9999] })
    const [sortBy, setSortBy] = useState({ responseRate: 'desc' })
    const [currentPage, setCurrentPage] = useState(1)
    const [triggerInit, setTriggerInit] = useState(false)

    const {
        data: expertsData,
        loading: expertsLoading,
        refetch: expertsRefetch,
    } = useQuery(LOAD_EXPERTS_QUERY, {
        variables: {
            query: filters.query,
            filters: _.omit(filters, 'query'),
            sortBy,
            pagination: { page: currentPage, per_page: PER_PAGE },
        },
    })

    const {
        data: numExpertsData,
        loading: numExpertsLoading,
        refetch: numExpertsRefetch,
    } = useQuery(LOAD_NUM_EXPERTS, {
        variables: {
            query: filters.query,
            filters: _.omit(filters, 'query'),
            pagination: { page: 1, per_page: Math.pow(2, 30) },
        },
    })

    useEffect(() => {
        expertsRefetch()
        numExpertsRefetch()
        window.scrollTo(0, 0)
    }, [sortBy, triggerInit, expertsRefetch, numExpertsRefetch])

    const experts = expertsData
        ? expertsData.experts.filter(
              (expert) =>
                  !project.jobs.map((job) => job.user.id).includes(expert.id)
          )
        : []

    return (
        <AdvisorContext.Provider
            value={{
                advisors: experts.map((expert) => ({ ...expert.user, expert })),
                project,
                loadAdvisor: () => setTriggerInit(!triggerInit),
                filters,
                setFilters,
                sortBy,
                setSortBy,
                currentPage,
                setCurrentPage,
                totalCount: numExpertsData ? numExpertsData.experts.length : 0,
                perPage: PER_PAGE,
            }}
        >
            <Loader active={expertsLoading || numExpertsLoading} />
            <Advisors />
        </AdvisorContext.Provider>
    )
}

export default SelectAdvisors
