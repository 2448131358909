import React, { useState } from 'react'
import { Form, Button, Icon } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import AdvisorTags from '../../../Advisors/AdvisorTags'
import Honorarium from './Honorarium'
import PaymentMethods from './PaymentMethods'
import Description from './Description'
import Message from './Message'
import Alert from '../../../Alert'
import './style.css'

const PaymentInfo = ({ job, payment, updatePayment, updatePage }) => {
    const [error, setError] = useState(null)

    const onNext = () => {
        if (!job.user.expert.stripe_account) {
            if (!payment.method) {
                setError({
                    header: 'Missing required field',
                    body: 'Please select a payment method',
                })
                return
            }
        } else {
            updatePayment({ method: 'Stripe' })
        }
        updatePage('CONFIRM')
    }
    const onBack = () => updatePage(null)
    return (
        <Modal.Body>
            {error && (
                <Alert
                    show={true}
                    header={error.header}
                    body={error.body}
                    onClose={() => setError(null)}
                />
            )}
            <Form>
                <Honorarium
                    payment={payment}
                    updatePayment={updatePayment}
                    job={job}
                />
                <Description payment={payment} updatePayment={updatePayment} />
                {!job.user.expert.stripe_account && (
                    <PaymentMethods
                        payment={payment}
                        updatePayment={updatePayment}
                        job={job}
                    />
                )}
                <div className="payment-tags-section">
                    <Form.Field>
                        <label>Tags</label>
                    </Form.Field>
                    <div>
                        <AdvisorTags advisor={job.user} />
                    </div>
                </div>
            </Form>
            <br />
            <Message
                job={job}
                payment={payment}
                updatePayment={updatePayment}
            />
            <Modal.Footer>
                <Button icon basic onClick={onBack}>
                    <Icon name="angle left" />
                </Button>
                <Button onClick={onNext}>Next</Button>
            </Modal.Footer>
        </Modal.Body>
    )
}

export default PaymentInfo
