import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'

const Title = () => {
    const pageHistory = useHistory()
    const goToExperts = useCallback(
        () => pageHistory.push('/admin/experts'),
        [pageHistory]
    )

    return (
        <Grid.Row className="panel-section">
            <Grid.Column className="panel-section-col">
                <div className="action-button" onClick={goToExperts}>
                    <MdOutlineKeyboardBackspace size={28} />
                    <span className="margin-top-4">Back to experts</span>
                </div>
            </Grid.Column>
        </Grid.Row>
    )
}

export default Title
