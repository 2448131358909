import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Message, Statistic } from 'semantic-ui-react'

const Heading = ({ match, team, totalCredit }) => (
    <Message floating style={{ width: '100%' }}>
        <Statistic.Group widths="four">
            <Statistic>
                <Statistic.Value>{totalCredit}</Statistic.Value>
                <Statistic.Label>Credit(s)</Statistic.Label>
            </Statistic>

            <Statistic>
                <Statistic.Value>{team.numJobsCompleted}</Statistic.Value>
                <Statistic.Label>Job(s)</Statistic.Label>
            </Statistic>

            {match.path !== '/team/credits' && (
                <Fragment>
                    <Statistic>
                        <Statistic.Value>0</Statistic.Value>
                        <Statistic.Label>Revenue</Statistic.Label>
                    </Statistic>

                    <Statistic>
                        <Statistic.Value>0</Statistic.Value>
                        <Statistic.Label>Profit</Statistic.Label>
                    </Statistic>
                </Fragment>
            )}
        </Statistic.Group>
    </Message>
)

export default withRouter(Heading)
