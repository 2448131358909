import React from 'react'

const CustomLink = ({ children, onClick = null }) => (
    // The aria-hidden is required to handle eslint error - jsx-a11y/click-events-have-key-events
    // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/93f78856655696a55309440593e0948c6fb96134/docs/rules/click-events-have-key-events.md
    <span
        role="link"
        tabIndex={0}
        className="span-link"
        onClick={onClick}
        aria-hidden="true"
    >
        {children}
    </span>
)

export default CustomLink
