/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

const ProjectMenu = ({ match, history }) => {
    const [activeItem, setActiveItem] = useState('active')

    useEffect(() => {
        setActiveItem(match.url.split('/')[2] || 'active')
    }, [match.url])

    const onNavigate = (e, { name }) => {
        setActiveItem(name)
        if (name === 'active') history.push('/projects')
        else history.push(`/projects/${name}`)
    }

    return (
        <Menu tabular>
            <Menu.Item
                name="active"
                active={activeItem === 'active'}
                onClick={onNavigate}
            >
                Active
            </Menu.Item>

            <Menu.Item
                name="draft"
                active={activeItem === 'draft'}
                onClick={onNavigate}
            >
                Draft
            </Menu.Item>
            <Menu.Item
                name="completed"
                active={activeItem === 'completed'}
                onClick={onNavigate}
            >
                Completed
            </Menu.Item>
        </Menu>
    )
}

export default withRouter(ProjectMenu)
