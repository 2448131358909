import React from 'react'
import { Grid, Header } from 'semantic-ui-react'

const RevenueStats = ({ transactions, lastTransactions }) => {
    const getPercentGrowth = (current, last) => {
        if (last === 0) return `+${current}`
        const result = Math.floor((current / last - 1) * 100)
        return result >= 0 ? `+${result}` : result
    }
    const getTotalAmountStats = (type) => {
        const getTotal = (transactions) =>
            transactions
                .filter((t) => t.type === type)
                .reduce((sum, t) => sum + t.amount, 0)
        const currentPeriod = getTotal(transactions)
        const previousPeriod = getTotal(lastTransactions)
        const percentGrowth = getPercentGrowth(currentPeriod, previousPeriod)
        return { currentPeriod, previousPeriod, percentGrowth }
    }

    const creditPurchases = getTotalAmountStats('Credit Purchase')
    const honorariums = getTotalAmountStats('Job Payment')

    const STATS_COLUMNS = [
        {
            title: 'Credit Purchased',
            total: creditPurchases.currentPeriod,
            percentGrowth: creditPurchases.percentGrowth,
        },
        {
            title: 'Honoraria Payments',
            total: honorariums.currentPeriod,
            percentGrowth: honorariums.percentGrowth,
        },
        {
            title: 'Cash Flow after Honoraria Payments',
            total: creditPurchases.currentPeriod - honorariums.currentPeriod,
            percentGrowth: getPercentGrowth(
                creditPurchases.currentPeriod - honorariums.currentPeriod,
                creditPurchases.previousPeriod - honorariums.previousPeriod
            ),
        },
    ]

    return (
        <Grid style={{ margin: '1rem' }}>
            <Grid.Row style={{ marginLeft: '1rem' }}>
                <Header as="h6">Revenue and Cash Flow</Header>
            </Grid.Row>
            <Grid.Row centered columns={3}>
                {STATS_COLUMNS.map((col) => (
                    <Grid.Column key={col.title}>
                        <Header as="h6">{col.title}</Header>
                        <Header.Subheader>Total</Header.Subheader>
                        <Header.Subheader>
                            {`$${parseFloat(col.total).toFixed(2)}`}
                        </Header.Subheader>
                        <Header.Subheader>
                            {`${col.percentGrowth}% since last period`}
                        </Header.Subheader>
                    </Grid.Column>
                ))}
            </Grid.Row>
        </Grid>
    )
}

export default RevenueStats
