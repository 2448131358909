import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Input, Dropdown } from 'semantic-ui-react'
import {
    ContentState,
    EditorState,
    convertToRaw,
    convertFromHTML,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { UPDATE_PROJECT_MUTATION } from '../../../graphql/project'
import {
    getInvitationEmail,
    getInvitationEmailMarkup,
    getInviteReminderEmail,
    getInvitationReminderEmailMarkup,
    getInvitePendingEmail,
    getInvitationPendingEmailMarkup,
} from '../../Emails'
import {
    getHTMLFromEditorState,
    getEditorStateFromHTML,
} from '../../../utils/helpers'
import { EMAIL_TEMPLATE_CATEGORY_OPTIONS } from '../../../utils/options'
import { SingleProjectContext, EmailTemplateContext } from '../../../Context'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const EditTemplate = () => {
    const { project } = useContext(SingleProjectContext)
    const {
        setShowEdit,
        isEdit,
        setIsEdit,
        editingTemplate,
        setEditingTemplate,
    } = useContext(EmailTemplateContext)

    const [email_subject, setEmailSubject] = useState(
        isEdit ? editingTemplate.subject : ''
    )
    const [email_content, setEmailContent] = useState(
        isEdit ? getHTMLFromEditorState(editingTemplate.content) : ''
    )
    const [category, setCategory] = useState(
        isEdit ? editingTemplate.category : ''
    )
    const [editorState, setEditorState] = useState(
        isEdit ? getEditorStateFromHTML(editingTemplate.content) : null
    )

    const [updateEmailTemplate] = useMutation(UPDATE_PROJECT_MUTATION, {
        onCompleted() {
            setIsEdit(false)
            setShowEdit(false)
            setEditingTemplate(null)
        },
    })

    const getEmailHTML = (category) => {
        switch (category) {
            case 'New Project Invite':
                return getInvitationEmail(
                    '[Salutation, if available] [Advisor full name]',
                    project.title,
                    email_content,
                    project.end_date
                )
            case 'Project Invite Reminder':
                return getInviteReminderEmail(
                    '[Salutation, if available] [Advisor full name]',
                    email_content
                )
            case 'Dexter Onboarding':
                return getInvitePendingEmail(email_content)
            default:
                return editorState
                    ? getHTMLFromEditorState(
                          JSON.stringify(
                              convertToRaw(editorState.getCurrentContent())
                          )
                      )
                    : null
        }
    }

    const getEditState = (category) => {
        let markup = null
        let subject = ''

        switch (category) {
            case 'New Project Invite':
                markup = getInvitationEmailMarkup(
                    project.title,
                    getHTMLFromEditorState(project.description)
                )
                subject = `Dexter: New Project Invite | ${project.title}`
                break
            case 'Project Invite Reminder':
                markup = getInvitationReminderEmailMarkup(project.title)
                subject = `Dexter: Project Invite Reminder | ${project.title}`
                break
            case 'Dexter Onboarding':
                markup = getInvitationPendingEmailMarkup(project.title)
                subject = `Dexter: Your invite to our expert network`
                break
            default:
                setEditorState(null)
                setEmailSubject('')
                setEmailContent('')
                return
        }

        const blocksFromHTML = convertFromHTML(markup)

        const editorState = EditorState.createWithContent(
            ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            )
        )

        setEditorState(editorState)
        setEmailSubject(subject)
        setEmailContent(
            getHTMLFromEditorState(
                JSON.stringify(convertToRaw(editorState.getCurrentContent()))
            )
        )
    }

    const saveChanges = () => {
        const templates = [...project.email_templates]
        const templateIndex = project.email_templates
            .map((temp) => temp.id)
            .indexOf(editingTemplate._id)

        templates[templateIndex] = {
            category,
            subject: email_subject,
            content: JSON.stringify(
                convertToRaw(editorState.getCurrentContent())
            ),
            last_modify: new Date(),
        }

        updateEmailTemplate({
            variables: {
                id: project.id,
                input: {
                    email_templates: templates,
                },
            },
        })
    }

    const saveAsNew = () => {
        const templates = [...project.email_templates]

        templates.push({
            category,
            subject: email_subject,
            content: JSON.stringify(
                convertToRaw(editorState.getCurrentContent())
            ),
            last_modify: new Date(),
        })

        updateEmailTemplate({
            variables: {
                id: project.id,
                input: {
                    email_templates: templates,
                },
            },
        })
    }

    return (
        <div className="email-template">
            <Dropdown
                placeholder="Choose a category"
                fluid
                search
                selection
                options={EMAIL_TEMPLATE_CATEGORY_OPTIONS}
                onChange={(e, { value }) => {
                    setCategory(value)
                    getEditState(value)
                }}
            />
            <br />
            <Input
                fluid
                placeholder="Subject"
                value={email_subject}
                onChange={(e, { value }) => setEmailSubject(value)}
            />
            <br />
            <Editor
                editorState={editorState}
                onEditorStateChange={(editorState) => {
                    setEditorState(editorState)
                    setEmailContent(
                        getHTMLFromEditorState(
                            JSON.stringify(
                                convertToRaw(editorState.getCurrentContent())
                            )
                        )
                    )
                }}
            />
            <br />
            <p>Here is how your email would look: </p>
            <div
                dangerouslySetInnerHTML={{ __html: getEmailHTML(category) }}
            ></div>
            <div style={{ marginLeft: '20%' }}>
                <Button primary onClick={saveChanges} disabled={!isEdit}>
                    Save changes
                </Button>
                <Button
                    variant="outline-secondary"
                    disabled={!editorState}
                    onClick={saveAsNew}
                >
                    Save as new
                </Button>
                <Button
                    variant="outline-secondary"
                    onClick={() => setShowEdit(false)}
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}

export default EditTemplate
