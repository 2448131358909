import React, { Fragment, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Button, Popup } from 'semantic-ui-react'

const ProjectDeleteButton = ({ onDelete, deletable }) => {
    const [showModal, setShowModal] = useState(false)

    return (
        <Fragment>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered
                style={{ zIndex: 9998 }}
            >
                <Modal.Body>
                    Are you sure you want to delete this project ? This action
                    is irreversible.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        primary
                        onClick={() => {
                            setShowModal(false)
                            onDelete()
                        }}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            {deletable ? (
                <Button negative onClick={() => setShowModal(true)}>
                    Delete the project
                </Button>
            ) : (
                <Popup
                    trigger={
                        <Button
                            negative
                            style={{ cursor: 'default', opacity: 0.45 }}
                        >
                            Delete the project
                        </Button>
                    }
                    style={{ zIndex: 9999 }}
                    content="This project is not deletable"
                />
            )}
        </Fragment>
    )
}

export default ProjectDeleteButton
