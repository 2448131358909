import React, { Fragment, useContext } from 'react'
import { Dropdown } from 'semantic-ui-react'
import {
    ADVISORS_SORT_OPTIONS,
    MANAGE_ADVISORS_SORT_OPTIONS,
    MANAGE_ADVISORS_FILTER_OPTIONS,
} from '../../utils/options'
import { AdvisorContext } from '../../Context'

const AdvisorListHeading = ({ type }) => {
    const {
        sortBy,
        setSortBy,
        filterBy,
        setFilterBy,
        totalCount,
        currentPage,
        perPage,
    } = useContext(AdvisorContext)

    const onSort = (type) => {
        switch (type) {
            case 'Highest response rate':
                setSortBy({ responseRate: 'desc' })
                break
            case 'Highest fee':
                setSortBy({ hourlyRate: 'desc' })
                break
            case 'Lowest fee':
                setSortBy({ hourlyRate: 'asc' })
                break
            case 'Most engagements':
                setSortBy({ numCompleted: 'desc' })
                break
            case 'Highest rating':
                setSortBy({ avgRating: 'desc' })
                break
            case 'Newest join date':
                setSortBy({ created_at: 'desc' })
                break
            default:
                break
        }
    }

    const getSortBy = (type) => {
        if (type['responseRate']) return 'Highest response rate'
        if (type['hourlyRate'] === 'desc') {
            return 'Highest fee'
        } else if (type['hourlyRate'] === 'asc') {
            return 'Lowest fee'
        }
        if (type['numCompleted']) return 'Most engagements'
        if (type['avgRating']) return 'Highest rating'
        if (type['created_at']) return 'Newest join date'
        return ''
    }

    const renderFilterBy = () => (
        <div className="sort-by" style={{ marginRight: '10px' }}>
            <Dropdown
                placeholder="Filter by status"
                multiple
                selection
                value={filterBy}
                options={MANAGE_ADVISORS_FILTER_OPTIONS}
                onChange={(e, { value }) => setFilterBy(value)}
                button
                className="icon mini fix-arrow"
            />
        </div>
    )

    const renderSortedByRelevance = () => (
        <div
            style={{
                float: 'right',
                margin: '5px 10px 0 0',
                fontSize: '12px',
                color: 'black',
            }}
        >
            <span>Sorted by relevance</span>
        </div>
    )

    const OPTIONS =
        type === 'MANAGE_ADVISOR'
            ? MANAGE_ADVISORS_SORT_OPTIONS
            : ADVISORS_SORT_OPTIONS

    return (
        <Fragment>
            <div style={{ float: 'left' }}>
                {`Page ${currentPage} of ${Math.ceil(
                    totalCount / perPage
                )} | ${totalCount} advisor(s)`}
            </div>
            {type !== 'MANAGE_ADVISOR' && (
                <div style={{ float: 'right' }}>
                    <Dropdown
                        placeholder="Sort by"
                        selection
                        options={OPTIONS}
                        onChange={(e, { value }) => onSort(value)}
                        value={getSortBy(sortBy)}
                        button
                        className="icon mini sort-by fix-arrow"
                    />
                </div>
            )}
            {type === 'SELECT_ADVISOR' && renderSortedByRelevance()}
            {type === 'MANAGE_ADVISOR' && renderFilterBy()}
        </Fragment>
    )
}

export default AdvisorListHeading
