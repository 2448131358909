import React from 'react'
import { Modal } from 'react-bootstrap'

const RescheduleContent = () => {
    return (
        <Modal.Body>
            <p>
                This meeting will be canceled and a rescheduling link will be
                sent to the expert.{' '}
                <strong style={{ textDecoration: 'underline' }}>
                    Make sure to update your availability as soon as possible to
                    reflect any changes to the available time slots
                </strong>
            </p>
        </Modal.Body>
    )
}

export default RescheduleContent
