import React from 'react'
import { Grid } from 'semantic-ui-react'
import { DECISIONS } from '../../../../constants/review'

const HistoryItem = ({ label, auto, category, decision, undoExtraction }) => {
    const getDecisionLabel = () => {
        switch (decision) {
            case 'rejected':
                return 'Invalid'
            case 'ignored':
                return 'Ignored'
            default:
                return DECISIONS.find((c) => c.key === category).label
        }
    }
    return (
        <Grid.Row
            verticalAlign="middle"
            className="history-item"
            onClick={undoExtraction}
        >
            <Grid.Row className="history-item-overlay">
                Click to add back to queue
            </Grid.Row>
            <Grid.Column floated="left" className="text-ellipsis">
                <Grid.Row className="history-item-left text-ellipsis">
                    <Grid.Column
                        className="text-nowrap history-item-chip bg-black-light"
                        style={{ display: auto ? 'block' : 'none' }}
                    >
                        Auto
                    </Grid.Column>
                    <Grid.Column className="text-ellipsis">{label}</Grid.Column>
                </Grid.Row>
            </Grid.Column>
            <Grid.Column
                floated="right"
                className={`text-nowrap history-item-chip bg-${decision}-light`}
            >
                {getDecisionLabel()}
            </Grid.Column>
        </Grid.Row>
    )
}

export default HistoryItem
