import React, { useState } from 'react'
import moment from 'moment'
import {
    Form,
    Header,
    Table,
    Checkbox,
    Popup,
    Icon,
    Label,
    Divider,
} from 'semantic-ui-react'

const getMeetingTime = (selectedParticipants, participants, meetingAt) => {
    const expertLogs = participants.filter((p) =>
        selectedParticipants.includes(p.name)
    )
    let totalMeetingTime = 0
    let mergedIntervals = []
    if (expertLogs.length > 0) {
        expertLogs.sort((a, b) => {
            return moment(a.joinedAt).diff(moment(b.joinedAt))
        })

        expertLogs.forEach((participant) => {
            let joinMoment = Math.max(
                moment(participant.joinedAt),
                moment(meetingAt)
            )
            let leaveMoment = moment(participant.leftAt)

            if (
                mergedIntervals.length === 0 ||
                joinMoment > mergedIntervals[mergedIntervals.length - 1].end
            ) {
                mergedIntervals.push({
                    start: joinMoment,
                    end: leaveMoment,
                })
            } else {
                mergedIntervals[mergedIntervals.length - 1].end = moment.max(
                    mergedIntervals[mergedIntervals.length - 1].end,
                    leaveMoment
                )
            }
        })

        const maxNonExpertLeaveMoment = participants
            .filter((p) => !selectedParticipants.includes(p.name))
            .sort((a, b) => moment(b.leftAt).diff(moment(a.leftAt)))

        if (maxNonExpertLeaveMoment.length > 0) {
            const latestNonExpertLeave = moment(
                maxNonExpertLeaveMoment[0].leftAt
            )

            mergedIntervals[mergedIntervals.length - 1].end = moment.min(
                mergedIntervals[mergedIntervals.length - 1].end,
                latestNonExpertLeave
            )
        }
        mergedIntervals.forEach((interval) => {
            let intervalDuration = interval.end.diff(interval.start, 'minutes')
            if (intervalDuration > 0) {
                totalMeetingTime += intervalDuration
            }
        })
    }
    return {
        callMinutes: totalMeetingTime,
        effectiveMeetingTimes: mergedIntervals,
    }
}

const MeetingLog = ({
    job,
    selectedParticipants,
    updateSelectedParticipants,
}) => (
    <div>
        <Header as="h6">Meeting Log</Header>
        <div>
            Select the Zoom ID(s) of the expert, {job.user.firstName}{' '}
            {job.user.lastName}
        </div>
        <Table basic="very" compact>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Zoom Id</Table.HeaderCell>
                    <Table.HeaderCell>Joined</Table.HeaderCell>
                    <Table.HeaderCell>Left</Table.HeaderCell>
                    <Table.HeaderCell>Minutes Since Start</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {job.zoomMeeting?.meetingReport?.participants.map(
                    (participant, index) => (
                        <Table.Row key={index}>
                            <Table.Cell collapsing>
                                <Checkbox
                                    checked={selectedParticipants.includes(
                                        participant.name
                                    )}
                                    onChange={(e) =>
                                        updateSelectedParticipants(
                                            participant.name
                                        )
                                    }
                                />
                            </Table.Cell>
                            <Table.Cell>
                                {participant.name}
                                {participant.email
                                    ? `(${participant.email})`
                                    : ''}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(participant.joinedAt).format('LT')}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(participant.leftAt).format('LT')}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(participant.leftAt).diff(
                                    moment.max(
                                        moment(job.meetingAt),
                                        moment(participant.joinedAt)
                                    ),
                                    'minutes'
                                )}
                            </Table.Cell>
                        </Table.Row>
                    )
                )}
            </Table.Body>
        </Table>
    </div>
)

const ZoomInformation = ({ job, payment, updatePayment }) => {
    const meetingParticipants =
        job.zoomMeeting?.meetingReport?.participants || []

    const [callMinutes, setCallMinutes] = useState(payment.callMinutes)
    const [hourlyRate, setHourlyRate] = useState(payment.hourlyRate)
    const [selectedParticipants, setSelectedParticipants] = useState([])
    const [effectiveMeetingTimes, setEffectiveMeetingTimes] = useState([])

    const updateMeetingData = (newCallMinutes, newHourlyRate) => {
        setCallMinutes(newCallMinutes)
        setHourlyRate(newHourlyRate)

        if (job.project.type === 'Interview') {
            const amount = Number(
                parseFloat((newCallMinutes * newHourlyRate) / 60).toFixed(2)
            )
            updatePayment({
                amount,
                description: `$${amount}; ${newCallMinutes} minutes at $${newHourlyRate} per hour`,
                callMinutes: newCallMinutes,
                hourlyRate: newHourlyRate,
            })
        }
    }

    const updateSelectedParticipants = (participant) => {
        let newSelectedParticipants = []
        if (selectedParticipants.includes(participant)) {
            newSelectedParticipants = selectedParticipants.filter(
                (p) => p !== participant
            )
        } else {
            newSelectedParticipants = [...selectedParticipants, participant]
        }
        setSelectedParticipants(newSelectedParticipants)
        const {
            effectiveMeetingTimes: newEffectiveMeetingTimes,
            callMinutes: newCallMinutes,
        } = getMeetingTime(
            newSelectedParticipants,
            meetingParticipants,
            job.meetingAt
        )
        setEffectiveMeetingTimes(newEffectiveMeetingTimes)
        updateMeetingData(newCallMinutes, payment.hourlyRate)
    }

    return (
        <div>
            {job.zoomMeeting?.meetingReport?.status === 'Complete' && (
                <MeetingLog
                    job={job}
                    selectedParticipants={selectedParticipants}
                    updateSelectedParticipants={updateSelectedParticipants}
                />
            )}
            <div>
                {'Effective meeting times are: '}
                <Popup
                    trigger={
                        <Icon
                            name="info"
                            inverted
                            circular
                            size="tiny"
                            color="grey"
                        />
                    }
                    style={{
                        zIndex: 9999,
                    }}
                    content="Effective meeting times are calculated from the later of the expert's
          dial-in time and the meeting start time to
          the earlier of the expert's leave time and
          the leave time of all other parties, while
          ignoring any overlapping periods"
                />
                <br />
                {job.zoomMeeting.meetingReport.participants ? (
                    <ul>
                        {effectiveMeetingTimes.map((time) => (
                            <li key={time.start}>
                                {moment(time.start).format('LT')}-{' '}
                                {moment(time.end).format('LT')} (
                                {moment(time.end).diff(
                                    moment(time.start),
                                    'minutes'
                                )}
                                {'  '}
                                min. )
                            </li>
                        ))}
                    </ul>
                ) : (
                    ' - No meeting log was found'
                )}
            </div>
            <Header as="h6">
                Payment amount{' '}
                <span>
                    <Popup
                        trigger={
                            <Icon
                                name="info"
                                inverted
                                circular
                                size="tiny"
                                color="grey"
                            />
                        }
                        style={{
                            zIndex: 9999,
                        }}
                        content="Enter the call length and hourly rate to compute the honorarium amount, or simply enter the honorarium amount directly."
                    />
                </span>
            </Header>

            <Form.Group>
                <br />
                <div style={{ marginLeft: 10 }}>Call Length</div>
                <Form.Input
                    labelPosition="right"
                    type="number"
                    min="0"
                    placeholder="Minutes"
                    value={callMinutes}
                    onChange={(e, { value }) =>
                        updateMeetingData(parseInt(value), payment.hourlyRate)
                    }
                >
                    <input />
                    <Label basic>Minutes</Label>
                </Form.Input>
            </Form.Group>

            <Form.Group>
                <div style={{ marginLeft: 10 }}>Hourly Rate</div>
                <Form.Input
                    labelPosition="left"
                    type="number"
                    min="0"
                    placeholder="Rate"
                    value={hourlyRate}
                    onChange={(e, { value }) =>
                        updateMeetingData(payment.callMinutes, parseInt(value))
                    }
                >
                    <Label basic>$</Label>
                    <input />
                </Form.Input>
            </Form.Group>
            <Divider />
        </div>
    )
}

export default ZoomInformation
