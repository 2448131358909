import React from 'react'
import PropTypes from 'prop-types'
import { Label, Icon } from 'semantic-ui-react'

import './styles.css'

const propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    internal_tags: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const Tags = ({
    tags,
    internal_tags,
    removeInternalTag,
    removeable,
    project_tags,
}) => {
    return (
        <div>
            <ul className="tags-section">
                {tags.map((item, index) => (
                    <Label
                        key={index}
                        color={
                            project_tags
                                ? project_tags.includes(item)
                                    ? 'yellow'
                                    : null
                                : null
                        }
                        className="customize-tags"
                    >
                        {item}
                    </Label>
                ))}
                {internal_tags.map((item, index) => {
                    return (
                        <Label
                            key={index}
                            color={
                                project_tags
                                    ? project_tags.includes(item)
                                        ? 'yellow'
                                        : null
                                    : null
                            }
                            className="customize-tags"
                        >
                            {removeInternalTag && removeable ? (
                                <a
                                    href="# "
                                    onClick={(e) => {
                                        e.preventDefault()
                                        removeInternalTag(item)
                                    }}
                                >
                                    <Icon name="remove" />
                                </a>
                            ) : null}

                            {item}
                        </Label>
                    )
                })}
            </ul>
        </div>
    )
}

Tags.propTypes = propTypes

export default Tags
