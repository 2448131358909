import React, { Fragment, useState } from 'react'
import { Table } from 'semantic-ui-react'
import moment from 'moment'
import Pagination from '../../Pagination'

const ProjectTable = ({ projects, setSelectedProject, setShowPanel }) => {
    const PER_PAGE = 10

    const [currentPage, setCurrentPage] = useState(1)

    const renderConditions = (project) => {
        if (project.status === 'Draft') {
            return 'The project has been submitted by the client. Review and approve the project'
        } else if (moment().diff(moment(project.end_date), 'days') > 0) {
            return 'The project has passed its end date. Confirm with the client to close it out or extend the deadline'
        } else {
            return 'The project has hit its target N. Confirm with the client to close it out'
        }
    }

    if (projects.length === 0) return <div>No projects</div>

    const end = currentPage * PER_PAGE
    const start = end - PER_PAGE

    return (
        <Fragment>
            <Table celled size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Team name</Table.HeaderCell>
                        <Table.HeaderCell>Project title</Table.HeaderCell>
                        <Table.HeaderCell>Owner</Table.HeaderCell>
                        <Table.HeaderCell>Duration</Table.HeaderCell>
                        <Table.HeaderCell>Conditions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {projects.slice(start, end).map((project) => {
                        return (
                            <Table.Row key={project.id}>
                                <Table.Cell>{project.team.name}</Table.Cell>
                                <Table.Cell>
                                    <a
                                        href="# "
                                        style={{ color: 'green' }}
                                        onClick={() => {
                                            setSelectedProject(project)
                                            setShowPanel(true)
                                        }}
                                    >
                                        {project.title}
                                    </a>
                                </Table.Cell>
                                <Table.Cell>
                                    {`${project.owners[0].fullName} (${project.owners[0].email})`}
                                </Table.Cell>
                                <Table.Cell>{`${moment(
                                    project.start_date
                                ).format('l')} - ${moment(
                                    project.end_date
                                ).format('l')}`}</Table.Cell>
                                <Table.Cell>
                                    {renderConditions(project)}
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
            <Pagination
                defaultActivePage={currentPage}
                totalPages={Math.ceil(projects.length / PER_PAGE)}
                onPageChange={setCurrentPage}
            />
        </Fragment>
    )
}

export default ProjectTable
