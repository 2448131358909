import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Loader } from 'semantic-ui-react'
import Advisors from '../../Advisors'
import { matchPrefix } from '../../../utils/helpers'
import { AdvisorContext } from '../../../Context'
import { LOAD_PROJECT_JOBS } from '../../../graphql/project'

const ManageAdvisors = () => {
    const PER_PAGE = 5

    const { id } = useParams()

    const [manageAdvisors, setManageAdvisors] = useState([])
    const [filters, setFilters] = useState({ hourlyRate: [0, 9999] })
    const [filterBy, setFilterBy] = useState([])
    const [sortBy, setSortBy] = useState('')
    const [currentPage, setCurrentPage] = useState(1)

    const { data, loading } = useQuery(LOAD_PROJECT_JOBS, {
        variables: { id },
    })

    useEffect(() => {
        if (data) {
            const jobs = [...data.project.jobs]
            jobs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            let tempFilterBy = filterBy.slice()
            if (filterBy.includes('Pending')) {
                tempFilterBy.push('Invited')
            }
            tempFilterBy = [...new Set(tempFilterBy)]
            const filteredJobs = jobs.filter((job) => {
                const inviteStatus = job.invite_status
                const responseStatus = job.response_status
                const overallStatus = job.overall_status
                const payStatus =
                    job.overall_status === 'Paid' ? 'Paid' : 'Not Paid'

                const filter_tags = [
                    inviteStatus,
                    responseStatus,
                    overallStatus === 'Completed'
                        ? overallStatus
                        : 'Incomplete',
                    payStatus,
                ]

                return (
                    filter_tags.filter((tag) => tempFilterBy.indexOf(tag) > -1)
                        .length === tempFilterBy.length
                )
            })
            setManageAdvisors(
                filteredJobs.map((job) => ({ ...job.user, expert: job.expert }))
            )
        }
    }, [data, filters, filterBy])

    const filterManageAdvisors = () => {
        const filteredJobs = data.project.jobs.filter((job) => {
            if (
                filters.query &&
                !matchPrefix(filters.query, job.expert.fullName)
            ) {
                return false
            }
            return true
        })
        setManageAdvisors(
            filteredJobs.map((job) => ({ ...job.user, expert: job.expert }))
        )
        window.scrollTo(0, 0)
    }

    let end = currentPage * PER_PAGE
    let start = end - PER_PAGE

    return (
        <AdvisorContext.Provider
            value={{
                advisors: manageAdvisors.slice(start, end),
                jobs: data ? data.project.jobs : [],
                filters,
                setFilters,
                sortBy,
                setSortBy,
                filterBy,
                setFilterBy,
                loadAdvisor: filterManageAdvisors,
                currentPage,
                setCurrentPage,
                totalCount: manageAdvisors.length,
                perPage: PER_PAGE,
            }}
        >
            <Loader active={loading} />
            <Advisors />
        </AdvisorContext.Provider>
    )
}

export default ManageAdvisors
