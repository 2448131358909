import React, { useContext } from 'react'
import { Label, Icon, Button } from 'semantic-ui-react'
import { AdvisorContext } from '../../../Context'

const FilterTags = () => {
    const { filters, setFilters, loadAdvisor } = useContext(AdvisorContext)

    const removeFilter = (key, value) => {
        let newFilters = filters
        if (key === 'query' || key === 'isBlocked') {
            delete newFilters[key]
        } else if (key === 'hourlyRate') {
            newFilters[key] = [0, 9999]
        } else {
            let findIndex = newFilters[key].indexOf(value)
            if (findIndex !== -1) {
                newFilters[key].splice(findIndex, 1)
            }
            if (newFilters[key].length === 0) {
                delete newFilters[key]
            }
        }
        setFilters(newFilters)
        loadAdvisor()
    }

    const clearAllFilters = () => {
        setFilters({ hourlyRate: [0, 9999] })
        loadAdvisor()
    }

    const parseFilterName = (key) => {
        switch (key) {
            case 'query':
                return 'Search'
            case 'organizationType':
                return 'Institution'
            case 'functions':
                return 'Functions'
            case 'country':
                return 'Country'
            case 'technology_tags':
                return 'Technology'
            case 'medical_specialties':
                return 'Medical Specialties'
            case 'status':
                return 'Status'
            case 'isBlocked':
                return 'Status'
            case 'hourlyRate':
                return 'Fee'
            default:
                return ''
        }
    }

    const renderLabel = (key, value, index) => {
        return (
            <Label key={index} className="customize-tags" basic>
                {parseFilterName(key)}: {value}
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => removeFilter(key, value)}
                >
                    <Icon name="remove" />
                </span>
            </Label>
        )
    }

    const hasFilter = Object.keys(filters).length > 1

    return (
        <div className={`filter-tags ${hasFilter ? 'box' : ''}`}>
            <ul className="tag-section">
                {Object.keys(filters).map((key, index) => {
                    if (
                        key === 'hourlyRate' &&
                        filters[key][0] === 0 &&
                        filters[key][1] === 9999
                    ) {
                        return null
                    } else if (key === 'hourlyRate') {
                        return renderLabel(
                            key,
                            `$${filters[key][0]} - $${filters[key][1]}`,
                            index
                        )
                    } else if (key === 'query') {
                        return renderLabel(key, filters[key], index)
                    } else if (key === 'isBlocked') {
                        if (filters[key])
                            return renderLabel(key, 'Blocked', 'Blocked')
                        return null
                    }
                    return filters[key].map((value, index) => {
                        return renderLabel(key, value, index)
                    })
                })}
                {hasFilter && (
                    <Button
                        size="small"
                        className="customize-tags"
                        onClick={clearAllFilters}
                    >
                        Clear all
                    </Button>
                )}
            </ul>
        </div>
    )
}

export default FilterTags
