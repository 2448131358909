/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react'
import { Table } from 'semantic-ui-react'
import moment from 'moment'
import Pagination from '../../Pagination'

const JobTable = ({ jobs, setSelectedJob, setShowPanel }) => {
    const PER_PAGE = 10

    const [currentPage, setCurrentPage] = useState(1)

    const renderConditions = (job) => {
        switch (job.overall_status) {
            case 'Pending Scheduling':
                const passedTime = moment().diff(
                    moment(job.invitedReminderAt),
                    'days'
                )
                const message = isNaN(passedTime) ? '' : `in ${passedTime} days`
                return `The expert hasn't responded to this invitation ${message}. Send a quick reminder to them`
            case 'Client Reschedule':
                return 'The client has asked to reschedule this job. Notify the expert by sending a followup email'
            case 'No Show':
                return 'This meeting was reported as a no show'
            case 'Cancelled':
                return 'The meeting has been cancelled'
            default:
                if (job.project.type === 'Interview') {
                    if (moment().diff(moment(job.meetingAt), 'minutes') >= 60) {
                        return 'The scheduled time has passed. Mark the job complete and pay the expert'
                    } else if (
                        moment().diff(moment(job.meetingAt), 'minutes') >= 0
                    ) {
                        return 'The scheduled time has passed. The meeting may be taking place now.'
                    }
                }
                return 'Survey completed. Confirm and pay the expert'
        }
    }

    if (jobs.length === 0) return <div>No jobs</div>

    const end = currentPage * PER_PAGE
    const start = end - PER_PAGE

    return (
        <Fragment>
            <Table celled size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Team name</Table.HeaderCell>
                        <Table.HeaderCell>Project title</Table.HeaderCell>
                        <Table.HeaderCell>Expert name</Table.HeaderCell>
                        <Table.HeaderCell>Last updated</Table.HeaderCell>
                        <Table.HeaderCell>Meeting date/time</Table.HeaderCell>
                        <Table.HeaderCell>Conditions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {jobs.slice(start, end).map((job) => {
                        return (
                            <Table.Row key={job.id}>
                                <Table.Cell>{job.project.team.name}</Table.Cell>
                                <Table.Cell>
                                    <a
                                        href="# "
                                        style={{ color: 'green' }}
                                        onClick={() => {
                                            setSelectedJob(job)
                                            setShowPanel(true)
                                        }}
                                    >
                                        {job.project.title}
                                    </a>
                                </Table.Cell>
                                <Table.Cell>
                                    {job.user.fullName || job.user.email}
                                </Table.Cell>
                                <Table.Cell>
                                    {job.updatedAt
                                        ? moment(job.updatedAt).format(
                                              'MM/DD/YY hh:mm a'
                                          )
                                        : 'null'}
                                </Table.Cell>
                                <Table.Cell>
                                    {job.meetingAt
                                        ? moment(job.meetingAt).format(
                                              'MM/DD/YY hh:mm a'
                                          )
                                        : job.project.type === 'Survey'
                                        ? 'Survey, Not applicable'
                                        : 'no meeting date'}
                                </Table.Cell>
                                <Table.Cell>{renderConditions(job)}</Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
            <Pagination
                defaultActivePage={currentPage}
                totalPages={Math.ceil(jobs.length / PER_PAGE)}
                onPageChange={setCurrentPage}
            />
        </Fragment>
    )
}

export default JobTable
