import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Form } from 'semantic-ui-react'
import { SWITCH_TO_USER } from '../../../graphql/admin'

const Support = () => {
    const [email, setEmail] = useState('')
    const [switchToUser, { loading }] = useMutation(SWITCH_TO_USER, {
        onCompleted: () => {
            const confirm = window.confirm(
                `Note: You are now switched to ${email} until you logout from any Dexter applications.`
            )
            if (confirm) {
                window.location.reload()
            }
        },
    })
    return (
        <Form onSubmit={() => switchToUser({ variables: { email } })}>
            <Form.Group>
                <Form.Input
                    value={email}
                    placeholder="Enter the user's email"
                    onChange={(e, { value }) => setEmail(value)}
                />
                <Form.Button
                    primary
                    loading={loading}
                    disabled={!email.trim() || loading}
                    content="Switch to User"
                />
            </Form.Group>
        </Form>
    )
}

export default Support
