import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

const SingleProjectMenu = ({ match, history }) => {
    const [activeItem, setActiveItem] = useState('details')

    useEffect(() => {
        setActiveItem(match.url.split(`/`)[3] || 'details')
    }, [match.url])

    const onNavigate = (e, { name }) => {
        setActiveItem(name)
        history.replace(`/projects/${match.params.id}/${name}`)
    }

    return (
        <Menu tabular style={{ clear: 'both' }}>
            <Menu.Item
                name="details"
                active={activeItem === 'details'}
                onClick={onNavigate}
            >
                Project details
            </Menu.Item>
            <Menu.Item
                name="email-template"
                active={activeItem === 'email-template'}
                onClick={onNavigate}
            >
                Email templates
            </Menu.Item>
            <Menu.Item
                name="advisors"
                active={activeItem === 'advisors'}
                onClick={onNavigate}
            >
                Find advisors
            </Menu.Item>
            <Menu.Item
                name="manage-advisors"
                active={activeItem === 'manage-advisors'}
                onClick={onNavigate}
            >
                Manage advisors
            </Menu.Item>
        </Menu>
    )
}

export default withRouter(SingleProjectMenu)
