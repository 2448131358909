import React, { useContext, useEffect, useMemo } from 'react'
import { Grid, List } from 'semantic-ui-react'
import { ExtractionReviewContext } from '../../Context'
import Mark from 'mark.js'
import CustomLink from '../CustomLink'

const LinkedInInfo = ({ sidebarOpen, width, extractions }) => {
    const { datawork } = useContext(ExtractionReviewContext)

    const profileInfo = document.querySelector('.profile-info')
    const markInstance = useMemo(() => new Mark(profileInfo), [profileInfo])

    useEffect(() => {
        if (extractions && sidebarOpen) {
            markInstance.unmark({
                done: () => {
                    extractions.forEach((extraction) => {
                        markInstance.mark(
                            extraction.sources.map((s) => s.text),
                            {
                                accuracy: {
                                    value: 'exactly',
                                    limiters:
                                        ':;.,-–—‒_(){}[]!\'"+=“■•#‘´`’‘'.split(
                                            ''
                                        ),
                                },
                                separateWordSearch: false,
                                caseSensitive: true,
                                ignorePunctuation:
                                    ':;.,-–—‒_(){}[]!\'"+=“■•#‘´`’‘'.split(''),
                                ignoreJoiners: true,
                                done: () => {
                                    if (!profileInfo) return
                                    const firstMark =
                                        profileInfo.querySelector('mark')
                                    if (firstMark)
                                        firstMark.scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'center',
                                        })
                                },
                            }
                        )
                    })
                },
            })
        }
    }, [extractions, markInstance, profileInfo, sidebarOpen])

    return (
        <Grid.Column
            className={`sidebar ${sidebarOpen ? '' : 'offscreen-right'}`}
            style={{
                maxWidth: width,
                display: sidebarOpen ? 'flex' : 'none',
            }}
        >
            Expert LinkedIn Profile
            <Grid.Column className="profile-info" id="search-node">
                <Grid.Row>
                    <CustomLink
                        onClick={() => window.open(datawork.linkedinUrl)}
                    >
                        {datawork.linkedinUrl}
                    </CustomLink>
                </Grid.Row>
                <Grid.Row>{datawork.data.title}</Grid.Row>
                <Grid.Row
                    style={{
                        display:
                            datawork.data.headline.length > 0
                                ? 'inline'
                                : 'none',
                    }}
                >
                    {datawork.data.headline}
                </Grid.Row>
                <Grid.Row
                    style={{
                        display:
                            datawork.data.summary.length > 0
                                ? 'inline'
                                : 'none',
                    }}
                >
                    {datawork.data.summary.join(' ')}
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div>Experience</div>
                        {datawork.data.experience.experienceTotal > 0 ? (
                            <List bulleted>
                                {datawork.data.experience.experienceGroup.reduce(
                                    (acc, curr, gindex) => {
                                        const positions = curr.positions.map(
                                            (p, pindex) => (
                                                <List.Item
                                                    key={`eg-${gindex}-${pindex}`}
                                                >
                                                    {`${p.title} at ${
                                                        curr.groupCompany.at(0)
                                                            .name
                                                    } [${p.startDate} - ${
                                                        p.currentPosition
                                                            ? 'Present'
                                                            : p.endDate
                                                    }]`}
                                                    {p.description.length >
                                                    0 ? (
                                                        <List.List>
                                                            <List.Item>
                                                                {p.description.join(
                                                                    '\n'
                                                                )}
                                                            </List.Item>
                                                        </List.List>
                                                    ) : null}
                                                </List.Item>
                                            )
                                        )
                                        acc.push(...positions)
                                        return acc
                                    },
                                    []
                                )}
                                {datawork.data.experience.experienceList.map(
                                    (exp, index) => (
                                        <List.Item key={`e-${index}`}>
                                            {`${exp.title} at ${
                                                exp.company.name
                                            } [${exp.startDate} - ${
                                                exp.currentPosition
                                                    ? 'Present'
                                                    : exp.endDate
                                            }]`}
                                            {exp.description.length > 0 ? (
                                                <List.List>
                                                    <List.Item>
                                                        {exp.description.join(
                                                            '\n'
                                                        )}
                                                    </List.Item>
                                                </List.List>
                                            ) : null}
                                        </List.Item>
                                    )
                                )}
                            </List>
                        ) : (
                            <div>None</div>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div>Education</div>
                        {datawork.data.education.length > 0 ? (
                            <List bulleted>
                                {datawork.data.education.map((e, index) => (
                                    <List.Item key={index}>
                                        {`${e.degreeInfo.join(', ')} at ${
                                            e.school
                                        } [${e.startDate} - ${e.endDate}]`}
                                    </List.Item>
                                ))}
                            </List>
                        ) : (
                            <div>None</div>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div>Patents</div>
                        {datawork.data.patents.length > 0 ? (
                            <List bulleted>
                                {datawork.data.patents.map((p, index) => (
                                    <List.Item key={index}>
                                        {`${p.title} [${p.date}]`}
                                    </List.Item>
                                ))}
                            </List>
                        ) : (
                            <div>None</div>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid.Column>
        </Grid.Column>
    )
}

export default LinkedInInfo
