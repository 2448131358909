import React, { useState, useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Grid, Dropdown, Modal, Button, Input } from 'semantic-ui-react'
import { ExtractionReviewContext } from '../../../../Context'
import { UPDATE_DATAWORK } from '../../../../graphql/datawork'

const REASON_OPTIONS = [
    {
        key: 'incorrect-linkedin-url',
        value: 'incorrect-linkedin-url',
        text: 'Incorrect LinkedIn URL',
    },
    { key: 'other', value: 'other', text: 'Other' },
]

const RescrapeModal = () => {
    const { datawork, expertListData } = useContext(ExtractionReviewContext)

    const pageHistory = useHistory()

    const [modalOpen, setModalOpen] = useState(false)
    const [selectedReason, setSelectedReason] = useState(null)
    const [urlInput, setUrlInput] = useState('')

    const [updateDatawork, { loading }] = useMutation(UPDATE_DATAWORK, {
        onCompleted: () => {
            closeModal()
            if (expertListData.nextIndex) {
                pageHistory.push(
                    `/admin/experts/${
                        expertListData.options.at(expertListData.nextIndex)
                            .value
                    }/review`
                )
            } else {
                pageHistory.push('/admin/experts')
            }
        },
    })

    const rescrapeExpert = useCallback(() => {
        if (selectedReason) {
            let status = 'new'
            let linkedinUrl = null
            if (
                selectedReason === 'incorrect-linkedin-url' &&
                urlInput !== ''
            ) {
                linkedinUrl = `https://www.linkedin.com/in/${urlInput}`
            } else if (
                selectedReason === 'incorrect-linkedin-url' &&
                urlInput === ''
            ) {
                status = 'invalid-url'
            } else if (selectedReason === 'other') {
                status = 'post-retry'
            }
            updateDatawork({
                variables: {
                    expertId: datawork.expert.id,
                    input: {
                        status,
                        linkedinUrl,
                    },
                },
            })
        }
    }, [updateDatawork, datawork.expert.id, selectedReason, urlInput])

    const closeModal = useCallback(() => {
        setSelectedReason(null)
        setUrlInput('')
        setModalOpen(false)
    }, [setSelectedReason, setUrlInput, setModalOpen])

    return (
        <Modal
            onClose={closeModal}
            onOpen={() => setModalOpen(true)}
            open={modalOpen}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            trigger={
                <div className="rescrape-modal-button">Rescrape expert</div>
            }
            className="rescrape-modal"
            style={{ top: `calc(50vh - ${150}px)` }}
        >
            <Modal.Header>
                Why do you want to rescrape this expert?
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Grid className="rescrape-modal-body">
                        <Grid.Row>
                            <div className="fullwidth">
                                <h6>
                                    Reason <span className="text-red">*</span>
                                </h6>
                                <Dropdown
                                    placeholder="Select reason for rescraping"
                                    fluid
                                    selection
                                    value={selectedReason}
                                    options={REASON_OPTIONS}
                                    onChange={(_, data) =>
                                        setSelectedReason(data.value)
                                    }
                                />
                            </div>
                        </Grid.Row>
                        <Grid.Row>
                            <div className="fullwidth">
                                <h6>Correct LinkedIn URL</h6>
                                <Input
                                    label="https://www.linkedin.com/in/"
                                    placeholder="LinkedIn URL here"
                                    fluid
                                    value={urlInput}
                                    onChange={(_, data) =>
                                        setUrlInput(data.value)
                                    }
                                />
                                <p style={{ marginTop: 10 }}>
                                    If you leave this blank, the expert will be
                                    notified to provide their correct LinkedIn
                                    URL
                                </p>
                            </div>
                        </Grid.Row>
                    </Grid>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={closeModal}>
                    Cancel
                </Button>
                <Button
                    primary
                    disabled={selectedReason === null}
                    loading={loading}
                    onClick={rescrapeExpert}
                >
                    Submit
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default RescrapeModal
