import React, { useState } from 'react'
import { Table, Button, Icon, Header, Grid } from 'semantic-ui-react'
import { useMutation } from '@apollo/client'
import { MERGE_ORGANIZATION } from '../../../../graphql/organizations'
import { FIELDS } from './fields'
import Alert from '../../../Alert'
import renderOrgField from './renderOrgField'

const ConfirmMerge = ({ orgToRemove, orgToKeep, onReset }) => {
    const [showAlert, setShowAlert] = useState(false)
    const [mergeOrgs] = useMutation(MERGE_ORGANIZATION, {
        update: (cache, { data: { mergeOrganization } }) => {
            cache.modify({
                fields: {
                    autocomplete: (previous, { toReference }) => {
                        return [...previous, toReference(mergeOrganization)]
                    },
                },
            })
        },
    })

    const handleClick = () => {
        setShowAlert(!showAlert)
        onReset()
    }

    return (
        <Grid>
            <Alert
                show={showAlert}
                header="Success"
                body={`Successfully merged ${orgToRemove.name} into ${orgToKeep.name}`}
                onClose={handleClick}
            />
            <Grid.Column width={16}>
                <Table striped stackable>
                    <Table.Header>
                        <Table.Row textAlign="center">
                            <Table.HeaderCell collapsing />
                            <Table.HeaderCell collapsing>
                                <Header as="h4" icon>
                                    <Icon name="check circle" color="green" />
                                    <Header.Subheader>
                                        Organization to Keep
                                    </Header.Subheader>
                                </Header>
                            </Table.HeaderCell>
                            <Table.HeaderCell collapsing>
                                <Header as="h4" icon>
                                    <Icon name="trash alternate" color="red" />
                                    <Header.Subheader>
                                        Organization to Remove
                                    </Header.Subheader>
                                </Header>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {FIELDS.map((field) => (
                            <Table.Row key={field} textAlign="center">
                                <Table.Cell>{field}</Table.Cell>
                                <Table.Cell>
                                    {renderOrgField(field, orgToKeep)}
                                </Table.Cell>
                                <Table.Cell>
                                    {renderOrgField(field, orgToRemove)}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Grid.Column>
            <Grid.Column width={16} textAlign="center">
                <Button
                    onClick={async () => {
                        await mergeOrgs({
                            variables: {
                                removeOrgId: orgToRemove.id,
                                remainOrgId: orgToKeep.id,
                            },
                        })
                        setShowAlert(true)
                    }}
                    color="black"
                >
                    Confirm
                </Button>
            </Grid.Column>
        </Grid>
    )
}

export default ConfirmMerge
