import React, { Fragment, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
    Form,
    Popup,
    Icon,
    Input,
    Button,
    Grid,
    Message,
} from 'semantic-ui-react'
import { LOAD_USER_BY_EMAIL_QUERY } from '../../../graphql/user'
import Alert from '../../Alert'
import OnboardingCheckbox from '../../Modals/OnboardingCheckbox'
import InvitePendingModal from '../../Modals/ProjectInvite/InvitePendingModal'

const OnboardingForm = ({ isSideForm }) => {
    const [pendingAdvisor, setPendingAdvisor] = useState('')
    const [completedAt, setCompletedAt] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')

    const [loadUsersByEmail] = useLazyQuery(LOAD_USER_BY_EMAIL_QUERY, {
        onCompleted: ({ userByEmail }) => {
            if (userByEmail) {
                setShowAlert(true)
                setAlertHeader('')
                setAlertBody('This advisor already existed in Dexter')
            } else {
                setShowModal(true)
            }
        },
    })

    const onPreview = () => {
        loadUsersByEmail({
            variables: { email: pendingAdvisor },
        })
    }

    const renderSideForm = () => (
        <div className="add-new-expert">
            <Form.Field>
                <label>
                    Experts not on Dexter yet? <br />
                    Send onboarding email
                    <Popup
                        position="left center"
                        style={{ left: '80px' }}
                        content="You can now add experts to project before they sign up for Dexter. Enter their email address and click Add to preview and send invites. Come back later to pay them once they've joined Dexter!"
                        trigger={
                            <Icon
                                name="info circle"
                                style={{ marginLeft: '5px' }}
                            />
                        }
                    />
                </label>

                <Input
                    fluid
                    placeholder="Enter email"
                    value={pendingAdvisor}
                    onChange={(e, { value }) => setPendingAdvisor(value)}
                />
                <Button fluid onClick={onPreview}>
                    Preview
                </Button>
            </Form.Field>
        </div>
    )

    const renderDefaultFrom = () => (
        <Grid.Column style={{ width: '97%', margin: 'auto' }}>
            <Message>
                <Message.Header>There is no advisor found</Message.Header>
                <p>
                    You can add a new advisor to this project by entering their
                    email. <br />
                    If you are looking for more leads, check out our HubSpot
                    contacts page{' '}
                    <a
                        href="https://app.hubspot.com/contacts/4433755/contacts/list/view/all/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                            color: 'rgb(22, 171, 57)',
                        }}
                    >
                        here
                    </a>
                    !
                </p>
            </Message>
            <Input
                fluid
                placeholder="Add new advisor email"
                action={{
                    content: 'Preview',
                    onClick: onPreview,
                }}
                value={pendingAdvisor}
                onChange={(e, { value }) => setPendingAdvisor(value)}
            />
        </Grid.Column>
    )

    return (
        <Fragment>
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <OnboardingCheckbox
                onShow={showModal}
                onHide={() => setShowModal(false)}
                onPreview={(isCompleted) => {
                    setShowModal(false)
                    if (isCompleted) setCompletedAt(new Date())
                    setShowPreviewModal(true)
                }}
            />
            <InvitePendingModal
                onShow={showPreviewModal}
                onHide={() => {
                    setPendingAdvisor('')
                    setShowPreviewModal(false)
                }}
                completedAt={completedAt}
                pendingAdvisor={pendingAdvisor}
            />
            {isSideForm ? renderSideForm() : renderDefaultFrom()}
        </Fragment>
    )
}

export default OnboardingForm
