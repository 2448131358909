import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Grid } from 'semantic-ui-react'
import SidePanel from './SidePanel'
import Main from './Main'
import LoadingSpinner from '../../../LoadingSpinner'
import { TeamManageContext } from '../../../../Context'
import { LOAD_SINGLE_TEAM_QUERY } from '../../../../graphql/team'

const TeamManage = () => {
    const { id } = useParams()

    const { loading, data } = useQuery(LOAD_SINGLE_TEAM_QUERY, {
        variables: { id },
    })

    if (loading) return <LoadingSpinner />

    return (
        <TeamManageContext.Provider
            value={{
                team: data.team,
            }}
        >
            <Grid>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <SidePanel team={data.team} />
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <Main />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </TeamManageContext.Provider>
    )
}

export default TeamManage
