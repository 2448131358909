import { gql } from '@apollo/client'

const EXPERTDATAWORK_MODEL = `
    id
    status
    expert {
        id
        fullName
        first_name
        last_name
        title
        organization {
            id
            name
        }
        education
        functions
        medical_specialties
        technology_tags
        internal_tags
        linkedin_profile
    }
    linkedinUrl
    tagExtractions {
        tag
        sources {
            text
            type
        }
        categoryProbabilities {
            category
            confidence
        }
    }
    data
`

export const EXPERTDATAWORK = gql`
    query ExpertDatawork($expertId: String!) {
        expertDatawork(expertId: $expertId) {
            ${EXPERTDATAWORK_MODEL}
        }
    }
`

export const UPDATE_DATAWORK = gql`
    mutation UpdateExpertDatawork(
        $expertId: String!
        $input: ExpertDataworkInput!
    ) {
        updateExpertDatawork(expertId: $expertId, input: $input) {
            id
            status
            linkedinUrl
        }
    }
`

export const STORE_TAGEXTRACTIONS = gql`
    mutation StoreTagExtractionDecisions(
        $expertId: String!
        $decisions: [TagDecisionInput]!
    ) {
        storeTagExtractionDecisions(
            expertId: $expertId
            decisions: $decisions
        ) {
            id
            expert {
                id
                fullName
            }
            status
        }
    }
`
