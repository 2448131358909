/**
 *   Customize Alert modal for showing all the error messages
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'

const propTypes = {
    header: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}

const Alert = ({ show, header, body, onClose }) => (
    <Modal show={show} onHide={onClose} centered style={{ zIndex: 99999 }}>
        <Modal.Header closeButton>
            <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>{body}</div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="success" className="tool-btn" onClick={onClose}>
                OK
            </Button>
        </Modal.Footer>
    </Modal>
)

Alert.propTypes = propTypes

export default Alert
