/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Grid, Icon, Card, Dropdown } from 'semantic-ui-react'
import { EXPERT_PROFILE_PROJECT_SORT_OPTIONS } from '../../utils/options'
import { LOAD_USER_JOBS_QUERY } from '../../graphql/job'
import PastCard from './PastCard'

const AdvisorProjects = ({ advisor }) => {
    const [showItem, setShowItem] = useState(1)
    const [sortBy, setSortBy] = useState('')

    const { data, loading } = useQuery(LOAD_USER_JOBS_QUERY, {
        variables: {
            id: advisor.id,
        },
        skip: !advisor.id,
    })

    if (loading) return <div>Loading past projects..</div>
    if (!data) return <div></div>

    const pastJobs = data.user.jobs.filter((job) => {
        const { overall_status, project } = job
        return (
            overall_status === 'Completed' ||
            overall_status === 'Declined' ||
            (project && project.status === 'Completed')
        )
    })

    if (sortBy === 'Newest first') {
        pastJobs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    } else if (sortBy === 'Highest rated') {
        pastJobs.sort(
            (a, b) => parseInt(b.expert_rating) - parseInt(a.expert_rating)
        )
    } else if (sortBy === 'Lowest rated') {
        pastJobs.sort(
            (a, b) => parseInt(a.expert_rating) - parseInt(b.expert_rating)
        )
    }

    const completeAmount = pastJobs.filter(
        (job) => job.overall_status === 'Completed'
    ).length
    const declinedAmount = pastJobs.filter(
        (job) => job.overall_status === 'Declined'
    ).length
    const cancelledAmount = pastJobs.filter(
        (job) => job.overall_status === 'Expired'
    ).length

    return (
        <Fragment>
            <Grid.Row columns={2} style={{ marginTop: '2%', paddingBottom: 0 }}>
                <Grid.Column style={{ paddingLeft: 0 }}>
                    Project History & feedback
                </Grid.Column>
                {pastJobs.length > 1 ? (
                    <Grid.Column floated="right">
                        <span
                            style={{ cursor: 'pointer', float: 'right' }}
                            onClick={() =>
                                setShowItem(
                                    showItem === pastJobs.length
                                        ? 1
                                        : pastJobs.length
                                )
                            }
                        >
                            {showItem === pastJobs.length ? 'Hide' : 'Show'}{' '}
                            Details{' '}
                            <Icon
                                name={
                                    showItem === pastJobs.length
                                        ? 'angle up'
                                        : 'angle down'
                                }
                            />
                        </span>
                    </Grid.Column>
                ) : null}
            </Grid.Row>
            <Grid.Row columns={4} style={{ paddingBottom: 0 }}>
                <Grid.Column width={4} style={{ paddingLeft: 0 }}>
                    {completeAmount}{' '}
                    <span style={{ color: 'blue' }}>Completed</span>
                </Grid.Column>
                <Grid.Column width={4} style={{ marginLeft: '3%' }}>
                    {declinedAmount}{' '}
                    <span style={{ color: 'green' }}>Declined</span>
                </Grid.Column>
                <Grid.Column width={4} style={{ marginLeft: '3%' }}>
                    {cancelledAmount}{' '}
                    <span style={{ color: 'red' }}>Cancelled</span>
                </Grid.Column>
                {showItem === pastJobs.length ? (
                    <Grid.Column width={4} style={{ marginLeft: '3%' }}>
                        <Dropdown
                            fluid
                            placeholder="Sort By"
                            options={EXPERT_PROFILE_PROJECT_SORT_OPTIONS}
                            onChange={(e, { value }) => setSortBy(value)}
                        />
                    </Grid.Column>
                ) : null}
            </Grid.Row>
            <Grid.Row>
                <Card.Group className="pending-card-group">
                    {pastJobs.slice(0, showItem).map((job, index) => {
                        return (
                            <PastCard
                                key={index}
                                advisor={advisor}
                                project={job.project || {}}
                                job={job}
                            />
                        )
                    })}
                </Card.Group>
            </Grid.Row>
        </Fragment>
    )
}

export default AdvisorProjects
