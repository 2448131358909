import React from 'react'
import { Grid, GridColumn } from 'semantic-ui-react'
import { round } from '../../../utils/helpers'
import Shortcut from '../Shortcut'

const DecisionButton = ({ decision, currExtraction, decide }) => {
    const { label, key, helper, shortcut } = decision

    const active = currExtraction && currExtraction.category === key

    const renderConfidencePercentage = () => {
        const nonCategoryDecisions = ['rejected', 'ignored']
        const metaCategoryDecisions = ['education', 'function']
        if (!currExtraction || nonCategoryDecisions.includes(key)) {
            return null
        } else if (metaCategoryDecisions.includes(key)) {
            return '0%'
        }
        return `${round(
            currExtraction.extraction.categoryProbabilities.find(
                (cp) => cp.category === key
            ).confidence * 100
        )}%`
    }

    return (
        <Grid.Column
            className={`cd-button ${active ? 'button-selected' : ''}`}
            onClick={() => decide(key)}
            style={{ cursor: 'pointer' }}
        >
            <Grid.Row>
                <GridColumn>
                    <div className={active ? '' : `text-${key}`}>{label}</div>
                    <div>{helper}</div>
                </GridColumn>
            </Grid.Row>
            <Grid.Row verticalAlign="middle">
                <Grid.Column floated="left" width={5}>
                    {renderConfidencePercentage()}
                </Grid.Column>
                <Grid.Column floated="right" width={5}>
                    <Shortcut
                        shortcut={shortcut}
                        active={active}
                        activeClass={`shortcut-${key}`}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid.Column>
    )
}

export default DecisionButton
