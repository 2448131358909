import React from 'react'
import { List } from 'semantic-ui-react'
import faqList from '../../constants/faq.json'

const FAQMenu = () => {
    return (
        <List bulleted>
            {faqList.map((elem, index) => {
                return (
                    <List.Item
                        key={index}
                        as="a"
                        content={elem.content}
                        href={`/faq#${elem.link}`}
                    />
                )
            })}
        </List>
    )
}

export default FAQMenu
