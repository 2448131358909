import React, { useContext } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Grid } from 'semantic-ui-react'
import { ExtractionReviewContext } from '../../../Context'
import DecisionButton from './DecisionButton'
import { DECISIONS } from '../../../constants/review'

const ReviewBody = () => {
    const { datawork, history, currExtraction, reviewEnabled, decide } =
        useContext(ExtractionReviewContext)

    useHotkeys('a', () => decide('title'), { enabled: reviewEnabled })
    useHotkeys('s', () => decide('organization'), {
        enabled: reviewEnabled,
    })
    useHotkeys('d', () => decide('medical_specialties'), {
        enabled: reviewEnabled,
    })
    useHotkeys('f', () => decide('technology_tags'), {
        enabled: reviewEnabled,
    })
    useHotkeys('z', () => decide('education'), {
        enabled: reviewEnabled,
    })
    useHotkeys('x', () => decide('function'), {
        enabled: reviewEnabled,
    })
    useHotkeys('r', () => decide('rejected'), {
        enabled: reviewEnabled,
    })
    useHotkeys('l', () => decide('ignored'), {
        enabled: reviewEnabled,
    })

    return (
        <Grid.Row className="body-wrapper">
            <Grid.Column className="review-body">
                <Grid.Row>
                    {history.length + 1}/{datawork.tagExtractions.length}
                </Grid.Row>
                <Grid.Row>
                    {currExtraction
                        ? currExtraction.extraction.tag
                        : 'Loading...'}
                </Grid.Row>
                <Grid.Column className="button-wrapper">
                    <Grid.Column className="decisions">
                        DECISIONS
                        <Grid.Row className="button-group">
                            {DECISIONS.map((decision, index) => (
                                <DecisionButton
                                    key={index}
                                    decision={decision}
                                    currExtraction={currExtraction}
                                    decide={decide}
                                />
                            ))}
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Column>
            </Grid.Column>
        </Grid.Row>
    )
}

export default ReviewBody
