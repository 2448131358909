import React from 'react'
import { Grid } from 'semantic-ui-react'
import TeamMenu from './TeamMenu'
import TeamRoutes from './TeamRoutes'

const Main = () => {
    return (
        <Grid style={{ marginTop: '1rem' }}>
            <Grid.Row>
                <TeamMenu />
                <TeamRoutes />
            </Grid.Row>
        </Grid>
    )
}

export default Main
