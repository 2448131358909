import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Container, Header, Form, Input, Button } from 'semantic-ui-react'
import { ADD_TEAM_CREDIT_MUTATION } from '../../graphql/transaction'

const AddCreditPanel = ({ team, onAdd, onCancel }) => {
    const [credit, setCredit] = useState(0)
    const [fee, setFee] = useState(0)
    const [description, setDescription] = useState(
        `Add ${credit} credit(s) to ${team.name} team`
    )

    const [addTeamCredit] = useMutation(ADD_TEAM_CREDIT_MUTATION, {
        onCompleted: () => {
            onAdd()
        },
    })

    const addCredit = () => {
        addTeamCredit({
            variables: {
                teamId: team.id,
                amount: parseFloat(fee),
                credits: parseFloat(credit),
                description,
            },
        })
    }

    return (
        <Container className="side-panel">
            <Header size="huge" textAlign="center">
                <Header.Content>{team.name}</Header.Content>
            </Header>
            <Form style={{ marginTop: '5%' }}>
                <Form.Field>
                    <label>How many credits to add?</label>
                    <Input
                        placeholder="Credit"
                        type="number"
                        value={credit}
                        onChange={(e, { value }) => setCredit(value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>How much fee was paid by the client?</label>
                    <Input
                        placeholder="$USD"
                        type="number"
                        value={fee}
                        onChange={(e, { value }) => setFee(value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Invoice description (Optional)</label>
                    <Input
                        placeholder="Enter description"
                        value={description}
                        onChange={(e, { value }) => setDescription(value)}
                    />
                </Form.Field>
                <div>
                    <Button onClick={addCredit}>Submit</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </div>
            </Form>
        </Container>
    )
}

export default AddCreditPanel
