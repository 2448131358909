import React, { useContext } from 'react'
import { Grid, Header } from 'semantic-ui-react'
import { ResponsivePie } from '@nivo/pie'
import { DashboardContext } from '../../../../Context'

const PieChart = ({ transactions, period }) => {
    const { getTeamNameById } = useContext(DashboardContext)

    const getGraphData = () => {
        const totalAmount = transactions.reduce((sum, t) => sum + t.amount, 0)
        const teamStats = transactions
            .filter((t) => t.amount !== 0)
            .reduce((count, { team, amount }) => {
                const teamName = getTeamNameById(team)
                count[teamName] = count[teamName] || {
                    id: teamName,
                    label: teamName,
                    value: 0,
                    percentage:
                        Math.round(((amount * 100) / totalAmount) * 10) / 10,
                }
                count[teamName].value += amount
                return count
            }, {})
        return Object.values(teamStats)
    }

    return (
        <ResponsivePie
            data={getGraphData()}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            colors={{ scheme: 'blues' }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsRadiusOffset={0.65}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [['darker', '3']],
            }}
            tooltip={({ label, value, percentage }) => {
                return (
                    <div>
                        <h6>{period}</h6>
                        <h6>{`${label}: $${value} (${percentage}%)`}</h6>
                    </div>
                )
            }}
        />
    )
}

const ClientOrders = ({
    transactions,
    lastTransactions,
    currentPeriod,
    previousPeriod,
}) => {
    const STATS_COLUMNS = [
        {
            title: 'Current Period',
            transactions,
            numOrders: [
                ...new Set(
                    transactions
                        .filter((t) => t.amount !== 0)
                        .map((t) => t.team)
                ),
            ].length,
            credits: transactions.reduce((sum, t) => sum + t.credits, 0),
            totalAmount: transactions.reduce((sum, t) => sum + t.amount, 0),
            period: currentPeriod,
        },
        {
            title: 'Previous Period',
            transactions: lastTransactions,
            numOrders: [
                ...new Set(
                    lastTransactions
                        .filter((t) => t.amount !== 0)
                        .map((t) => t.team)
                ),
            ].length,
            credits: lastTransactions.reduce((sum, t) => sum + t.credits, 0),
            totalAmount: lastTransactions.reduce((sum, t) => sum + t.amount, 0),
            period: previousPeriod,
        },
    ]
    return (
        <Grid style={{ margin: '1rem' }}>
            <Grid.Row style={{ marginLeft: '1rem' }}>
                <Header as="h6">Client Orders</Header>
            </Grid.Row>
            <Grid.Row centered columns={2}>
                {STATS_COLUMNS.map((col) => (
                    <Grid.Column key={col.title}>
                        <div style={{ height: '400px' }}>
                            {col.transactions.length === 0 ? (
                                <h5
                                    style={{
                                        textAlign: 'center',
                                        verticalAlign: 'middle',
                                        lineHeight: '400px',
                                    }}
                                >
                                    No data found during this period
                                </h5>
                            ) : (
                                <PieChart
                                    transactions={col.transactions}
                                    period={col.period}
                                />
                            )}
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <Header.Subheader>{`N=${col.numOrders}`}</Header.Subheader>
                            <Header.Subheader>{`Credits=${col.credits}`}</Header.Subheader>
                            <Header.Subheader>{`Total Amount=$${col.totalAmount}`}</Header.Subheader>
                            <Header.Subheader>{col.title}</Header.Subheader>
                            <Header.Subheader>{col.period}</Header.Subheader>
                        </div>
                    </Grid.Column>
                ))}
            </Grid.Row>
        </Grid>
    )
}

export default ClientOrders
