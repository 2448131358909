import React from 'react'
import { Divider, Header } from 'semantic-ui-react'

const ManageTeammates = () => (
    <>
        <Header as="h2">How do I add or remove a teammate?</Header>
        If you have Admin access to your team account, you can manage your
        teammates by selecting Team from the top navigation bar. In the members
        tab, you can review everyone who is given access to the team account,
        modify their role (i.e. Admin, User), or remove them from the account.
        If you wish to add a new teammate, click &quot;Add members&quot; to
        enter their email address(es) and assign their role(s).
        <Divider />
    </>
)

export default ManageTeammates
