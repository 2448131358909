import React, { Fragment } from 'react'
import { Divider, Header } from 'semantic-ui-react'

const ManageProject = () => (
    <Fragment>
        <Header as="h2">
            Can I update project description after the project has been
            approved?
        </Header>
        Once the project is approved, certain fields that are shared with the
        experts (marked with a red asterisk{' '}
        <span style={{ color: 'red' }}>*</span>), including the project title
        and interview topics, are no longer editable. If you need to make
        changes these fields, you will need to create a new project. You will
        still have control over other fields like the project start/end dates
        and project availability, and these fields can be updated at anytime.
        <Divider />
    </Fragment>
)

export default ManageProject
