import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Header, Form, Container } from 'semantic-ui-react'
import Autocomplete from './Autocomplete'
import { LOAD_SINGLE_ORGANIZATION_QUERY } from '../../../../graphql/organizations'

const SelectOrg = ({ step, organization, onSelect, excludeIds }) => {
    const [orgQuery, setOrgQuery] = useState('')
    const [organizationId, setOrganizationId] = useState(null)
    useEffect(() => {
        if (organization) {
            setOrgQuery(createQuery(organization))
            setOrganizationId(organization.id)
        } else {
            setOrgQuery('')
            setOrganizationId(null)
        }
    }, [step, organization])

    function createQuery({ name, location }) {
        return `${name} - ${location.city} ${location.country}`
    }

    const [loadOrg] = useLazyQuery(LOAD_SINGLE_ORGANIZATION_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: ({ organization }) => {
            onSelect(organization)
        },
    })

    const handleSelect = (organizationId) => {
        setOrganizationId(organizationId)
        if (organizationId) loadOrg({ variables: { organizationId } })
    }
    return (
        <Container>
            <Header as="h3">
                In order to merge two organizations, select one organization to
                remove from the database and another to keep.
            </Header>
            <Header as="h4">
                {step === 0
                    ? 'First, select an organization to remove'
                    : 'Next, select an organization to keep'}
            </Header>
            <Form>
                <Autocomplete
                    required
                    width={12}
                    placeholder={`Select an organization to ${
                        step === 0 ? 'remove' : 'keep'
                    }`}
                    context="Organization"
                    value={orgQuery}
                    readOnly={organizationId}
                    onChange={(value) => {
                        setOrgQuery(value)
                        setOrganizationId(null)
                        onSelect(null)
                    }}
                    onSelect={({ organizationId }) =>
                        handleSelect(organizationId)
                    }
                    exclude={excludeIds}
                />
            </Form>
        </Container>
    )
}

export default SelectOrg
