import React, { Fragment, useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Loader } from 'semantic-ui-react'
import { UPDATE_JOB_MUTATION } from '../../../graphql/job'
import { getInvitationEmail } from '../../Emails'
import EmailModal from './EmailModal'
import Alert from '../../Alert'
import { SingleProjectContext } from '../../../Context'
import DataContext from '../../../contexts/DataContext'
import AuthContext from '../../../contexts/AuthContext'

const InviteModal = ({
    onShow,
    onHide,
    selectedAdvisors,
    setSelectedAdvisors,
    project,
    onSave,
}) => {
    const { authUser } = useContext(AuthContext)
    const { user } = useContext(DataContext)
    const singleProjectContext = useContext(SingleProjectContext)

    const [adding, setAdding] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')

    const [updateJob] = useMutation(UPDATE_JOB_MUTATION)

    const onInvite = (email_content, sendEmail, email_subject) => {
        if (project.team.availableInvites <= 0) {
            setShowAlert(true)
            setAlertHeader('No available invites !')
            setAlertBody('Please purchase more credits')
            return
        }

        setAdding(true)

        let promises = []
        let success = []
        let failed = []
        selectedAdvisors.forEach((advisor) => {
            const emailInfo = {
                recipient: {
                    email: advisor.email,
                },
                sender: {
                    email: `${authUser.email}`,
                    name: `${
                        authUser.name.includes('@') ? 'Dexter' : authUser.name
                    }`,
                },
                cc: user.isSuperUser()
                    ? []
                    : project.owners.map((owner) => owner.email),
                text: 'New Project Invite',
            }
            console.log(project.jobs, advisor)
            if (
                advisor.expert.status === 'Active' &&
                !project.jobs.some(
                    (job) =>
                        job.user.id === advisor.id &&
                        job.invite_status === 'Invited'
                )
            ) {
                success.push(advisor)
                promises.push(
                    updateJob({
                        variables: {
                            userId: advisor.id,
                            projectId: project.id,
                            job: {
                                invite_status: 'Invited',
                                response_status: 'Pending',
                                overall_status: 'Pending Scheduling',
                                invitedAt: new Date(),
                                invitedReminderAt: new Date(),
                                email_logs: [
                                    {
                                        subject: email_subject,
                                        sent_at: new Date(),
                                    },
                                ],
                            },
                        },
                        update: (cache, { data: { updateJob } }) => {
                            if (singleProjectContext) {
                                singleProjectContext.setProject(
                                    updateJob.project
                                )
                            }
                            sendEmail(
                                emailInfo.recipient,
                                emailInfo.sender,
                                emailInfo.cc,
                                emailInfo.text,
                                getInvitationEmail(
                                    `${advisor.expert.salutation} ${
                                        advisor.fullName || advisor.email
                                    }`,
                                    project.title,
                                    email_content,
                                    project.end_date,
                                    user.isSuperUser()
                                        ? updateJob.offerRate
                                        : null
                                )
                            )
                        },
                    })
                )
            } else {
                failed.push(advisor)
            }
        })

        Promise.all(promises).then(() => {
            setAdding(false)
            setShowAlert(true)
            setAlertHeader('Email Sent')
            setAlertBody(
                <div>
                    <p>
                        {success.length} emails successfully sent ! <br />
                        {failed.length} failed due to invalid status.
                    </p>
                    {failed.length > 0 ? (
                        <p>
                            Failed:{' '}
                            {failed
                                .map(
                                    (advisor) =>
                                        advisor.fullName || advisor.email
                                )
                                .join(', ')}
                        </p>
                    ) : null}
                </div>
            )
            if (onSave) {
                onSave()
            }
            setSelectedAdvisors([])
            onHide()
        })
    }

    if (adding) return <Loader active />

    return (
        <Fragment>
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <EmailModal
                onShow={onShow}
                onHide={onHide}
                project={project}
                type="Invite"
                title={`Invite ${selectedAdvisors.length} advisor(s) to this project ?`}
                buttonText={`Send ${selectedAdvisors.length} email(s)`}
                onSend={onInvite}
            />
        </Fragment>
    )
}

export default InviteModal
