import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Button, Grid, Dimmer, Loader } from 'semantic-ui-react'
import PaymentTable from '../components/Payments/PaymentTable'
import LoadingSpinner from '../components/LoadingSpinner'
import Pagination from '../components/Pagination'
import { LOAD_TRANSACTIONS_QUERY } from '../graphql/transaction'
import { exportPayments } from '../utils/helpers'
import '../components/Payments/styles.css'

const Payment = () => {
    const ROW_PER_PAGE = 100

    const [transactions, setTransactions] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [tableData, setTableData] = useState([])

    const [
        loadTransactions,
        { called: txnCalled, loading: txnLoading, data: txnData },
    ] = useLazyQuery(LOAD_TRANSACTIONS_QUERY, {
        variables: {
            pagination: {
                page: currentPage,
                per_page: ROW_PER_PAGE,
            },
        },
        onCompleted: (data) => {
            setTransactions(data.transactions.transactions)
            setTotalCount(data.transactions.total)
        },
    })

    const [
        exportAllTransactions,
        { called: txnAllCalled, loading: txnAllLoading },
    ] = useLazyQuery(LOAD_TRANSACTIONS_QUERY, {
        variables: {
            pagination: {
                page: 1,
                per_page: 1000,
            },
        },
        onCompleted: (data) => {
            exportPayments(data.transactions.transactions)
        },
    })

    useEffect(() => {
        if (!txnCalled) {
            loadTransactions()
        }
        if (txnCalled && !txnLoading) {
            setTransactions(txnData.transactions.transactions)
        }
        if (txnData && txnData.transactions.transactions.length > 0) {
            setTableData(
                transactions
                    .filter((t) => t.paymentId !== null)
                    .map((transaction) => {
                        const { job, paymentId } = transaction
                        const { project, expert } = job
                        return {
                            ...transaction,
                            _id: job.id,
                            fullName: expert.fullName,
                            companyName: transaction.team.name,
                            code: project?.code,
                            isCredit:
                                paymentId.slice(0, 2) === 'ch' ? 'Yes' : '',
                            stripe_id: expert?.stripe_account,
                        }
                    })
            )
        }
    }, [
        txnCalled,
        txnLoading,
        currentPage,
        txnData,
        transactions,
        loadTransactions,
    ])

    const headerName = [
        { name: 'Dexter Job ID', field: '_id' },
        { name: 'Stripe payment ID', field: 'paymentId' },
        { name: 'Stripe ID', field: 'stripe_id' },
        { name: 'Paid Date', field: 'createdAt' },
        { name: 'Expert', field: 'fullName' },
        { name: 'Company Name', field: 'companyName' },
        { name: 'Code', field: 'code' },
        { name: 'Amount', field: 'amount' },
        { name: 'Credit Card', field: 'isCredit' },
        { name: 'Payment Detail', field: 'description' },
    ]

    if (!txnCalled && txnLoading) return <LoadingSpinner />

    return (
        <div className="payment-page">
            <Dimmer active={txnAllCalled && txnAllLoading}>
                <Loader>Exporting..</Loader>
            </Dimmer>
            <Grid columns={1}>
                <Grid.Column>
                    <h2>Payment history</h2>
                    <Button
                        size="tiny"
                        onClick={exportAllTransactions}
                        style={{ float: 'right' }}
                    >
                        Export
                    </Button>
                    <br />
                    <div className="payment-table">
                        <PaymentTable
                            headerName={headerName}
                            tableData={tableData}
                        />
                    </div>
                    <span className="pagination-data">
                        Total {totalCount} results | Page {currentPage} of{' '}
                        {Math.ceil(totalCount / ROW_PER_PAGE)}
                    </span>
                    <Pagination
                        defaultActivePage={currentPage}
                        totalPages={Math.ceil(totalCount / ROW_PER_PAGE)}
                        onPageChange={setCurrentPage}
                    />
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default Payment
