/* eslint-disabled */
import debounce from 'lodash.debounce'
import { stateToHTML } from 'draft-js-export-html'
import { convertFromRaw, EditorState, ContentState } from 'draft-js'
import moment from 'moment'
import XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { useState, useEffect } from 'react'
import { AUTH0_DATABASE } from '../constants/auth0'

export const isSuperAdmin = (memberships) => {
    if (!memberships) return false
    return memberships.some(
        (membership) =>
            membership.teamName === 'Dexter' && membership.role === 'Admin'
    )
}

export const isTeamAdmin = (teamId, memberships) => {
    if (!memberships) return false
    const currentMembership = memberships.filter(
        (membership) => membership.teamId === teamId
    )[0]
    if (!currentMembership) return false
    return currentMembership.role === 'Admin'
}

export const isTeamMember = (teamId, memberships) => {
    if (!memberships) return false
    return (
        isSuperAdmin(memberships) ||
        memberships.some((membership) => membership.teamId === teamId)
    )
}

export const toCredit = (hourlyRate) => {
    if (hourlyRate <= 450) return 1
    else if (hourlyRate <= 600) return 1.5
    else return 2
}

export const phoneValidation = (phone) => {
    if (phone === '') return true
    // eslint-disable-next-line
    const regex = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/
    return regex.test(phone)
}

export const getValidatedEmails = (emailString) => {
    var emails = []
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    var result = emailString.replace(/\s/g, '').split(/,|;/)
    for (var i = 0; i < result.length; i++) {
        if (regex.test(result[i])) {
            emails.push(result[i])
        }
    }
    return emails
}

/**
 *   Infinite scrolling implementation
 *
 *   @param setNumberOfCards - increase the display amount for cards
 */
export const onInfiniteScroll = (setNumberOfCards) => {
    window.onscroll = debounce(() => {
        const getDocHeight = () => {
            return Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.body.clientHeight,
                document.documentElement.clientHeight
            )
        }
        if (
            window.innerHeight + document.documentElement.scrollTop ===
            getDocHeight()
        ) {
            setNumberOfCards()
        }
    }, 100)
}

export const parseDatabase = (database) => {
    switch (database) {
        case 'google':
            return 'google-oauth2'
        case 'linkedin':
            return 'linkedin'
        default:
            return AUTH0_DATABASE
    }
}

/**
 *    Formats payment amount into dollar format
 *
 *    @param num - value that needs to be formatted
 *    @return dollar formatted value
 */
export const formatDollar = (num) => {
    num = parseFloat(num)
    return `$${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

/**
 *   Score the matchness of two lists, a match element is 1 point
 *
 *   @param list1 - first list
 *   @param list2 - second list
 *   @return number of score points
 */
export const getIncludeInArrayScore = (list1, list2) => {
    let set = new Set(list1)
    let score = 0
    for (let i = 0; i < list2.length; i++) {
        if (set.has(list2[i])) score++
    }
    return score
}

/**
 *    Transfers the EditorState format data into plain HTML
 *
 *    @param str - HTML in String format
 *    @return accepted editorState format data
 */
export const getHTMLFromEditorState = (str) => {
    try {
        const result = JSON.parse(str)
        return stateToHTML(convertFromRaw(result))
    } catch (err) {
        return str
    }
}

/**
 *    Transfers HTML format into EditorState format data
 *
 *    @param str - String of HTML
 *    @return accepted editorState format data
 */
export const getEditorStateFromHTML = (str) => {
    try {
        const result = JSON.parse(str)
        return EditorState.createWithContent(convertFromRaw(result))
    } catch (err) {
        return EditorState.createWithContent(
            ContentState.createFromText(str || '')
        )
    }
}

export const convertRichTextToPlainText = (richText) => {
    try {
        const content = JSON.parse(richText)
        let plainText = ''
        for (let i = 0; i < content.blocks.length; i++) {
            plainText += content.blocks[i].text + ' '
        }
        return plainText
    } catch (err) {
        return richText
    }
}

/**
 *   Exports payment history into xlsx file
 *
 *   @param transactions - array of transactions
 */
export const exportPayments = (transactions) => {
    let dataSheets = []
    let titles = [
        'Dexter Job ID',
        'Stripe payment ID',
        'Stripe ID',
        'Paid Date',
        'Expert',
        'Company Name',
        'Code',
        'Amount',
        'Credit Card',
        'Payment Detail',
    ]

    dataSheets.push(titles)

    transactions.forEach((transaction) => {
        //find the expert associated with this job
        const { job } = transaction
        const { expert, project } = job

        const paidAt = moment(transaction.createdAt).format('l')
        const stripeCountry = [
            'United States',
            'United States of America',
            'United Kingdom',
            'United Kingdom of Great Britain and North Ireland',
            'Canada',
        ]
        let content = [
            job.id,
            transaction.paymentId,
            expert?.stripe_account,
            paidAt,
            expert.fullName || expert.email,
            transaction.team.name,
            project?.code,
            transaction.amount,
            stripeCountry.includes(expert.country) ? null : 'Yes',
            transaction.description,
        ]
        dataSheets.push(content)
    })
    let wb = XLSX.utils.book_new()
    wb.SheetNames.push('Test Sheet')
    let ws_data = dataSheets
    var ws = XLSX.utils.aoa_to_sheet(ws_data)
    wb.Sheets['Test Sheet'] = ws
    let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

    function s2ab(s) {
        var buf = new ArrayBuffer(s.length)
        var view = new Uint8Array(buf)
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
        return buf
    }
    saveAs(
        new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
        'Transaction.xlsx'
    )
}

/**
 *   Exports selected Experts into xlsx file
 *
 *   @param experts - all Experts
 *   @param expertIds - array of selected experts' id
 */
export const exportAdvisors = (advisors) => {
    let dataSheets = []
    let titles = [
        'Name',
        'Status',
        'Email',
        'Phone',
        'Role',
        'Organization',
        'Country',
        'Tags',
        'Hourly Rate',
        'Linkedin',
        'Join Date',
    ]

    dataSheets.push(titles)

    advisors.forEach((advisor) => {
        let content = [
            advisor.fullName,
            advisor.expert.status,
            advisor.email,
            advisor.expert.phone,
            advisor.expert.title,
            advisor.expert.organization.name,
            advisor.expert.country,
            advisor.expert.tags,
            formatDollar(
                advisor.expert.hourlyRate ? advisor.expert.hourlyRate : 0
            ),
            advisor.expert.linkedin_profile,
            moment(advisor.expert.created_at).format('l'),
        ]
        dataSheets.push(content)
    })
    let wb = XLSX.utils.book_new()
    wb.SheetNames.push('Test Sheet')
    let ws_data = dataSheets
    var ws = XLSX.utils.aoa_to_sheet(ws_data)
    wb.Sheets['Test Sheet'] = ws
    let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

    function s2ab(s) {
        var buf = new ArrayBuffer(s.length)
        var view = new Uint8Array(buf)
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
        return buf
    }
    saveAs(
        new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
        'Experts.xlsx'
    )
}

export const exportManageAdvisors = (jobs) => {
    let dataSheets = []
    let titles = [
        'IV#',
        'Date',
        'Full name',
        'Source',
        'Specialty',
        'Organization Type',
        'Organization Name',
        'Hourly Rate',
        'Paid?',
    ]

    dataSheets.push(titles)

    const getValue = (job) => {
        // Show either completedAt > response_status> invite_status
        if (job.completedAt) return moment(job.completedAt).format('MM/DD/YYYY')
        else if (job.overall_status !== 'Pending Scheduling')
            return job.overall_status
        return job.invite_status
    }

    jobs.forEach((job, index) => {
        let content = [
            index + 1,
            getValue(job),
            job.user.fullName || job.user.email,
            'Dexter',
            job.expert.medical_specialties.join(', '),
            job.expert.organization.type,
            job.expert.organization.name,
            formatDollar(job.expert.hourlyRate || 0),
            job.overall_status === 'Paid' ? 'Paid' : 'Not Paid',
        ]
        dataSheets.push(content)
    })
    let wb = XLSX.utils.book_new()
    wb.SheetNames.push('Test Sheet')
    let ws_data = dataSheets
    var ws = XLSX.utils.aoa_to_sheet(ws_data)
    wb.Sheets['Test Sheet'] = ws
    let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

    function s2ab(s) {
        var buf = new ArrayBuffer(s.length)
        var view = new Uint8Array(buf)
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
        return buf
    }
    saveAs(
        new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
        'ManageAdvisors.xlsx'
    )
}

export const matchPrefix = (prefix, str) => {
    if (!prefix || !str) return false
    if (!prefix.match(/^[\p{L}0-9\s/-]+$/u)) return false
    prefix = prefix.toLowerCase()
    str = str.toLowerCase()

    let search = prefix.split(/\s+/u)

    for (let i = 0, len = search.length; i < len; i++) {
        let regex = new RegExp(`\\b${search[i]}\\b`, 'iu')
        if (regex.test(str) === false) {
            return false
        }
    }

    return true
}

export const includeInArray = (list1, list2) => {
    if (!list1 || !list2) return false
    let set = new Set(list1)
    for (let i = 0; i < list2.length; i++) {
        if (set.has(list2[i])) return true
    }
    return false
}

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height,
    }
}

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    )

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}

export const round = (num, places = 0) =>
    Math.round((num + Number.EPSILON) * Math.pow(10, places)) /
    Math.pow(10, places)

export const capitalize = (str) => {
    const words = str.split(' ')
    return words
        .map((word) => {
            const lower = word.toLowerCase()
            return word.charAt(0).toUpperCase() + lower.slice(1)
        })
        .join(' ')
}
