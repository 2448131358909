import React from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import { Header, List, Icon, Popup } from 'semantic-ui-react'
import { JOB_ADMIN_NOTES } from '../../../../graphql/job'

const JobInformation = ({ job }) => {
    const copyEmail = (e, email) => {
        e.preventDefault()
        let aux = document.createElement('input')
        aux.setAttribute('value', email)
        document.body.appendChild(aux)
        aux.select()
        aux.setSelectionRange(0, 99999)
        document.execCommand('copy')
        document.body.removeChild(aux)
    }
    const jobOwners =
        job?.interviewers?.lengtth > 0 ? job.interviewers : job.project.owners

    const { data } = useQuery(JOB_ADMIN_NOTES, {
        variables: { id: job.id },
    })

    return (
        <List>
            <Header as="h6">Job Information</Header>
            <List.Item>
                Expert: {job.user.fullName || job.user.email}{' '}
                <span>
                    <Popup
                        content="Copied email!"
                        style={{
                            zIndex: 9999,
                        }}
                        on="click"
                        trigger={
                            <Icon
                                name="mail"
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={(e) => copyEmail(e, job.user.email)}
                            />
                        }
                    />
                    {'   '}
                    {job.user.expert.stripe_account && (
                        <Popup
                            content="Copied Stripe ID!"
                            style={{
                                zIndex: 9999,
                            }}
                            on="click"
                            trigger={
                                <Icon
                                    name="stripe"
                                    size="large"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={(e) =>
                                        copyEmail(
                                            e,
                                            job.user.expert.stripe_account
                                        )
                                    }
                                />
                            }
                        />
                    )}
                </span>
            </List.Item>

            <List.Item>
                {job.project.type === 'Interview'
                    ? 'Interviewers'
                    : 'Project Owners'}
                : {job.project.team.name} |{' '}
                {jobOwners.map((owner) => (
                    <span key={owner.id}>
                        {owner.fullName || owner.email}
                        {'   '}
                        <Popup
                            content="Copied email!"
                            style={{
                                zIndex: 9999,
                            }}
                            on="click"
                            trigger={
                                <Icon
                                    name="mail"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={(e) => copyEmail(e, owner.email)}
                                />
                            }
                        />
                    </span>
                ))}
            </List.Item>

            {job.offerRate && (
                <List.Item>
                    Offer rate:{' '}
                    <span>{`$${job.offerRate} ${
                        job.project.type === 'Interview'
                            ? 'per hour'
                            : 'for survey'
                    }`}</span>
                </List.Item>
            )}

            {data?.job.adminNotes && (
                <List.Item>Admint Notes: {data.job.adminNotes} </List.Item>
            )}

            <List.Item>
                {job.meetingAt ? (
                    <div>
                        Meeting at:{' '}
                        {moment(job.meetingAt).format('MM/DD/YY, h:mm A')}
                    </div>
                ) : (
                    <div>
                        {' '}
                        Completed At:{' '}
                        {moment(job.completedAt).format('MM/DD/YY, h:mm A')}
                    </div>
                )}
            </List.Item>
        </List>
    )
}

export default JobInformation
