import React, { useContext } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Credit from '../../../Credit'
import Member from '../../../Member'
import { TeamManageContext } from '../../../../Context'

const TeamRoutes = () => {
    const { team } = useContext(TeamManageContext)

    return (
        <Switch>
            <Route
                exact
                path="/admin/teams/:id/credits"
                render={() => <Credit team={team} />}
            />
            <Route
                exact
                path="/admin/teams/:id/members"
                render={() => <Member team={team} />}
            />
            <Route render={() => <Redirect to="/not-found-page" />} />
        </Switch>
    )
}

export default TeamRoutes
