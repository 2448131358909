import React, { Fragment, useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Message, List, Icon } from 'semantic-ui-react'
import { UPDATE_PROJECT_MUTATION } from '../../../graphql/project'
import { SingleProjectContext } from '../../../Context'

const OnboardingMessage = () => {
    const { project } = useContext(SingleProjectContext)

    const [showPendingAdvisor, setShowPendingAdvisor] = useState(false)

    const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION)

    const removePendingAdvisor = (email) => {
        const tempList = [...project.pending_experts]
        const index = tempList.map((obj) => obj.email).indexOf(email)
        if (index === -1) return
        tempList.splice(index, 1)

        updateProject({
            variables: {
                id: project.id,
                input: {
                    pending_experts: tempList,
                },
            },
        })
    }

    return (
        <Fragment>
            {project.pending_experts &&
                project.pending_experts.length !== 0 && (
                    <Message warning>
                        <Message.Header>
                            There are {project.pending_experts.length}{' '}
                            onboarding advisors
                        </Message.Header>
                        {showPendingAdvisor ? (
                            <List horizontal>
                                {project.pending_experts.map((obj, index) => {
                                    return (
                                        <List.Item key={index}>
                                            {obj.email}
                                            <a
                                                href="# "
                                                onClick={() =>
                                                    removePendingAdvisor(
                                                        obj.email
                                                    )
                                                }
                                            >
                                                <Icon name="remove" />
                                            </a>
                                        </List.Item>
                                    )
                                })}
                            </List>
                        ) : null}
                        <a
                            href="# "
                            style={{ color: 'brown' }}
                            onClick={() =>
                                setShowPendingAdvisor(!showPendingAdvisor)
                            }
                        >
                            {showPendingAdvisor ? (
                                <div>
                                    Hide
                                    <br />
                                </div>
                            ) : (
                                'Show'
                            )}
                        </a>
                    </Message>
                )}
        </Fragment>
    )
}

export default OnboardingMessage
