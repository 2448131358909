// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invite-modal {
  margin-top: 6%;
}

.invite-modal .modal-lg {
  max-width: 90%;
}

.message-modal .subtext {
  float: left;
  color: #000;
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modals/ProjectInvite/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".invite-modal {\n  margin-top: 6%;\n}\n\n.invite-modal .modal-lg {\n  max-width: 90%;\n}\n\n.message-modal .subtext {\n  float: left;\n  color: #000;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
