import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import LoadingSpinner from '../components/LoadingSpinner'
import Alert from '../components/Alert'

const AuthContext = React.createContext({ token: null })
export const AuthProvider = ({ children }) => {
    const {
        isLoading,
        isAuthenticated,
        getIdTokenClaims,
        error,
        loginWithRedirect,
        loginWithPopup,
        logout,
        user,
    } = useAuth0()

    const [token, setToken] = useState(null)

    const tokenNeeded = isAuthenticated && !token

    const signout = () =>
        logout({
            logoutParams: { returnTo: window.location.origin },
        })

    if (tokenNeeded) {
        getIdTokenClaims().then((t) => {
            setToken(t.__raw)
        })
    }

    if (isLoading || tokenNeeded) {
        return <LoadingSpinner />
    }

    return (
        <AuthContext.Provider
            value={{
                token,
                loginWithRedirect,
                authLogout: signout,
                loginWithPopup,
                authUser: user,
            }}
        >
            {error && <Alert header={error.message} onClose={signout} />}
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
