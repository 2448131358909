import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation } from '@apollo/client'
import { Grid, Loader } from 'semantic-ui-react'
import { ExtractionReviewContext } from '../../../Context'
import Results from './Results'
import Shortcut from '../Shortcut'
import {
    MdOutlineKeyboardBackspace,
    MdCheck,
    MdTurnRight,
} from 'react-icons/md'
import { STORE_TAGEXTRACTIONS } from '../../../graphql/datawork'
import { capitalize } from '../../../utils/helpers'
import { LOAD_MANAGE_EXPERTS_QUERY } from '../../../graphql/admin'

const Confirmation = () => {
    const {
        datawork,
        expertListData,
        history,
        confirmationEnabled,
        undoExtraction,
    } = useContext(ExtractionReviewContext)

    const pageHistory = useHistory()

    const [storeTagExtractions, { loading }] = useMutation(
        STORE_TAGEXTRACTIONS,
        {
            onCompleted: (data, { redirectPath }) => {
                pageHistory.push(redirectPath)
            },
            refetchQueries: [{ query: LOAD_MANAGE_EXPERTS_QUERY }],
        }
    )

    const submitTagExtractionReview = (nextExpertId) => {
        storeTagExtractions({
            variables: {
                expertId: datawork.expert.id,
                decisions: history.map((h) => ({
                    tag: h.extraction.tag,
                    decision: h.decision,
                    category: h.category,
                })),
            },
            redirectPath: `/admin/experts${
                nextExpertId ? `/${nextExpertId}/review` : ''
            }`,
        })
    }

    useHotkeys('u', () => undoExtraction(history.length - 1), {
        enabled: confirmationEnabled,
    })
    useHotkeys('e', () => submitTagExtractionReview(), {
        enabled: confirmationEnabled,
    })
    useHotkeys(
        'space',
        () => {
            if (expertListData.nextIndex) {
                submitTagExtractionReview(
                    expertListData.options.at(expertListData.nextIndex).value
                )
            }
        },
        {
            enabled: confirmationEnabled,
        }
    )

    return (
        <Grid.Row className="body-wrapper">
            <Grid.Column className="confirmation">
                <Grid.Row>
                    {history.length}/{datawork.tagExtractions.length}
                </Grid.Row>
                <Grid.Row className="confirmation-header">
                    Way to go! You{"'"}ve cleaned all of{' '}
                    {datawork.expert.first_name &&
                    datawork.expert.first_name !== ''
                        ? capitalize(datawork.expert.first_name)
                        : capitalize(datawork.expert.fullName)}
                    {"'"}s extractions.
                </Grid.Row>
                <Grid.Row className="results-wrapper">
                    <Results
                        history={history}
                        numTagExtractions={datawork.tagExtractions.length}
                    />
                </Grid.Row>
                {loading ? (
                    <Loader active inline="centered" />
                ) : (
                    <div className="confirmation-actions fullwidth">
                        <Grid.Column className="action-button-wrapper">
                            <div
                                className="action-button"
                                onClick={() =>
                                    undoExtraction(history.length - 1)
                                }
                            >
                                <MdOutlineKeyboardBackspace size={28} />
                                <span className="margin-top-4">
                                    Undo last extraction
                                </span>
                            </div>
                            <Shortcut shortcut={'U'} />
                        </Grid.Column>
                        <Grid.Column className="action-button-wrapper">
                            <div
                                className="action-button"
                                onClick={() => submitTagExtractionReview()}
                            >
                                <span className="margin-top-4">
                                    Notify expert and exit
                                </span>
                                <MdCheck size={28} />
                            </div>
                            <Shortcut shortcut={'E'} />
                        </Grid.Column>
                        <Grid.Column
                            className="action-button-wrapper"
                            style={{
                                display: expertListData.nextIndex
                                    ? 'flex'
                                    : 'none',
                            }}
                        >
                            <div className="text-gray">
                                Next:{' '}
                                {expertListData.nextIndex
                                    ? expertListData.options.at(
                                          expertListData.nextIndex
                                      ).text
                                    : 'None'}
                            </div>
                            <div
                                className="action-button"
                                onClick={
                                    expertListData.nextIndex
                                        ? () =>
                                              submitTagExtractionReview(
                                                  expertListData.options.at(
                                                      expertListData.nextIndex
                                                  ).value
                                              )
                                        : () => {}
                                }
                            >
                                <span className="margin-top-4">
                                    Notify expert and continue
                                </span>
                                <MdTurnRight size={28} />
                            </div>
                            <Shortcut shortcut={'Space'} />
                        </Grid.Column>
                    </div>
                )}
            </Grid.Column>
        </Grid.Row>
    )
}

export default Confirmation
