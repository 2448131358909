import React, { useState } from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import SelectOrg from './SelectOrg'
import ConfirmMerge from './ConfirmMerge'
import InfoBox from './InfoBox'
import NavigateButtons from './NavigateButtons'

const MainContainer = ({ step, setStep }) => {
    const [orgToRemove, setOrgToRemove] = useState(null)
    const [orgToKeep, setOrgToKeep] = useState(null)

    function excludeIds() {
        const Ids = []
        if (orgToRemove) Ids.push(orgToRemove.id)
        if (orgToKeep) Ids.push(orgToKeep.id)
        return Ids
    }

    function handleReset() {
        setStep(0)
        setOrgToKeep(null)
        setOrgToRemove(null)
    }

    const ORG_BY_STEP = {
        0: { organization: orgToRemove, setOrganization: setOrgToRemove },
        1: { organization: orgToKeep, setOrganization: setOrgToKeep },
    }

    return (
        <Grid stackable>
            <Grid.Column width={step === 2 ? 12 : 10}>
                {step === 2 ? (
                    <ConfirmMerge
                        orgToKeep={orgToKeep}
                        orgToRemove={orgToRemove}
                        onReset={handleReset}
                    />
                ) : (
                    <SelectOrg
                        step={step}
                        organization={ORG_BY_STEP[step].organization}
                        onSelect={ORG_BY_STEP[step].setOrganization}
                        excludeIds={excludeIds()}
                    />
                )}
                <NavigateButtons
                    step={step}
                    setStep={setStep}
                    onCancel={handleReset}
                    hidden={step === 2}
                    disabled={step !== 2 && !ORG_BY_STEP[step].organization}
                />
            </Grid.Column>
            <Grid.Column width={step === 2 ? 4 : 6}>
                {step === 2 ? (
                    <Segment>{`This action will delete ${orgToRemove.name} from the database and
            re-assign ${orgToRemove.experts.length} associated experts to ${orgToKeep.name}`}</Segment>
                ) : (
                    <InfoBox organization={ORG_BY_STEP[step].organization} />
                )}
            </Grid.Column>
        </Grid>
    )
}

export default MainContainer
