import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { Container, Header, Form, Button, Dropdown } from 'semantic-ui-react'
import Alert from '../Alert'
import {
    UPDATE_MEMBER_MUTATION,
    LOAD_TEAM_MEMBERS_QUERY,
} from '../../graphql/team'
import { MEMBER_OPTIONS } from './options'

const EditMember = ({ member, team, onCancel }) => {
    const [role, setRole] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')

    useEffect(() => {
        setRole(member.role)
    }, [member])

    const [updateMember] = useMutation(UPDATE_MEMBER_MUTATION, {
        refetchQueries: [
            {
                query: LOAD_TEAM_MEMBERS_QUERY,
                variables: { id: team.id },
            },
        ],
        awaitRefetchQueries: true,
        onCompleted: onCancel,
        onError: (error) => {
            setShowAlert(true)
            setAlertHeader('Error')
            setAlertBody(
                `You can not update the member: ${
                    error.message.split('GraphQL error: ')[1]
                }`
            )
        },
    })

    const onSave = () => {
        updateMember({
            variables: {
                teamId: team.id,
                userId: member.userId,
                role,
            },
        })
    }

    return (
        <Container className="side-panel">
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <Header size="huge">
                <Header.Content>
                    {member.user.fullName || '(Missing Name)'}
                    <Header.Subheader>{member.user.email}</Header.Subheader>
                </Header.Content>
            </Header>
            <Form>
                <Form.Field>
                    <label>Role</label>
                    <Dropdown
                        selection
                        fluid
                        options={MEMBER_OPTIONS}
                        value={role}
                        onChange={(e, { value }) => setRole(value)}
                    />
                </Form.Field>
                <div>
                    <Button onClick={onSave}>Save changes</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </div>
            </Form>
        </Container>
    )
}

export default EditMember
