import React, { useContext } from 'react'
import { Grid, Header, List } from 'semantic-ui-react'
import { ResponsiveBar } from '@nivo/bar'
import { DashboardContext } from '../../../../Context'

const BarChart = ({ data, keys, type }) => {
    const BAR_PROPS = {
        revenue: {
            groupMode: 'grouped',
            colors: ({ id }) => {
                return id === 'Credit Purchase' ? '#1B5E82' : '#f47560'
            },
            axisLeft: { format: (v) => `$${v}` },
            // eslint-disable-next-line react/display-name
            tooltip: ({ data }) => {
                return (
                    <List>
                        <List.Item>{data.time}</List.Item>
                        {Object.keys(data).map((key) => {
                            if (
                                key === 'time' ||
                                key === 'Honoraria' ||
                                key === 'cashFlow' ||
                                key === 'Credit Purchase'
                            )
                                return <div key={key} />
                            return (
                                <List.Item key={key}>
                                    {`${key}: $${parseFloat(data[key]).toFixed(
                                        2
                                    )}`}
                                </List.Item>
                            )
                        })}
                        <List.Item>{`Honoraria: $${parseFloat(
                            data['Honoraria']
                        ).toFixed(2)}`}</List.Item>
                        <List.Item>{`Net Cash Flow: $${parseFloat(
                            data.cashFlow
                        ).toFixed(2)}`}</List.Item>
                    </List>
                )
            },
        },
        jobs: {
            groupMode: 'stacked',
            colors: { scheme: 'set3' },
            axisLeft: {
                legend: '# Completed Jobs',
                legendPosition: 'middle',
                legendOffset: -40,
            },
            // eslint-disable-next-line react/display-name
            tooltip: ({ data }) => {
                return (
                    <List>
                        <List.Item>{data.time}</List.Item>
                        {Object.keys(data).map((key) => {
                            if (key === 'time' || key === 'numJobs')
                                return <div key={key} />
                            return (
                                <List.Item key={key}>
                                    {`${key}: ${data[key]}`}
                                </List.Item>
                            )
                        })}
                        <List.Item>{`Total: ${data.numJobs}`}</List.Item>
                    </List>
                )
            },
        },
    }
    return (
        <ResponsiveBar
            data={data.reverse()}
            keys={keys}
            indexBy="time"
            margin={{ top: 10, right: 130, bottom: 50, left: 60 }}
            enableLabel={false}
            enableGridY={false}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    symbolSize: 20,
                },
            ]}
            {...BAR_PROPS[type]}
        />
    )
}

const RevenueChart = ({ transactionsByPeriod }) => {
    const { getTeamNameById, teams } = useContext(DashboardContext)

    const revenueData = Object.keys(transactionsByPeriod).map((time) => {
        const result = {}
        result.time = time
        const teamStats = transactionsByPeriod[time]
            .filter((t) => t.type === 'Credit Purchase')
            .reduce((count, tr) => {
                const teamName = getTeamNameById(tr.team)
                count[teamName] = count[teamName] || {
                    name: teamName,
                    amount: 0,
                }
                count[teamName].amount += tr.amount
                return count
            }, {})

        Object.values(teamStats)
            .filter((t) => t.amount !== 0)
            .forEach((team) => {
                result[team.name] = Number(parseFloat(team.amount).toFixed(2))
            })
        const totalCreditPurchase = transactionsByPeriod[time]
            .filter((t) => t.type === 'Credit Purchase')
            .reduce((sum, t) => sum + t.amount, 0)
        const totalHonoraria = transactionsByPeriod[time]
            .filter((t) => t.type === 'Job Payment')
            .reduce((sum, t) => sum + t.amount, 0)
        result['Credit Purchase'] = totalCreditPurchase
        result['Honoraria'] = totalHonoraria
        result.cashFlow = totalCreditPurchase - totalHonoraria
        return result
    })

    const jobData = Object.keys(transactionsByPeriod).map((time) => {
        const result = {}
        result.time = time
        const teamStats = transactionsByPeriod[time]
            .filter((t) => t.type === 'Job Payment')
            .reduce((count, tr) => {
                const teamName = getTeamNameById(tr.team)
                count[teamName] = count[teamName] || {
                    name: teamName,
                    count: 0,
                }
                count[teamName].count++
                return count
            }, {})

        Object.values(teamStats)
            .filter((t) => t.count !== 0)
            .forEach((team) => {
                result[team.name] = team.count
            })

        result.numJobs = Object.values(teamStats).reduce(
            (sum, t) => sum + t.count,
            0
        )
        return result
    })

    const STATS_GRAPHS = [
        {
            title: 'Revenue and Cash Flow',
            graphData: revenueData,
            graphKeys: ['Credit Purchase', 'Honoraria'],
            graphType: 'revenue',
        },
        {
            title: 'Completed Jobs',
            graphData: jobData,
            graphKeys: teams.map((t) => t.name),
            graphType: 'jobs',
        },
    ]

    return (
        <Grid style={{ margin: '1rem' }}>
            {STATS_GRAPHS.map((statsGraph) => (
                <React.Fragment key={statsGraph.title}>
                    <Grid.Row style={{ marginLeft: '1rem' }}>
                        <Header as="h6">{statsGraph.title}</Header>
                    </Grid.Row>
                    <Grid.Row centered>
                        <Grid.Column>
                            <div style={{ height: '400px' }}>
                                <BarChart
                                    data={statsGraph.graphData}
                                    keys={statsGraph.graphKeys}
                                    type={statsGraph.graphType}
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </React.Fragment>
            ))}
        </Grid>
    )
}

export default RevenueChart
