import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Form, Input, Table, Dropdown } from 'semantic-ui-react'
import moment from 'moment'
import { UPDATE_PROJECT_MUTATION } from '../../../graphql/project'
import { SingleProjectContext, EmailTemplateContext } from '../../../Context'

const Template = () => {
    const { project, setProject } = useContext(SingleProjectContext)
    const { setShowEdit, setIsEdit, setEditingTemplate } =
        useContext(EmailTemplateContext)

    const [templateSearch, setTemplateSearch] = useState('')

    const [deleteEmailTemplate] = useMutation(UPDATE_PROJECT_MUTATION)

    const deleteTemplate = (index) => {
        const templates = project.email_templates.slice()

        templates.splice(index, 1)

        deleteEmailTemplate({
            variables: {
                id: project.id,
                input: {
                    email_templates: templates,
                },
            },
            update: (cache, { data: { updateProject } }) => {
                setProject(updateProject)
            },
        })
    }

    let sortedProjectEmailTemplates = []

    if (project.email_templates) {
        sortedProjectEmailTemplates = project.email_templates.slice()
        sortedProjectEmailTemplates.sort(
            (a, b) => new Date(b.last_modify) - new Date(a.last_modify)
        )
    }

    return (
        <div>
            <Form>
                <Button
                    primary
                    floated="right"
                    onClick={() => {
                        setIsEdit(false)
                        setShowEdit(true)
                    }}
                >
                    New Template
                </Button>
                <Input
                    icon="search"
                    style={{ marginTop: '10%' }}
                    placeholder="Search template"
                    onChange={(e, { value }) => setTemplateSearch(value)}
                />
            </Form>
            {sortedProjectEmailTemplates && (
                <Table basic="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Category</Table.HeaderCell>
                            <Table.HeaderCell>Subject</Table.HeaderCell>
                            <Table.HeaderCell>Last Modified</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {sortedProjectEmailTemplates.map((template, index) => {
                            return (
                                <Table.Row
                                    key={index}
                                    style={
                                        `${template.subject} ${template.category}`
                                            .toLowerCase()
                                            .search(
                                                templateSearch
                                                    .replace(
                                                        /[*|":<>[\]{}`\\()';@&$]/g,
                                                        ''
                                                    )
                                                    .toLowerCase()
                                            ) === -1
                                            ? { display: 'none' }
                                            : {}
                                    }
                                >
                                    <Table.Cell>{template.category}</Table.Cell>
                                    <Table.Cell>
                                        <a
                                            href="# "
                                            style={{
                                                color: 'rgb(21, 121, 198)',
                                            }}
                                            onClick={() => {
                                                setIsEdit(true)
                                                setEditingTemplate(template)
                                                setShowEdit(true)
                                            }}
                                        >
                                            {template.subject}
                                        </a>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {moment(template.last_modify).format(
                                            'MM/DD/YYYY kk:mm'
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Dropdown
                                            icon="ellipsis vertical"
                                            floating
                                            labeled
                                            className="icon"
                                        >
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    text="Delete"
                                                    onClick={() =>
                                                        deleteTemplate(index)
                                                    }
                                                />
                                                <Dropdown.Divider />
                                                <Dropdown.Item
                                                    text="Edit"
                                                    onClick={() => {
                                                        setIsEdit(true)
                                                        setEditingTemplate(
                                                            template
                                                        )
                                                        setShowEdit(true)
                                                    }}
                                                />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            )}
        </div>
    )
}

export default Template
