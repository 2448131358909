/* eslint-disable */
import React from 'react'
import Spinner from 'react-spinkit'

import './styles.css'

const LoadingSpinner = () => {
    return (
        <div className="loading-section" style={{ width: '100%' }}>
            <div className="loading-icon">
                <Spinner name="pacman" color="#427A9E" />
            </div>
            <div className="loading-text">
                <h4>We are gathering data for you right now!</h4>
                <h6>Just a moment.</h6>
                <h6>If this takes a while, please refresh the page.</h6>
            </div>
        </div>
    )
}

export default LoadingSpinner
