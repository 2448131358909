/* eslint-disable */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
    Form,
    Card,
    Image,
    Rating,
    Icon,
    Feed,
    Popup,
} from 'semantic-ui-react'
import moment from 'moment'
import { getHTMLFromEditorState } from '../../utils/helpers'

const PastCard = ({ advisor, job, project }) => {
    const [showContent, setShowContent] = useState(false)
    const getStatus = () => {
        if (
            ['Completed', 'Paid'].includes(job.overall_status) ||
            job.overall_status === 'Declined'
        )
            return job.overall_status
        else return 'Cancelled'
    }

    return (
        <Card className="pending-cards">
            <Card.Content>
                <Popup
                    content={`${project.type}`}
                    trigger={
                        <Image
                            floated="right"
                            size="mini"
                            src={
                                project.type === 'Interview'
                                    ? '/logos/interview.svg'
                                    : '/logos/survey.svg'
                            }
                        />
                    }
                />

                <Card.Header>
                    <Link to={`/projects/${project.id}/manage-advisors`}>
                        {project.title}
                    </Link>
                </Card.Header>
                <Card.Meta>
                    <span>
                        {getStatus()}{' '}
                        {getStatus() === 'Completed'
                            ? ` | ${moment(job.completedAt).format('LL')}`
                            : null}
                    </span>
                </Card.Meta>
                <Card.Description className="content-description">
                    <div
                        style={
                            !showContent
                                ? {
                                      visibility: 'hidden',
                                      maxHeight: '0.1em',
                                      width: '100%',
                                      overflow: 'hidden',
                                  }
                                : null
                        }
                    >
                        <div
                            style={{ whiteSpace: 'pre-wrap' }}
                            dangerouslySetInnerHTML={{
                                __html: getHTMLFromEditorState(
                                    project.description
                                ),
                            }}
                        ></div>
                    </div>
                    <a
                        href="#"
                        className="show-content-button"
                        onClick={(e) => {
                            e.preventDefault()
                            setShowContent(!showContent)
                        }}
                    >
                        {showContent ? (
                            <span>
                                Hide details
                                <Icon name="angle up" />
                            </span>
                        ) : (
                            <span>
                                Show details
                                <Icon name="angle down" />
                            </span>
                        )}
                    </a>
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
                <Form>
                    <div className="inline field">
                        <Rating
                            icon="star"
                            rating={job.expert_rating || 0}
                            maxRating={5}
                            disabled
                        />
                    </div>
                </Form>
                <Feed>
                    <Feed.Event>
                        <Feed.Content>
                            <Feed.Summary>
                                {job.overall_status === 'Paid' ? (
                                    <div className="feed-summary-title">
                                        <span className="comment-owner">
                                            {`${job.payment?.enteredBy || 'Someone'} `}
                                        </span>
                                        {`reviewed ${
                                            advisor.fullName || advisor.email
                                        } on ${project.title}`}
                                    </div>
                                ) : null}
                            </Feed.Summary>
                            <Feed.Extra text>
                                <div className="feed-comment">
                                    {job.expert_comment}
                                </div>
                            </Feed.Extra>
                        </Feed.Content>
                    </Feed.Event>
                </Feed>
            </Card.Content>
        </Card>
    )
}

export default PastCard
