import React, { useState, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Table, Button, Icon, Popup } from 'semantic-ui-react'
import ConfirmDeleteMemberModal from '../Modals/Team/ConfirmDeleteMemberModal'
import Alert from '../Alert'
import {
    LOAD_TEAM_MEMBERS_QUERY,
    DELETE_MEMBER_MUTATION,
} from '../../graphql/team'
import LoadingSpinner from '../LoadingSpinner'
import DataContext from '../../contexts/DataContext'

const MemberTable = ({ team, onUpdateMember }) => {
    const { user } = useContext(DataContext)

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedMember, setSelectedMember] = useState({})
    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')

    const { data, loading } = useQuery(LOAD_TEAM_MEMBERS_QUERY, {
        skip: !team.id,
        variables: { id: team.id },
    })

    const [deleteMember] = useMutation(DELETE_MEMBER_MUTATION, {
        refetchQueries: [
            {
                query: LOAD_TEAM_MEMBERS_QUERY,
                variables: { id: team.id },
            },
        ],
        awaitRefetchQueries: true,
        onError: (error) => {
            setShowAlert(true)
            setAlertHeader('Error')
            setAlertBody(
                `You can not delete the member: ${
                    error.message.split('GraphQL error: ')[1]
                }`
            )
        },
    })

    const onDeleteMember = (member) => {
        deleteMember({
            variables: {
                teamId: team.id,
                userId: member.userId,
            },
        })
    }

    if (loading) return <LoadingSpinner />

    return (
        <Table celled>
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <ConfirmDeleteMemberModal
                onShow={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                member={selectedMember}
                onDelete={onDeleteMember}
            />
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Email Address</Table.HeaderCell>
                    <Table.HeaderCell>Role</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data &&
                    data.team.members.map((member) => {
                        const isSelf = member.userId === user.id
                        return (
                            <Table.Row key={member.userId}>
                                <Table.Cell>{member.user.email}</Table.Cell>
                                <Table.Cell>{member.role}</Table.Cell>
                                <Table.Cell>
                                    <div>
                                        <Popup
                                            content="Modify role"
                                            trigger={
                                                <Button
                                                    icon
                                                    disabled={isSelf}
                                                    onClick={() =>
                                                        onUpdateMember(member)
                                                    }
                                                >
                                                    <Icon name="edit" />
                                                </Button>
                                            }
                                        />
                                        <Popup
                                            content="Remove user"
                                            trigger={
                                                <Button
                                                    icon
                                                    disabled={isSelf}
                                                    onClick={() => {
                                                        setSelectedMember(
                                                            member
                                                        )
                                                        setShowDeleteModal(true)
                                                    }}
                                                >
                                                    <Icon name="user times" />
                                                </Button>
                                            }
                                        />
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
            </Table.Body>
        </Table>
    )
}

export default MemberTable
