import React, { useContext } from 'react'
import { Grid } from 'semantic-ui-react'
import { useWindowDimensions } from '../../../../utils/helpers'
import { ExtractionReviewContext } from '../../../../Context'
import HistoryItem from './HistoryItem'
import PanelHeader from '../PanelHeader'

const History = ({ panelHeight }) => {
    const { history, undoExtraction } = useContext(ExtractionReviewContext)

    const { height } = useWindowDimensions()

    return (
        <Grid.Column className="panel-section-col fullwidth">
            <PanelHeader label="HISTORY" />
            <div
                className="panel-section-subcol fullwidth"
                style={{
                    maxHeight: height - panelHeight - 65.406, // offset of 65.406px to make it fit
                    gap: 0,
                }}
            >
                {history.map((h, index) => {
                    return (
                        <HistoryItem
                            key={index}
                            label={h.extraction.tag}
                            auto={h.auto}
                            category={h.category}
                            decision={h.decision}
                            undoExtraction={() => undoExtraction(index)}
                        />
                    )
                })}
            </div>
        </Grid.Column>
    )
}

export default History
