import React, { useState, useContext } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Button, Statistic, Icon, Popup } from 'semantic-ui-react'
import Alert from '../../Alert'
import ProcessModal from '../../Modals/ProcessModal'
import { LOAD_EXPORT_MANAGE_ADVISORS_DATA } from '../../../graphql/project'
import { UPDATE_JOB_MUTATION, DELETE_JOB_MUTATION } from '../../../graphql/job'
import { SingleProjectContext } from '../../../Context'
import { exportManageAdvisors } from '../../../utils/helpers'
import CancelRequestModal from '../../Modals/ProjectInvite/CancelRequestModal'
import SuccessModal from '../../Modals/SuccessModal'
import './styles.css'

const InviteToProject = ({
    selectedAdvisors,
    setSelectedAdvisors,
    setShowModal,
    onSelectAll,
    isSelectAll,
    setIsSelectAll,
    jobs,
}) => {
    const { project, refetch } = useContext(SingleProjectContext)

    const [adding, setAdding] = useState(false)
    const [successMsg, setSuccessMsg] = useState(null)
    const [showCancelRequestModal, setShowCancelRequestModal] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')

    const [loadExportData] = useLazyQuery(LOAD_EXPORT_MANAGE_ADVISORS_DATA)
    const [updateJob] = useMutation(UPDATE_JOB_MUTATION)
    const [deleteJob] = useMutation(DELETE_JOB_MUTATION)

    const cancelRequest = () => {
        let hasAccepted = false
        setAdding(true)

        let promises = []
        let numOfInvites = 0
        let numOfPending = 0
        selectedAdvisors.forEach((advisor) => {
            const jobData = jobs.filter(
                (job) => job.user && job.user.id === advisor.id
            )[0]
            if (
                jobData.response_status === 'Pending' &&
                jobData.overall_status === 'Pending Scheduling'
            ) {
                if (jobData.invite_status === 'Invited') {
                    promises.push(
                        updateJob({
                            variables: {
                                userId: advisor.id,
                                projectId: project.id,
                                job: {
                                    overall_status: 'Expired',
                                },
                            },
                        })
                    )
                    numOfInvites++
                } else {
                    promises.push(
                        deleteJob({
                            variables: { id: jobData.id },
                        })
                    )
                    numOfPending++
                }
            } else {
                hasAccepted = true
                setAdding(false)
                setShowAlert(true)
                setAlertHeader('Reminder')
                setAlertBody(
                    'You cannot uninvite experts that have accepted/declined the invite or have completed the job.'
                )
                return false
            }
        })

        Promise.all(promises).then(() => {
            if (!hasAccepted) refetch()
            setAdding(false)
            setSuccessMsg({
                title: `Successfully removed ${
                    numOfInvites + numOfPending
                } experts from the project`,
                content: `We have removed ${numOfPending} experts from the 'Manage advisors' tab and
                    expired ${numOfInvites} pending invitations`,
            })
            setShowCancelRequestModal(false)
            setSelectedAdvisors([])
            setIsSelectAll(false)
        })
    }

    // button's styling properties
    const buttonPositionStyle = {
        icon: true,
        size: 'tiny',
        labelPosition: 'left',
        style: { width: '100%', marginBottom: '5px' },
    }

    const renderPreviewInviteButton = () => {
        if (selectedAdvisors.length === 0 || project.status !== 'Active') {
            return (
                <Popup
                    trigger={
                        <Button
                            {...buttonPositionStyle}
                            style={{ opacity: 0.45, cursor: 'default' }}
                        >
                            <Icon name="add square" />
                            Preview invites
                        </Button>
                    }
                    position="left center"
                    style={{ left: '80px' }}
                    content="You cannot send project invites when the status is in Draft or Completed mode"
                />
            )
        }
        return (
            <Popup
                trigger={
                    <Button
                        {...buttonPositionStyle}
                        onClick={() => setShowModal(true)}
                    >
                        <Icon name="add square" />
                        Preview invites
                    </Button>
                }
                position="left center"
                style={{ left: '80px' }}
                content="Click here to send a personalized e-mail to an expert"
            />
        )
    }

    return (
        <div className="add-to-project">
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <ProcessModal onShow={adding} content="Processing.." />
            <CancelRequestModal
                onShow={showCancelRequestModal}
                onHide={() => setShowCancelRequestModal(false)}
                project={project}
                numOfSelectedExpert={selectedAdvisors.length}
                cancelRequest={cancelRequest}
            />
            {successMsg && (
                <SuccessModal
                    title={successMsg.title}
                    content={successMsg.content}
                    onHide={() => setSuccessMsg(null)}
                />
            )}
            <Statistic className="title">
                <Statistic.Value>{selectedAdvisors.length} </Statistic.Value>
                <Statistic.Label>Advisors selected</Statistic.Label>
            </Statistic>
            <br />
            {renderPreviewInviteButton()}
            <br />
            <Popup
                trigger={
                    <Button
                        {...buttonPositionStyle}
                        disabled={selectedAdvisors.length === 0}
                        onClick={() => setShowCancelRequestModal(true)}
                    >
                        <Icon name="remove circle" />
                        Remove from project
                    </Button>
                }
                position="left center"
                style={{ left: '80px' }}
                content={`
                    Clicking on this button will expire invitations for anyone who was invited but has not responded
                    while it will simply remove those who have not been invited from the 'Manage Advisors' tab.
                    It will not have any effect on experts who have already responded.
                    Note that it will not send any notifications to the experts
                `}
            />
            <br />
            <Button
                primary
                {...buttonPositionStyle}
                onClick={() => onSelectAll(project)}
                className={isSelectAll ? '' : 'basic'}
            >
                <Icon name="check" />
                {isSelectAll ? 'Unselect all' : 'Select all'}
            </Button>
            <br />
            <Button
                {...buttonPositionStyle}
                basic
                color="green"
                onClick={() => {
                    loadExportData({
                        variables: { id: project.id },
                        onCompleted: (data) =>
                            exportManageAdvisors(data.project.jobs),
                    })
                }}
            >
                <Icon name="file excel" />
                Export sourcing sheet
            </Button>
        </div>
    )
}

export default InviteToProject
