import React from 'react'
import { List, Segment } from 'semantic-ui-react'
import { FIELDS } from './fields'
import renderOrgField from './renderOrgField'

const InfoBox = ({ organization }) => {
    if (organization) {
        return (
            <Segment>
                <List>
                    {FIELDS.map((field) => {
                        return (
                            <List.Item key={field}>
                                <List.Header>{field}:</List.Header>
                                {renderOrgField(field, organization)}
                            </List.Item>
                        )
                    })}
                </List>
            </Segment>
        )
    } else return null
}

export default InfoBox
