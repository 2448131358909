// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-section {
  width: 60% !important;
  margin: 0 auto;
  margin-top: 5%;
}

.project-section a {
  color: black;
}

.project-section .ui.card,
.ui.cards > .card {
  width: 100%;
}

.ui.cards {
  display: block;
  clear: both;
}

.ui.message .header {
  margin-bottom: 5px !important;
}

.progress {
  height: auto !important;
  overflow: visible !important;
}

.ui.progress .bar > .progress {
  background: none;
}

.project-section .project-cards .ui.card,
.ui.cards > .card {
  width: auto !important;
  height: auto !important;
  margin: 10px;
  padding: 10px;
}

.ui.card .meta,
.ui.cards > .card .meta {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}

.project-section .project-cards {
  margin-bottom: 0 !important;
}

.project-cards .function-btn {
  float: right;
}

.project-cards .dropdown-toggle::after {
  display: none;
}

.project-cards .dropdown-item i {
  margin-right: 5px;
}

.project-sort-by {
  float: right;
}

.load-more {
  cursor: pointer;
  margin-left: 40%;
  margin-top: 50px;
  margin-bottom: 100px;
  font-weight: bold;
}

@media only screen and (max-width: 1000px) {
  .project-section {
    margin: 10px;
  }

  .ui.tabular.menu .item {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Projects/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,uBAAuB;EACvB,4BAA4B;AAC9B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,aAAa;AACf;;AAEA;;EAEE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".project-section {\n  width: 60% !important;\n  margin: 0 auto;\n  margin-top: 5%;\n}\n\n.project-section a {\n  color: black;\n}\n\n.project-section .ui.card,\n.ui.cards > .card {\n  width: 100%;\n}\n\n.ui.cards {\n  display: block;\n  clear: both;\n}\n\n.ui.message .header {\n  margin-bottom: 5px !important;\n}\n\n.progress {\n  height: auto !important;\n  overflow: visible !important;\n}\n\n.ui.progress .bar > .progress {\n  background: none;\n}\n\n.project-section .project-cards .ui.card,\n.ui.cards > .card {\n  width: auto !important;\n  height: auto !important;\n  margin: 10px;\n  padding: 10px;\n}\n\n.ui.card .meta,\n.ui.cards > .card .meta {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.4);\n}\n\n.project-section .project-cards {\n  margin-bottom: 0 !important;\n}\n\n.project-cards .function-btn {\n  float: right;\n}\n\n.project-cards .dropdown-toggle::after {\n  display: none;\n}\n\n.project-cards .dropdown-item i {\n  margin-right: 5px;\n}\n\n.project-sort-by {\n  float: right;\n}\n\n.load-more {\n  cursor: pointer;\n  margin-left: 40%;\n  margin-top: 50px;\n  margin-bottom: 100px;\n  font-weight: bold;\n}\n\n@media only screen and (max-width: 1000px) {\n  .project-section {\n    margin: 10px;\n  }\n\n  .ui.tabular.menu .item {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
