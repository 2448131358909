export const PROJECT_SORT_OPTIONS = [
    { key: 'Start Date', text: 'Start Date', value: 'Start Date' },
    { key: 'End Date first', text: 'Ending First', value: 'End Date first' },
    { key: 'End Date last', text: 'Ending Last', value: 'End Date last' },
]

export const PROJECT_TYPE_OPTIONS = [
    { key: 'Interview', text: 'Interview', value: 'Interview' },
    { key: 'Survey', text: 'Survey', value: 'Survey' },
]

export const ADVISORS_SORT_OPTIONS = [
    {
        key: 'Highest response rate',
        text: 'Highest response rate',
        value: 'Highest response rate',
    },
    {
        key: 'Newest join date',
        text: 'Newest join date',
        value: 'Newest join date',
    },
    {
        key: 'Most engagements',
        text: 'Most engagements',
        value: 'Most engagements',
    },
    { key: 'Highest fee', text: 'Highest hourly rate', value: 'Highest fee' },
    { key: 'Lowest fee', text: 'Lowest hourly rate', value: 'Lowest fee' },
    { key: 'Highest rating', text: 'Highest rating', value: 'Highest rating' },
]

export const ADVISORS_SORT_FOR_CLIENT_OPTIONS = [
    {
        key: 'Highest response rate',
        text: 'Highest response rate',
        value: 'Highest response rate',
    },
    {
        key: 'Newest join date',
        text: 'Newest join date',
        value: 'Newest join date',
    },
    {
        key: 'Most engagements',
        text: 'Most engagements',
        value: 'Most engagements',
    },
    { key: 'Highest fee', text: 'Highest fee', value: 'Highest fee' },
    { key: 'Lowest fee', text: 'Lowest fee', value: 'Lowest fee' },
]

export const MANAGE_ADVISORS_SORT_OPTIONS = [
    {
        key: 'Highest response rate',
        text: 'Highest response rate',
        value: 'Highest response rate',
    },
    { key: 'Highest fee', text: 'Highest hourly rate', value: 'Highest fee' },
    { key: 'Lowest fee', text: 'Lowest hourly rate', value: 'Lowest fee' },
]

export const MANAGE_ADVISORS_FILTER_OPTIONS = [
    { key: 'Invited', text: 'Invited', value: 'Invited' },
    { key: 'Not Invited', text: 'Not Invited', value: 'Not Invited' },
    { key: 'Pending', text: 'Response pending', value: 'Pending' },
    { key: 'Accepted', text: 'Accepted', value: 'Accepted' },
    { key: 'Declined', text: 'Declined', value: 'Declined' },
    { key: 'Incomplete', text: 'Incomplete', value: 'Incomplete' },
    { key: 'Completed', text: 'Completed', value: 'Completed' },
    { key: 'Paid', text: 'Paid', value: 'Paid' },
    { key: 'Not Paid', text: 'Not Paid', value: 'Not Paid' },
]

export const EXPERT_PROFILE_PROJECT_SORT_OPTIONS = [
    { key: 'Newest first', value: 'Newest first', text: 'Newest first' },
    { key: 'Highest rated', value: 'Highest rated', text: 'Highest rated' },
    { key: 'Lowest rated', value: 'Lowest rated', text: 'Lowest rated' },
]

export const EMAIL_TEMPLATE_CATEGORY_OPTIONS = [
    {
        key: 'New Project Invite',
        value: 'New Project Invite',
        text: 'New Project Invite',
    },
    {
        key: 'Project Invite Reminder',
        value: 'Project Invite Reminder',
        text: 'Project Invite Reminder',
    },
    {
        key: 'Dexter Onboarding',
        value: 'Dexter Onboarding',
        text: 'Dexter Onboarding',
    },
    { key: 'Other', value: 'Other', text: 'Other' },
]
