// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/fonts/SofiaProLight.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: SofiaProLight;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}

#root {
  width: 100%;
  height: 100%;
}

html,
body {
  font-family: SofiaProLight !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: SofiaProLight !important;
}

h1 {
  font-size: 36px;
}

h2 {
  color: hsl(90, 14%, 11%) !important;
}

h3 {
  color: hsl(90, 14%, 11%, 0.7) !important;
}

.site-wrap {
  width: inherit;
  height: inherit;
}

.fullscreen {
  width: inherit;
  height: inherit;
  overflow: hidden;
}

.main {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 1px;
}

.side-panel {
  padding: 5% !important;
  padding-left: 10% !important;
}

.span-link {
  cursor: pointer;
  text-decoration: underline;
}

/*=================
	windows resize
==================*/
@media only screen and (max-width: 768px) {
  .main {
    width: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,+DAAgE;AAClE;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;;EAEE,qCAAqC;AACvC;;AAEA;;;;;EAKE,qCAAqC;AACvC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,0BAA0B;AAC5B;;AAEA;;mBAEmB;AACnB;EACE;IACE,WAAW;EACb;AACF","sourcesContent":["@font-face {\n  font-family: SofiaProLight;\n  src: url('../public/fonts/SofiaProLight.otf') format('opentype');\n}\n\n#root {\n  width: 100%;\n  height: 100%;\n}\n\nhtml,\nbody {\n  font-family: SofiaProLight !important;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5 {\n  font-family: SofiaProLight !important;\n}\n\nh1 {\n  font-size: 36px;\n}\n\nh2 {\n  color: hsl(90, 14%, 11%) !important;\n}\n\nh3 {\n  color: hsl(90, 14%, 11%, 0.7) !important;\n}\n\n.site-wrap {\n  width: inherit;\n  height: inherit;\n}\n\n.fullscreen {\n  width: inherit;\n  height: inherit;\n  overflow: hidden;\n}\n\n.main {\n  width: 90%;\n  margin: 0 auto;\n  padding-bottom: 1px;\n}\n\n.side-panel {\n  padding: 5% !important;\n  padding-left: 10% !important;\n}\n\n.span-link {\n  cursor: pointer;\n  text-decoration: underline;\n}\n\n/*=================\n\twindows resize\n==================*/\n@media only screen and (max-width: 768px) {\n  .main {\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
