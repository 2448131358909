/* eslint-disable react/prop-types */
import React from 'react'
import moment from 'moment'
import { Table } from 'semantic-ui-react'

const PaymentTable = ({ headerName, tableData }) => {
    return (
        <Table celled sortable>
            <Table.Header>
                <Table.Row>
                    {headerName.map((header, index) => {
                        return (
                            <Table.HeaderCell
                                key={index}
                                className="table-header"
                            >
                                {header.name}
                            </Table.HeaderCell>
                        )
                    })}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {tableData.map((data, tableIndex) => {
                    return (
                        <Table.Row key={tableIndex}>
                            {headerName.map((header, headerIndex) => {
                                let result = data[header.field]
                                if (header.field === 'createdAt')
                                    result = moment(result).format('l')
                                else if (header.field === 'amount')
                                    result = `$${result}.00`
                                return (
                                    <Table.Cell
                                        key={headerIndex}
                                        collapsing
                                        className="table-cell"
                                    >
                                        <div>{result}</div>
                                    </Table.Cell>
                                )
                            })}
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
    )
}

export default PaymentTable
