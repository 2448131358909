export const DECISIONS = [
    { label: 'Title', key: 'title', shortcut: 'A' },
    { label: 'Organization', key: 'organization', shortcut: 'S' },
    { label: 'Medical Specialty', key: 'medical_specialties', shortcut: 'D' },
    { label: 'Technology', key: 'technology_tags', shortcut: 'F' },
    { label: 'Education', key: 'education', shortcut: 'Z' },
    { label: 'Function', key: 'function', shortcut: 'X' },
    {
        label: 'Reject',
        key: 'rejected',
        helper: 'Invalid extraction',
        shortcut: 'R',
    },
    { label: 'Ignore', key: 'ignored', helper: null, shortcut: 'L' },
]

export const AUTO_THRESHOLD = 0.95
export const CATEGORY_THRESHOLD = 0.75
