import React from 'react'
import { Grid } from 'semantic-ui-react'
import AdminRoutes from '../components/Admin/AdminRoutes'
import AdminMenu from '../components/Admin/AdminMenu'

const Admin = () => {
    return (
        <Grid>
            <Grid.Column>
                <AdminMenu />
                <AdminRoutes />
            </Grid.Column>
        </Grid>
    )
}

export default Admin
