import React, { useContext } from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import {
    ApolloProvider,
    ApolloClient,
    InMemoryCache,
    from,
    HttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import cleanTypenameLink from './utils/cleanTypenameLink'
import AppHeader from './components/AppHeader'
import SingleProject from './views/SingleProject'
import Projects from './views/Projects'
import NewProject from './views/NewProject'
import Payment from './views/Payments'
import Advisors from './views/Advisors'
import Tutorial from './views/Tutorial'
import Admin from './views/Admin'
import ExtractionReview from './views/ExtractionReview'
import FirstTimeSignupPage from './views/FirstTimeSignupPage'
import FAQ from './views/FAQ'
import Landing from './views/Landing'
import NoMatch from './components/NotFoundPage'
import AuthContext from './contexts/AuthContext'
import DataContext, { DataProvider } from './contexts/DataContext'

const AdminRoute = ({ ...props }) => {
    const { user } = useContext(DataContext)
    if (!user.isSuperUser()) return <Redirect to="/" />
    return <Route {...props} />
}

const guestUserPages = (
    <Switch>
        <Route path="/" component={Landing} />
    </Switch>
)

const loggedInUserPages = (
    <DataProvider>
        <AppHeader />
        <Switch>
            <Route exact path="/" render={() => <Redirect to="/projects" />} />
            <Route exact path="/payments" component={Payment} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/projects/new-project" component={NewProject} />
            <Route exact path="/projects/:type" component={Projects} />
            <Route exact path="/projects/:id" component={SingleProject} />
            <Route exact path="/projects/:id/:page" component={SingleProject} />
            <Route exact path="/tutorial" component={Tutorial} />
            <AdminRoute exact path="/admin" component={Admin} />
            <AdminRoute
                exact
                path="/admin/experts/:id/review"
                component={ExtractionReview}
            />
            <AdminRoute path="/admin/team/:id/:type" component={Admin} />
            <AdminRoute path="/admin/:page" component={Admin} />
            <Route exact path="/advisors" component={Advisors} />
            <Route exact path="/faq" component={FAQ} />
            <Route
                exact
                path="/first-time-signup-notice"
                component={FirstTimeSignupPage}
            />
            <Route exact path="/not-found-page" component={NoMatch} />
            <Route component={NoMatch} />
        </Switch>
    </DataProvider>
)

const Routes = () => {
    const { token } = useContext(AuthContext)
    const authLink = setContext(async (_, { headers }) => ({
        headers: { ...headers, ...(token && { authorization: token }) },
    }))
    const httpLink = new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_BASE_URL,
    })

    const apolloClient = new ApolloClient({
        link: from([authLink, cleanTypenameLink, httpLink]),
        cache: new InMemoryCache({
            typePolicies: {
                Project: {
                    merge: true,
                    fields: {
                        calendar: { merge: false },
                    },
                },
            },
        }),
    })

    const getPages = () => {
        if (token) {
            return loggedInUserPages
        }
        return guestUserPages
    }

    const path = window.location.pathname.substring(1).split('/')
    const isExtractionReview =
        path[0] === 'admin' && path[1] === 'experts' && path[3] === 'review'

    return (
        <ApolloProvider client={apolloClient}>
            <BrowserRouter>
                <div className="site-wrap">
                    <div
                        className={
                            isExtractionReview || !token ? 'fullscreen' : 'main'
                        }
                    >
                        {getPages()}
                    </div>
                </div>
            </BrowserRouter>
        </ApolloProvider>
    )
}

export default Routes
