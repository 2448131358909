import React, { useState, useEffect, useContext } from 'react'
import { useMeasure } from 'react-use'
import { Grid, Progress as ProgressBar } from 'semantic-ui-react'
import { round } from '../../../../utils/helpers'
import { ExtractionReviewContext } from '../../../../Context'
import ProgressDecisionBar from './ProgressDecisionBar'
import PanelHeader from '../PanelHeader'
import PanelSubheader from '../PanelSubheader'

const Progress = () => {
    const { datawork, history } = useContext(ExtractionReviewContext)

    const [panelInnerRef, { width: panelInnerWidth }] = useMeasure()

    const [decisionStats, setDecisionStats] = useState({
        accepted: 0,
        rejected: 0,
        ignored: 0,
        total: 0,
    })

    // Calculate number of each decision
    useEffect(() => {
        const temp = {
            accepted: 0,
            rejected: 0,
            ignored: 0,
            total: 0,
        }
        history.forEach((h) => {
            if (h.decision === 'irrelevant' || h.decision === 'duplicate')
                temp['ignored']++
            else temp[h.decision]++
            temp['total']++
        })
        setDecisionStats(temp)
    }, [history])

    const hasHistory = decisionStats.total > 0

    return (
        <Grid.Row className="panel-section">
            <Grid.Column className="panel-section-col fullwidth">
                <PanelHeader label="PROGRESS" />
                <div
                    className="panel-section-subcol fullwidth"
                    ref={panelInnerRef}
                >
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column floated="left" className="fullwidth">
                            <ProgressBar
                                percent={round(
                                    (decisionStats.total /
                                        datawork.tagExtractions.length) *
                                        100
                                )}
                                progress={hasHistory}
                                active={hasHistory}
                                autoSuccess
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <ProgressDecisionBar
                        decisionStats={decisionStats}
                        panelInnerWidth={panelInnerWidth}
                    />
                    <Grid.Row>
                        <PanelSubheader
                            label="ACCEPT"
                            data={decisionStats.accepted}
                            direction="row"
                            labelStyle="text-accepted"
                        />
                        <PanelSubheader
                            label="REJECT"
                            data={decisionStats.rejected}
                            direction="row"
                            labelStyle="text-rejected"
                        />
                        <PanelSubheader
                            label="IGNORE"
                            data={decisionStats.ignored}
                            direction="row"
                            labelStyle="text-ignored"
                        />
                    </Grid.Row>
                </div>
            </Grid.Column>
        </Grid.Row>
    )
}

export default Progress
