/* eslint-disable no-console */
import React, { useState, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Button, Form } from 'semantic-ui-react'
import {
    CREATE_PROJECT_MUTATION,
    UPDATE_PROJECT_MUTATION,
} from '../../../graphql/project'
import { SingleProjectContext } from '../../../Context'
import DataContext from '../../../contexts/DataContext'
import Identity from './Identity'
import Information1 from './Information1'
import Information2 from './Information2'
import Details from './Details'
import Alert from '../../Alert'

const ProjectDetails = () => {
    const { user } = useContext(DataContext)
    const singleProjectContext = useContext(SingleProjectContext)

    const history = useHistory()

    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')
    const [error, setError] = useState(null)

    const [createProject, { loading: creating }] = useMutation(
        CREATE_PROJECT_MUTATION,
        {
            onError: (error) => {
                console.log(error)
                setShowAlert(true)
                setAlertHeader('Error')
                setAlertBody(
                    `You can not create this project: ${
                        error.message.split('GraphQL error: ')[1]
                    }`
                )
            },
        }
    )

    const [updateProject, { loading: updating }] = useMutation(
        UPDATE_PROJECT_MUTATION,
        {
            onError: (error) => {
                console.log(error)
                setShowAlert(true)
                setAlertHeader('Error')
                setAlertBody(
                    `You can not update this project: ${
                        error.message.split('GraphQL error: ')[1]
                    }`
                )
            },
        }
    )

    const identityRef = useRef(null)
    const information1Ref = useRef(null)
    const information2Ref = useRef(null)
    const detailsRef = useRef(null)

    const submitChange = () => {
        if (
            singleProjectContext &&
            singleProjectContext.project.owners &&
            !singleProjectContext.project.owners
                .map((owner) => owner.id)
                .includes(user.id)
        ) {
            setShowAlert(true)
            setAlertHeader('Oops')
            setAlertBody('Only project owners can update project information')
        }

        const { projectTitle, projectOwners } = identityRef.current.state

        const { startDate, endDate, projectType, calendlyLink, surveyLink } =
            information1Ref.current.state

        const { projectCode, targetN, referable } =
            information2Ref.current.state

        const { keywords, description } = detailsRef.current.state
        const projectFieldError = {}

        if (projectTitle.length === 0) {
            projectFieldError.title = 'Please fill out the project title'
        }

        if (projectType.length === 0) {
            projectFieldError.projectType = 'Please fill out the project type.'
        }

        if (targetN < 1) {
            projectFieldError.targetN =
                'The project participant limit can not have less than 1 participant.'
        }
        if (projectCode.length === 0) {
            projectFieldError.code = 'Please fill out the case code.'
        }
        if (projectOwners.length === 0) {
            projectFieldError.owners = 'Please fill out the project owner.'
        }

        if (projectType === 'Survey' && surveyLink.length === 0) {
            projectFieldError.surveyLink =
                'Please fill out the survey link for experts to take your survey.'
        }

        if (JSON.stringify(projectFieldError) !== '{}') {
            setError(projectFieldError)
            return
        }

        const projectInput = {
            team: user.currentTeam,
            type: projectType,
            start_date: startDate,
            end_date: endDate,
            service_type: 'Full-Service',
            targetN: parseInt(targetN),
            status: singleProjectContext
                ? singleProjectContext.project.status
                : 'Draft',
            title: projectTitle,
            calendly_link: calendlyLink,
            surveyLink,
            description,
            code: projectCode,
            owners: projectOwners,
            keywords: keywords.map((obj) => obj.value),
            referable,
        }

        if (singleProjectContext) {
            updateProject({
                variables: {
                    id: singleProjectContext.project.id,
                    input: projectInput,
                },
                update: (cache, { data: { updateProject } }) => {
                    singleProjectContext.setProject(updateProject)
                    history.replace(`/projects/${updateProject.id}/advisors`)
                },
            })
        } else {
            createProject({
                variables: {
                    input: projectInput,
                },
                update: (cache, { data: { createProject } }) => {
                    history.replace(`/projects/${createProject.id}/details`)
                },
            })
        }
        setError(null)
    }

    return (
        <div className="project-details-page">
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <Form>
                <Form.Field style={{ float: 'right' }}>
                    <label>
                        <span style={{ color: 'red' }}>* </span>
                        These fields are shared with the experts
                    </label>
                </Form.Field>
                <Identity
                    project={
                        singleProjectContext ? singleProjectContext.project : {}
                    }
                    error={error}
                    ref={identityRef}
                />
                <Information1
                    project={
                        singleProjectContext ? singleProjectContext.project : {}
                    }
                    error={error}
                    ref={information1Ref}
                />
                <Details
                    project={
                        singleProjectContext ? singleProjectContext.project : {}
                    }
                    error={error}
                    ref={detailsRef}
                />
                <Information2
                    project={
                        singleProjectContext ? singleProjectContext.project : {}
                    }
                    error={error}
                    ref={information2Ref}
                />
            </Form>

            <div className="submit-btn">
                <Button
                    color="green"
                    loading={creating || updating}
                    disabled={
                        singleProjectContext &&
                        singleProjectContext.project.status === 'Completed'
                    }
                    onClick={submitChange}
                >
                    Save
                </Button>
                {!singleProjectContext && (
                    <Button onClick={() => history.push('/projects')}>
                        Cancel
                    </Button>
                )}
            </div>
        </div>
    )
}

export default ProjectDetails
