import React, { useMemo, useState } from 'react'
import moment from 'moment'
import { useQuery, useLazyQuery } from '@apollo/client'
import { Modal } from 'react-bootstrap'
import {
    Form,
    Header,
    FormInput,
    Loader,
    Button,
    Icon,
} from 'semantic-ui-react'
import {
    LATEST_PAYMENT,
    LATEST_CHECK_PAYMENT,
    CHECK_PAYMENT_INFO_FIELDS,
} from '../../../graphql/transaction'

const CheckPaymentInput = ({ job, payment, updatePayment }) => {
    const NON_EDITABLE_FIELDS = useMemo(() => ['Timestamp'], [])
    const { loading } = useQuery(CHECK_PAYMENT_INFO_FIELDS, {
        skip: payment.checkPaymentInfo !== null,
        onCompleted: ({ checkPaymentInfoFields }) => {
            const DEFAULT_CHECK_INFO = checkPaymentInfoFields.reduce(
                (obj, key) => {
                    obj[key] = ''
                    return obj
                },
                {}
            )
            updatePayment({
                checkPaymentInfo: {
                    ...DEFAULT_CHECK_INFO,
                    'Vendor First Name': job.user.firstName,
                    'Vendor Last Name': job.user.lastName,
                },
            })
        },
    })
    const updateCheckPaymentInfo = (key, value) => {
        const updatedPaymentInfo = {
            ...payment.checkPaymentInfo,
            [key]: value,
        }
        updatePayment({ checkPaymentInfo: updatedPaymentInfo })
    }
    if (loading) return <Loader active />
    return (
        <div>
            {payment.checkPaymentInfo &&
                Object.keys(payment.checkPaymentInfo)
                    .filter((key) => !NON_EDITABLE_FIELDS.includes(key))
                    .map((key) => (
                        <FormInput
                            key={key}
                            label={key}
                            value={payment.checkPaymentInfo[key]}
                            type="text"
                            onChange={(e, { value }) => {
                                updateCheckPaymentInfo(key, value)
                            }}
                        />
                    ))}
        </div>
    )
}

const CheckPayment = ({ job, payment, updatePayment, updatePage }) => {
    const NON_EDITABLE_FIELDS = useMemo(() => ['Timestamp'], [])

    const [latestCheckPaymentInfo, setLatestCheckPaymentInfo] = useState(null)

    const [fetchLatestCheckPayment, { loading: loadingLatestCheckPayment }] =
        useLazyQuery(LATEST_CHECK_PAYMENT)

    const { loading: loadingLatestPayment } = useQuery(LATEST_PAYMENT, {
        variables: {
            userId: job.user.id,
        },
        skip: !!job.user.expert.stripe_account,
        onCompleted: ({ latestJobPayment }) => {
            const isCheck = latestJobPayment?.method === 'Check'
            const hasCheckPaymentInfo =
                payment.checkPaymentInfo &&
                Object.values(payment.checkPaymentInfo).some((el) => el !== '')
            if (isCheck && !hasCheckPaymentInfo) {
                fetchLatestCheckPayment({
                    variables: { expertId: job.user.id },
                    // Ensure the data always being fetched without being ignored due to cache
                    fetchPolicy: 'network-only',
                    onCompleted: ({ latestCheckPaymentInfo }) => {
                        const parsedPaymentInfo = JSON.parse(
                            latestCheckPaymentInfo
                        )
                        setLatestCheckPaymentInfo(parsedPaymentInfo)
                        const editableFields = Object.fromEntries(
                            Object.entries(parsedPaymentInfo).filter(
                                ([key]) => !NON_EDITABLE_FIELDS.includes(key)
                            )
                        )
                        updatePayment({ checkPaymentInfo: editableFields })
                    },
                })
            }
        },
    })

    const onNext = () => updatePage('CREDIT')
    const onBack = () => updatePage('PAYMENT')

    if (loadingLatestPayment || loadingLatestCheckPayment)
        return <Loader active />

    return (
        <Modal.Body>
            <Form>
                <Form.Field>
                    <Header as="h6">Check Payment</Header>
                    <div>
                        {latestCheckPaymentInfo &&
                            `Last payment record was on ${moment(
                                latestCheckPaymentInfo['Timestamp']
                            ).format('MM/DD/YY')} for ${job.user.email}`}
                    </div>
                    <CheckPaymentInput
                        job={job}
                        payment={payment}
                        updatePayment={updatePayment}
                    />
                </Form.Field>
            </Form>
            <Modal.Footer>
                <Button icon basic onClick={onBack}>
                    <Icon name="angle left" />
                </Button>
                <Button onClick={onNext}>Next</Button>
            </Modal.Footer>
        </Modal.Body>
    )
}

export default CheckPayment
