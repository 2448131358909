import React from 'react'
import { Grid } from 'semantic-ui-react'

const ResultsCard = ({ category, history }) => {
    const { label, key } = category

    const suggestions = history.filter(
        (h) => h.category === key && h.decision === 'accepted'
    )

    return (
        <Grid.Column className="results-card">
            <Grid.Row verticalAlign="middle" className="results-card-header">
                <Grid.Column floated="left" className="text-ellipsis">
                    {label}
                </Grid.Column>
                <Grid.Column
                    floated="right"
                    className={`text-nowrap ${
                        suggestions.length > 0 ? 'text-active' : ''
                    }`}
                >
                    +{suggestions.length}
                </Grid.Column>
            </Grid.Row>
            <div className={`${suggestions.length > 0 ? 'text-active' : ''}`}>
                {suggestions.length > 0
                    ? suggestions.map((s) => s.extraction.tag).join('; ')
                    : 'None'}
            </div>
        </Grid.Column>
    )
}

export default ResultsCard
