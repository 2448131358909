import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import ProcessSuccess from './ProcessSuccess'
import PaymentInfo from './PaymentInfo'
import Confirm from './Confirm'
import { lowerCase } from 'lodash'
import './style.css'

const FullServicePayment = ({ onHide, job }) => {
    const [payment, setPayment] = useState({
        type: 'Job Payment',
        amount: job.user.expert.hourlyRate,
        description: `$${job.user.expert.hourlyRate} for ${lowerCase(
            job.project.type
        )}`,
        method: job.user.expert.stripe_account ? 'Stripe' : null,
        additionalMessage: null,
    })
    const [modalPage, setModalPage] = useState('PAYMENT')

    const closeModal = () => {
        setModalPage('PAYMENT')
        onHide()
    }

    const updatePayment = (data) => setPayment({ ...payment, ...data })
    const updatePage = (page) => (page ? setModalPage(page) : closeModal())

    const PAGES = {
        PAYMENT: (
            <PaymentInfo
                job={job}
                payment={payment}
                updatePayment={updatePayment}
                updatePage={updatePage}
            />
        ),
        CONFIRM: (
            <Confirm job={job} payment={payment} updatePage={updatePage} />
        ),
        SUCCESS: <ProcessSuccess />,
    }

    return (
        <Modal show onHide={closeModal} centered style={{ zIndex: 9999 }}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {`${job.user.fullName || job.user.email} | ${
                        job.project.title
                    }`}
                </Modal.Title>
            </Modal.Header>
            {PAGES[modalPage]}
        </Modal>
    )
}

export default FullServicePayment
