/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Button } from 'semantic-ui-react'
import moment from 'moment'
import Payment from '../../../Modals/Payment'
import FullServicePayment from '../../../Modals/FullServicePayment'
import { LOAD_MANAGED_JOB_QUERY } from '../../../../graphql/job'

const PayButtons = ({ job }) => {
    const [showPayment, setShowPayment] = useState(false)

    const { data, loading } = useQuery(LOAD_MANAGED_JOB_QUERY, {
        variables: { id: job.id },
    })

    if (loading)
        return <Button size="mini" floated="right" disabled color="red" />

    const renderPaidText = () => (
        <span style={{ float: 'right' }}>
            {`Paid $ ${data.job.payment.amount} on ${moment(
                data.job.payment.createdAt
            ).format('MM/DD/YY')} via ${data.job.payment.method}`}
        </span>
    )

    const renderPayButton = () => (
        <Button
            size="mini"
            floated="right"
            color="red"
            onClick={() => {
                setShowPayment(true)
            }}
        >
            {data.job.user.expert.stripe_account
                ? 'Pay'
                : 'Record external payment'}
        </Button>
    )

    const renderPayment = () => {
        if (job.project.service_type === 'Self-Service') {
            return (
                <Payment job={data.job} onHide={() => setShowPayment(false)} />
            )
        }
        return (
            <FullServicePayment
                job={data.job}
                onHide={() => setShowPayment(false)}
            />
        )
    }

    return (
        <Fragment>
            {showPayment && renderPayment()}
            {data.job.overall_status === 'Paid'
                ? renderPaidText()
                : renderPayButton()}
        </Fragment>
    )
}

export default PayButtons
