import React, { Fragment } from 'react'
import { Divider, Header } from 'semantic-ui-react'

const getStarted = () => (
    <Fragment>
        <Header as="h2" name="get-started">
            How do I get started?
        </Header>
        <Header as="h3">1. Create a project</Header>
        Create a new project and provide information to clearly communincate
        your research scope and timeline
        <Header as="h3">2. Find an expert</Header>
        Browse our library of experts to find the right ones for your unique
        questions
        <Header as="h3">3. Request a call</Header>
        When you request a call, your proejct will first need to be reviewed and
        approved by Dexter staff. Note that the fields that are shared with the
        experts (marked with a red asterisk{' '}
        <span style={{ color: 'red' }}>*</span>) will not be editable after this
        point. The review process typically takes 1-2 days. During this time, QC
        steps will be taken to make sure the project is set up correctly and all
        necessary information has been provided. Once approved, requests will be
        automatically sent to the selected experts.
        <Header as="h3">4. Connect Directly</Header>
        Monitor in real-time the status of each request from the project page.
        The details for any upcoming meetings, including the conferene call link
        and date/time, will be sent to you via email and will become available
        in the Home page. After the call, credit(s) will be debited from your
        account based on the expert rate and the call length.
        <Header as="h3">5. Close a project</Header>
        Calls can be requested on demand on any approved projects as permitted
        by your credit balance. When you are done with a project, you can close
        the project by marking it complete.
        <Divider />
    </Fragment>
)

export default getStarted
