import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Modal } from 'react-bootstrap'
import { Rating, Form, TextArea, Button } from 'semantic-ui-react'
import { LOAD_PROJECT_JOBS } from '../../graphql/project'
import { UPDATE_JOB_MUTATION } from '../../graphql/job'
import { LOAD_PAST_MEETINGS_QUERY } from '../../graphql/meeting'
import { SingleProjectContext } from '../../Context'

const ReviewModal = ({ onShow, onHide, project, job }) => {
    const singleProjectContext = useContext(SingleProjectContext)
    const [advisorRating, setAdvisorRating] = useState(0)
    const [advisorComment, setAdvisorComment] = useState('')

    const [updateJob] = useMutation(UPDATE_JOB_MUTATION, {
        refetchQueries: [
            {
                query: LOAD_PAST_MEETINGS_QUERY,
            },
            {
                query: LOAD_PROJECT_JOBS,
                variables: { id: project.id },
            },
        ],
        awaitRefetchQueries: true,
    })

    const onSubmit = () => {
        updateJob({
            variables: {
                userId: job.expert.id,
                projectId: project.id,
                job: {
                    expert_comment: advisorComment,
                    expert_rating: advisorRating,
                },
            },
            update: (cache, { data: { updateJob } }) => {
                if (singleProjectContext) {
                    singleProjectContext.setProject(updateJob.project)
                }

                onHide()
            },
        })
    }

    return (
        <Modal show={onShow} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Advisor review</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="field">
                    <label>Rating</label>
                    <Rating
                        icon="star"
                        defaultRating={advisorRating}
                        maxRating={5}
                        style={{ marginLeft: '5px' }}
                        onRate={(e, { rating }) => setAdvisorRating(rating)}
                    />
                </div>
                <br />
                <div className="field">
                    <Form>
                        <TextArea
                            row="3"
                            onChange={(e, { value }) =>
                                setAdvisorComment(value)
                            }
                            placeholder="Enter your comment on this advisor"
                        />
                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onSubmit}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ReviewModal
