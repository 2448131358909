import React, { useState, useEffect } from 'react'
import { Grid } from 'semantic-ui-react'

const ProgressDecisionBar = ({ decisionStats, panelInnerWidth }) => {
    const [progressWidths, setProgressWidths] = useState({
        none: panelInnerWidth,
        accepted: 0,
        rejected: 0,
        ignored: 0,
    })

    // Calculate progress bar widths
    useEffect(() => {
        const temp = {
            none: panelInnerWidth,
            accepted: 0,
            rejected: 0,
            ignored: 0,
        }
        if (decisionStats.total === 0) {
            temp.none = panelInnerWidth
            temp.accepted = 0
            temp.rejected = 0
            temp.ignored = 0
        } else {
            temp.none = 0
            temp.accepted =
                (decisionStats.accepted / decisionStats.total) * panelInnerWidth
            temp.rejected =
                (decisionStats.rejected / decisionStats.total) * panelInnerWidth
            temp.ignored =
                (decisionStats.ignored / decisionStats.total) * panelInnerWidth
        }
        setProgressWidths(temp)
    }, [decisionStats, panelInnerWidth])

    const hasHistory = decisionStats.total > 0

    return (
        <Grid.Row verticalAlign="middle" className="progress-decision">
            <Grid.Column
                className="progress-decision progress-none"
                style={{
                    display: hasHistory ? 'none' : 'block',
                    width: progressWidths.none,
                }}
            />
            <Grid.Column
                className="progress-decision bg-accepted"
                style={{
                    display:
                        hasHistory && decisionStats.accepted > 0
                            ? 'block'
                            : 'none',
                    width: progressWidths.accepted,
                }}
            />
            <Grid.Column
                className="progress-decision bg-rejected"
                style={{
                    display:
                        hasHistory && decisionStats.rejected > 0
                            ? 'block'
                            : 'none',
                    width: progressWidths.rejected,
                }}
            />
            <Grid.Column
                className="progress-decision bg-ignored"
                style={{
                    display:
                        hasHistory && decisionStats.ignored > 0
                            ? 'block'
                            : 'none',
                    width: progressWidths.ignored,
                }}
            />
        </Grid.Row>
    )
}

export default ProgressDecisionBar
