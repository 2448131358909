import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'

const ConfirmDeleteMemberModal = ({ onShow, onHide, member, onDelete }) => {
    return (
        <Modal show={onShow} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Delete member</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Are you sure to remove{' '}
                    {member.user ? member.user.email : null} from membership ?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    negative
                    onClick={() => {
                        onDelete(member)
                        onHide()
                    }}
                >
                    Yes
                </Button>
                <Button onClick={onHide}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmDeleteMemberModal
