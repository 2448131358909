/* eslint-disable react/prop-types */
import React from 'react'
import { Card, Image, Flag, Popup, Label, Button } from 'semantic-ui-react'
import DefaultCard from './DefaultCard'
import ManageAdvisorCard from './ManageAdvisorCard'
import SocialIcon from '../../Resources/SocialIcon'
import countries from '../../../constants/countries.json'

const AdvisorCard = ({
    advisor,
    setUserId,
    selectedAdvisors,
    onSelect,
    setShowSidebar,
    type,
    job,
}) => {
    const checkInfoEmpty = (info) =>
        !info || info === '(none)' || info === '(unknown)'

    const renderSelectButton = () => {
        if (advisor.expert.status !== 'Active' || advisor.expert.isBlocked) {
            return (
                <Popup
                    position="top right"
                    trigger={
                        <Button
                            color="blue"
                            basic
                            floated="right"
                            size="mini"
                            style={{ opacity: 0.45, cursor: 'default' }}
                        >
                            Select
                        </Button>
                    }
                    content="You cannot invite an expert whose status is 'Do Not Contact' (those who have opted out of receiving email communications from Dexter) or 'Blocked' (those who have been restricted from all Dexter engagements due to violations of user terms and agreements)."
                />
            )
        }
        return (
            <Button
                color="blue"
                floated="right"
                size="mini"
                className={
                    selectedAdvisors
                        .map((advisor) => advisor.id)
                        .includes(advisor.id)
                        ? null
                        : 'basic'
                }
                onClick={() => onSelect(advisor)}
            >
                {selectedAdvisors
                    .map((advisor) => advisor.id)
                    .includes(advisor.id)
                    ? 'Selected'
                    : 'Select'}
            </Button>
        )
    }

    return (
        <Card>
            <Card.Content>
                <Image
                    floated="left"
                    size="mini"
                    className="expert_profile"
                    src={
                        advisor.expert.profile_picture ||
                        '/logos/fake_avatar.png'
                    }
                />
                {(type === 'SELECT_ADVISOR' || type === 'MANAGE_ADVISOR') &&
                    renderSelectButton()}
                <SocialIcon advisor={advisor} />
                <Card.Header>
                    <a
                        href="# "
                        className="expert_name_link"
                        onClick={(e) => {
                            e.preventDefault()
                            setShowSidebar(true)
                            setUserId(advisor.id)
                        }}
                    >
                        {advisor.fullName || advisor.email}
                        {checkInfoEmpty(advisor.expert.country) ? (
                            ''
                        ) : (
                            <Popup
                                content={advisor.expert.country}
                                trigger={
                                    <Flag
                                        style={{ marginLeft: '5px' }}
                                        name={countries[advisor.expert.country]}
                                    />
                                }
                            />
                        )}
                    </a>
                    {advisor.expert.isBlocked && (
                        <Popup
                            trigger={
                                <Label
                                    color="black"
                                    horizontal
                                    style={{ marginLeft: '5px' }}
                                >
                                    Blocked
                                </Label>
                            }
                            content="This expert has been restricted from all Dexter engagements due to violations of user terms and agreements"
                        />
                    )}
                    {advisor.expert.status === 'Do Not Contact' && (
                        <Popup
                            trigger={
                                <Label horizontal style={{ marginLeft: '5px' }}>
                                    Do Not Contact
                                </Label>
                            }
                            content="This expert has opted out of receiving email communications from Dexter"
                        />
                    )}
                </Card.Header>
                <Card.Meta>
                    {checkInfoEmpty(advisor.expert.title)
                        ? ''
                        : advisor.expert.title}{' '}
                    {checkInfoEmpty(advisor.expert.title) ||
                    checkInfoEmpty(advisor.expert.organization.name)
                        ? ''
                        : ','}{' '}
                    {checkInfoEmpty(advisor.expert.organization.name)
                        ? ''
                        : advisor.expert.organization.name}
                </Card.Meta>
                <Card.Description className="card-description">
                    {type === 'MANAGE_ADVISOR' ? (
                        <ManageAdvisorCard advisor={advisor} job={job} />
                    ) : (
                        <DefaultCard advisor={advisor} />
                    )}
                </Card.Description>
            </Card.Content>
        </Card>
    )
}

export default AdvisorCard
