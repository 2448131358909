import React, { useState, useEffect } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

const TeamMenu = ({ match, history }) => {
    const { id } = useParams()

    const [activeItem, setActiveItem] = useState('activity')

    useEffect(() => {
        setActiveItem(match.url.split('/')[4] || 'credits')
    }, [match.url])

    const onNavigate = (e, { name }) => {
        setActiveItem(name)
        if (name === 'activity') history.push(`/admin/teams/${id}/credits`)
        else history.push(`/admin/teams/${id}/${name}`)
    }

    return (
        <Menu tabular>
            <Menu.Item
                name="credits"
                active={activeItem === 'credits'}
                onClick={onNavigate}
            >
                Credits
            </Menu.Item>
            <Menu.Item
                name="members"
                active={activeItem === 'members'}
                onClick={onNavigate}
            >
                Members
            </Menu.Item>
        </Menu>
    )
}

export default withRouter(TeamMenu)
