import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Input, Button } from 'semantic-ui-react'
import { ProjectContext } from '../Context'
import ProjectMenu from '../components/Projects/ProjectMenu'
import ProjectRoutes from '../components/Projects/ProjectRoutes'
import '../components/Projects/styles.css'

const Projects = () => {
    const history = useHistory()

    const [query, setQuery] = useState('')
    const [sortBy, setSortBy] = useState({ createdAt: 'desc' })
    const [myProjectsOnly, setMyProjectsOnly] = useState(true)

    return (
        <div className="project-section">
            <Input
                icon="search"
                placeholder="Search for a project"
                onChange={(e, { value }) => setQuery(value)}
            />
            <Button
                primary
                floated="right"
                onClick={() => history.push('/projects/new-project')}
            >
                New Project
            </Button>

            <div style={{ marginTop: '5%' }}>
                <ProjectContext.Provider
                    value={{
                        query,
                        sortBy,
                        setSortBy,
                        myProjectsOnly,
                        setMyProjectsOnly,
                    }}
                >
                    <ProjectMenu />
                    <ProjectRoutes />
                </ProjectContext.Provider>
            </div>
        </div>
    )
}

export default Projects
