import React from 'react'
import { Modal } from 'react-bootstrap'

const ProcessModal = ({ onShow, content }) => (
    <Modal show={onShow} size="sm" centered>
        <Modal.Body>
            <h6>
                <strong>{content}</strong>
            </h6>
        </Modal.Body>
    </Modal>
)

export default ProcessModal
