/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Tab, Image, Header } from 'semantic-ui-react'
import * as contentful from 'contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const Tutorial = () => {
    const history = useHistory()
    const [contentList, setContentList] = useState([])

    useEffect(() => {
        let isSubscribed = true

        const clientContent = contentful.createClient({
            space: process.env.REACT_APP_CONTENTFUL_SPACE,
            accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
        })

        clientContent
            .getEntries()
            .then((entries) =>
                setContentList(entries.items.map((entry) => entry.fields))
            )

        return () => (isSubscribed = false)
    }, [])

    const panes = contentList.reverse().map((entry) => {
        const options = {
            renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node) => (
                    <Image size="huge" src={node.data.target.fields.file.url} />
                ),
            },
        }
        return {
            menuItem: entry.title,
            render: () => (
                <Tab.Pane>
                    {documentToReactComponents(entry.description, options)}
                </Tab.Pane>
            ),
        }
    })

    return (
        <div>
            <Header as="h1">Dexter 101</Header>
            <Tab
                menu={{ fluid: true, vertical: true, tabular: true }}
                panes={panes}
            />
        </div>
    )
}

export default Tutorial
