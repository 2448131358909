import React, { useState } from 'react'
import Nouislider from 'nouislider-react'
import 'nouislider/distribute/nouislider.css'

const SliderFilter = ({ filters, type, range, updateFilter }) => {
    const [min, max] = range
    const [value, setValue] = useState(filters[type][1])

    const updateValue = (val) => {
        const newValue = parseInt(val[0])
        updateFilter(type, [0, newValue])
    }

    return (
        <div className={`sliders ${type}-slider`}>
            <Nouislider
                range={{ min, max }}
                step={1}
                start={value}
                connect={[true, false]}
                onChange={updateValue}
                onSlide={(val) => setValue(parseInt(val[0]))}
            />{' '}
            <span className="end_range_text">${value}</span>
        </div>
    )
}

export default SliderFilter
