import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import ProjectList from './ProjectList'

const ProjectRoutes = () => (
    <Switch>
        <Route
            exact
            path="/projects"
            render={() => <ProjectList category="Active" />}
        />
        <Route
            exact
            path="/projects/draft"
            render={() => <ProjectList category="Draft" />}
        />
        <Route
            exact
            path="/projects/completed"
            render={() => <ProjectList category="Completed" />}
        />
        <Route render={() => <Redirect to="/not-found-page" />} />
    </Switch>
)

export default ProjectRoutes
