import React, { Fragment } from 'react'
import { useMeasure } from 'react-use'
import { Grid } from 'semantic-ui-react'
import Logo from './Logo'
import Title from './Title'
import Expert from './Expert'
import ExtractionList from './ExtractionList'
import Progress from './Progress'
import History from './History'

const Panel = () => {
    const [panelRef, { height: panelHeight }] = useMeasure()

    return (
        <Fragment>
            <div className="panel" ref={panelRef}>
                <Logo />
                <Title />
                <Expert />
                <ExtractionList />
                <Progress />
            </div>
            <Grid.Row className="panel-footer">
                <History panelHeight={panelHeight} />
            </Grid.Row>
        </Fragment>
    )
}

export default Panel
