import React, { useState, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Dropdown } from 'semantic-ui-react'
import { LOAD_TEAMS_NAME_QUERY } from '../../graphql/team'
import DataContext from '../../contexts/DataContext'

const TeamSelection = () => {
    const { user } = useContext(DataContext)

    const [currentTeamName, setCurrentTeamName] = useState(
        user.membership[0].teamName
    )

    const [loadAllTeams, { called, loading, data }] = useLazyQuery(
        LOAD_TEAMS_NAME_QUERY
    )

    if (!user.isSuperUser()) return <div></div>

    const onSelect = (e, { value, text }) => {
        user.setCurrentTeam(value)
        setCurrentTeamName(text)
    }

    return (
        <Dropdown
            text={currentTeamName}
            basic
            button
            onClick={() => {
                loadAllTeams()
            }}
        >
            <Dropdown.Menu>
                {called && loading ? (
                    <Dropdown.Item text="Loading.." disabled />
                ) : (
                    data?.teams.map((team) => (
                        <Dropdown.Item
                            key={team.id}
                            value={team.id}
                            text={team.name}
                            onClick={onSelect}
                        />
                    )) || []
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default TeamSelection
