// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link, a {
  font-weight: bold;
  color: rgb(22, 171, 57);
}

.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}

.text {
  margin: 0;
  display: inline;
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/components/Admin/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,qCAAqC,EAAE,wBAAwB;EAC/D,6BAA6B,EAAE,oBAAoB;EACnD,sBAAsB,EAAE,cAAc;EACtC,wBAAwB,EAAE,YAAY;EACtC,qBAAqB,EAAE,SAAS;EAChC,qBAAqB,EAAE,2BAA2B;EAClD,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".link, a {\n  font-weight: bold;\n  color: rgb(22, 171, 57);\n}\n\n.wrapword {\n  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */\n  white-space: -webkit-pre-wrap; /* Chrome & Safari */\n  white-space: -pre-wrap; /* Opera 4-6 */\n  white-space: -o-pre-wrap; /* Opera 7 */\n  white-space: pre-wrap; /* CSS3 */\n  word-wrap: break-word; /* Internet Explorer 5.5+ */\n  word-break: break-all;\n  white-space: normal;\n}\n\n.text {\n  margin: 0;\n  display: inline;\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
