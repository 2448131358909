// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-text {
  text-align: center;
  color: #0066cc;
}

.loading-section {
  margin: 100px;
  margin-top: 350px;
}

.loading-icon {
  margin-left: 50%;
  margin-bottom: 3%;
}

.loading-section2 {
  margin: 150px;
  padding-bottom: 30px;
}

.loading-icon2 {
  margin-left: 50%;
}

.loader2 {
  border: 3px solid lightgrey;
  border-top: 3px solid black;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
  float: left;
  margin-right: 10px;
  margin-top: 3px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*=================
	windows resize
==================*/
@media only screen and (max-width: 768px) {
  .loading-section {
    margin-top: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinner/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,2BAA2B;EAC3B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kCAAkC;EAClC,WAAW;EACX,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;;mBAEmB;AACnB;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".loading-text {\n  text-align: center;\n  color: #0066cc;\n}\n\n.loading-section {\n  margin: 100px;\n  margin-top: 350px;\n}\n\n.loading-icon {\n  margin-left: 50%;\n  margin-bottom: 3%;\n}\n\n.loading-section2 {\n  margin: 150px;\n  padding-bottom: 30px;\n}\n\n.loading-icon2 {\n  margin-left: 50%;\n}\n\n.loader2 {\n  border: 3px solid lightgrey;\n  border-top: 3px solid black;\n  border-radius: 50%;\n  width: 16px;\n  height: 16px;\n  animation: spin 2s linear infinite;\n  float: left;\n  margin-right: 10px;\n  margin-top: 3px;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n/*=================\n\twindows resize\n==================*/\n@media only screen and (max-width: 768px) {\n  .loading-section {\n    margin-top: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
