import React, { useContext } from 'react'
import { Step } from 'semantic-ui-react'
import DataContext from '../../../../contexts/DataContext'

const StatusProgression = ({ project, job }) => {
    const { user } = useContext(DataContext)
    return (
        <Step.Group style={{ marginLeft: '5%' }}>
            <Step
                disabled={job.invite_status !== 'Invited'}
                className={job.invite_status === 'Invited' ? 'active' : null}
            >
                <Step.Content>
                    <Step.Title
                        style={
                            job.invite_status === 'Invited'
                                ? { color: 'green' }
                                : null
                        }
                    >
                        Invited
                    </Step.Title>
                </Step.Content>
            </Step>

            <Step
                disabled={job.response_status === 'Pending'}
                className={job.response_status !== 'Pending' ? 'active' : null}
            >
                <Step.Content>
                    <Step.Title
                        style={
                            job.response_status !== 'Pending'
                                ? job.response_status === 'Accepted'
                                    ? { color: 'green' }
                                    : { color: 'red' }
                                : null
                        }
                    >
                        {job.response_status === 'Accepted'
                            ? 'Accepted'
                            : job.response_status}
                    </Step.Title>
                </Step.Content>
            </Step>
            {user.isSuperUser() && project && project.type === 'Interview' && (
                <Step
                    disabled={!job.meetingAt}
                    className={job.meetingAt ? 'active' : null}
                >
                    <Step.Content>
                        <Step.Title
                            style={job.meetingAt ? { color: 'green' } : null}
                        >
                            Scheduled
                        </Step.Title>
                    </Step.Content>
                </Step>
            )}
            <Step
                disabled={!['Completed', 'Paid'].includes(job.overall_status)}
                className={
                    ['Completed', 'Paid'].includes(job.overall_status)
                        ? 'active'
                        : null
                }
            >
                <Step.Content>
                    <Step.Title
                        style={
                            ['Completed', 'Paid'].includes(job.overall_status)
                                ? { color: 'green' }
                                : job.overall_status === 'Cancelled' ||
                                  job.overall_status === 'Expired'
                                ? { color: 'red' }
                                : null
                        }
                    >
                        {job.overall_status === 'Cancelled' ||
                        job.overall_status === 'Expired'
                            ? job.overall_status
                            : 'Completed'}
                    </Step.Title>
                </Step.Content>
            </Step>
            <Step
                disabled={job.overall_status !== 'Paid'}
                className={job.overall_status === 'Paid' ? 'active' : null}
            >
                <Step.Content>
                    <Step.Title
                        style={
                            job.overall_status === 'Paid'
                                ? { color: 'green' }
                                : null
                        }
                    >
                        Paid
                    </Step.Title>
                </Step.Content>
            </Step>
        </Step.Group>
    )
}

export default StatusProgression
