import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Statistic, Icon, Popup } from 'semantic-ui-react'
import { UPDATE_JOB_MUTATION } from '../../../graphql/job'
import { exportAdvisors } from '../../../utils/helpers'
import Alert from '../../Alert'
import ProcessModal from '../../Modals/ProcessModal'
import { SingleProjectContext } from '../../../Context'
import './styles.css'

const AddToProject = ({
    selectedAdvisors,
    setSelectedAdvisors,
    setShowModal,
}) => {
    const { project, setProject } = useContext(SingleProjectContext)

    const [adding, setAdding] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')

    const [createJob] = useMutation(UPDATE_JOB_MUTATION)

    const addToProject = () => {
        if (selectedAdvisors.length === 0) {
            setShowAlert(true)
            setAlertHeader('No item selected !')
            setAlertBody('Please select the experts you would like to add.')
            return
        }
        if (project.team.availableInvites <= 0) {
            setShowAlert(true)
            setAlertHeader('Not enough available invites !')
            setAlertBody(`
                The action could not be done because you don't have enough available invites. 
                Please add more credits or try again later if you have any outstanding invitations. 
                Any un-responded invitations will expire and be freed up again for use 3 days after they are sent.
            `)
            return
        }
        setAdding(true)

        let promises = []
        selectedAdvisors.forEach((advisor) => {
            promises.push(
                createJob({
                    variables: {
                        userId: advisor.id,
                        projectId: project.id,
                        job: {
                            invite_status: 'Not Invited',
                            response_status: 'Pending',
                            overall_status: 'Pending Scheduling',
                        },
                    },
                    update: (cache, { data: { updateJob } }) => {
                        setProject(updateJob.project)
                    },
                })
            )
        })
        Promise.all(promises).then(() => {
            setAdding(false)
            setSelectedAdvisors([])
        })
    }

    const onExport = () => {
        if (selectedAdvisors.length === 0) {
            setShowAlert(true)
            setAlertHeader('No item selected !')
            setAlertBody('Please select the advisors you would like to export.')
            return
        }
        exportAdvisors(selectedAdvisors)
    }

    const buttonPositionStyle = {
        icon: true,
        size: 'tiny',
        labelPosition: 'left',
    }

    const isCompleted = project.status === 'Completed'

    const renderAddAndInviteButton = () => {
        if (selectedAdvisors.length === 0 || project.status !== 'Active') {
            return (
                <Popup
                    position="left center"
                    style={{ left: '80px' }}
                    trigger={
                        <Button
                            {...buttonPositionStyle}
                            style={{ opacity: 0.45, cursor: 'default' }}
                        >
                            <Icon name="add square" />
                            Add and Invite
                        </Button>
                    }
                    content="You cannot send project invites when the status is in Draft or Completed mode"
                />
            )
        }
        return (
            <Button {...buttonPositionStyle} onClick={() => setShowModal(true)}>
                <Icon name="add square" />
                Add and Invite
            </Button>
        )
    }

    return (
        <div className="add-to-project">
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <ProcessModal
                onShow={adding}
                content="Adding advisors to project.."
            />
            <Statistic className="title">
                <Statistic.Value>{selectedAdvisors.length} </Statistic.Value>
                <Statistic.Label>Advisors selected</Statistic.Label>
            </Statistic>
            <br />
            <Button
                {...buttonPositionStyle}
                onClick={addToProject}
                disabled={isCompleted || adding}
            >
                <Icon name="plus cart" />
                Add to project
            </Button>
            <br />
            {renderAddAndInviteButton()}
            <br />
            <Button
                {...buttonPositionStyle}
                basic
                color="green"
                onClick={onExport}
            >
                <Icon name="file excel" />
                Export
            </Button>
        </div>
    )
}

export default AddToProject
