import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Modal } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'
import { BLOCK_EXPERT, UNBLOCK_EXPERT } from '../../graphql/user'
import CustomLink from '../CustomLink'

const BlockExpertButton = ({ advisor }) => {
    const [show, setShow] = useState(false)
    const [blockExpert] = useMutation(BLOCK_EXPERT, {
        onCompleted: () => setShow(false),
    })
    const [unblockExpert] = useMutation(UNBLOCK_EXPERT)
    if (advisor.expert.isBlocked) {
        return (
            <CustomLink
                onClick={() => unblockExpert({ variables: { id: advisor.id } })}
            >
                Unblock this expert
            </CustomLink>
        )
    }
    return (
        <>
            <CustomLink onClick={() => setShow(true)}>
                Block this exeprt
            </CustomLink>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                centered
                style={{ zIndex: 9999 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Are you sure you wish to block ${
                            advisor.fullName || advisor.email
                        }?`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {`Blocked experts are no longer eligible to be invited to
                        Dexter projects and will not appear in search results in
                        the Dexter client portal. ${advisor.expert.numPending} pending invitations will be
                        expired upon blocking that person`}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShow(false)}>Cancel</Button>
                    <Button
                        primary
                        onClick={() =>
                            blockExpert({ variables: { id: advisor.id } })
                        }
                    >
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default BlockExpertButton
