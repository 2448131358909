import React, { Fragment } from 'react'
import { Statistic } from 'semantic-ui-react'
import { formatDollar } from '../../../utils/helpers'
import AdvisorTags from '../AdvisorTags'

const DefaulCard = ({ advisor }) => {
    return (
        <Fragment>
            <Statistic.Group widths={4}>
                <Statistic horizontal>
                    <Statistic.Value>
                        {formatDollar(advisor.expert.hourlyRate)}
                    </Statistic.Value>
                    <Statistic.Label>/ hr</Statistic.Label>
                </Statistic>
                <Statistic horizontal>
                    <Statistic.Value>
                        {advisor.expert.responseRate}%
                    </Statistic.Value>
                    <Statistic.Label>Response Rate</Statistic.Label>
                </Statistic>
                <Statistic horizontal>
                    <Statistic.Value>
                        {advisor.expert.numCompleted}
                    </Statistic.Value>
                    <Statistic.Label>Total Engagements</Statistic.Label>
                </Statistic>
            </Statistic.Group>
            <AdvisorTags advisor={advisor} showInternalOnly={false} />
        </Fragment>
    )
}

export default DefaulCard
