import React from 'react'
import { NavLink } from 'react-router-dom'

const MenuItem = ({ path, name }) => (
    <li>
        <NavLink activeStyle={{ color: 'green' }} to={path}>
            {name}
        </NavLink>
    </li>
)

export default MenuItem
