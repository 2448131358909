// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-page {
  width: 100% !important;
}

.payment-page .payment-table {
  height: 650px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: auto;
}

.payment-page .pagination-data {
  display: flex;
  align-items: center;
  justify-content: center;
}

#transcation-graph-dropdown {
  float: right;
  z-index: 999;
  margin-top: 40px;
}

#transcation-graph-dropdown > .dropdown.icon {
  padding: 0 !important;
}

.payment-graph-modal {
  margin-top: 2%;
}

.payment-graph-modal .modal-lg {
  max-width: 90%;
}

@media only screen and (max-width: 1000px) {
  .payment-page {
    margin: 10px;
    margin-bottom: 100px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Payments/styles.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,YAAY;IACZ,oBAAoB;EACtB;AACF","sourcesContent":[".payment-page {\n  width: 100% !important;\n}\n\n.payment-page .payment-table {\n  height: 650px;\n  width: 100%;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  overflow: auto;\n}\n\n.payment-page .pagination-data {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n#transcation-graph-dropdown {\n  float: right;\n  z-index: 999;\n  margin-top: 40px;\n}\n\n#transcation-graph-dropdown > .dropdown.icon {\n  padding: 0 !important;\n}\n\n.payment-graph-modal {\n  margin-top: 2%;\n}\n\n.payment-graph-modal .modal-lg {\n  max-width: 90%;\n}\n\n@media only screen and (max-width: 1000px) {\n  .payment-page {\n    margin: 10px;\n    margin-bottom: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
