import React, { useEffect, useContext } from 'react'
import { Grid, List } from 'semantic-ui-react'
import PanelHeader from './PanelHeader'
import { ExtractionReviewContext } from '../../../Context'

const ExtractionList = () => {
    const { queue, history } = useContext(ExtractionReviewContext)

    const tags = queue.map((extraction) => extraction.tag)
    const reviewedTags = history.map((h) => h.extraction.tag).reverse()
    const currTag = tags.at(0)

    const extractionList = document.querySelector('.here')

    useEffect(() => {
        if (extractionList) {
            const curCursor = extractionList.querySelector('.active')
            if (curCursor) {
                curCursor.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })
            }
        }
    }, [extractionList, currTag])

    return (
        <Grid.Row className="panel-section">
            <Grid.Column className="panel-section-col fullwidth">
                <PanelHeader label="EXTRACTIONS" />
                <div className="panel-section-subcol fullwidth">
                    <List id="extraction-list" className="here">
                        {reviewedTags.map((tag, index) => (
                            <List.Item key={index} disabled>
                                {tag}
                            </List.Item>
                        ))}
                        {tags.map((tag, index) => (
                            <List.Item key={index} active={tag === currTag}>
                                {tag}
                            </List.Item>
                        ))}
                    </List>
                </div>
            </Grid.Column>
        </Grid.Row>
    )
}

export default ExtractionList
