import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'

const CancelRequestModal = ({
    onShow,
    onHide,
    numOfSelectedExpert,
    project,
    cancelRequest,
}) => {
    return (
        <Modal
            show={onShow}
            onHide={onHide}
            centered
            size="large"
            className="message-modal"
        >
            <Modal.Header closeButton>
                {`You are about to remove ${numOfSelectedExpert} selected experts from this project`}
            </Modal.Header>
            <Modal.Body>
                <p>
                    Once confirmed, anyone who is not invited to the project
                    will be removed from the Manage advisors tab. For those who
                    are invited but whose response is pending, the invitation
                    will expire without further notice.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button basic onClick={onHide}>
                    Cancel
                </Button>
                <Button negative onClick={cancelRequest}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CancelRequestModal
