import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Image, Button } from 'semantic-ui-react'

const propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
}

const NotFoundPage = ({ history }) => (
    <div
        className="page-wrap d-flex flex-row align-items-center"
        style={{ marginTop: '15%' }}
    >
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 text-center">
                    <Image
                        src="/logos/page_not_found.svg"
                        size="medium"
                        centered
                    />
                    <h3 style={{ margin: '3% 1% 5% 1%' }}>
                        The page you are looking for was not found.
                    </h3>
                    <Button primary onClick={() => history.replace('/')}>
                        Back to Home
                    </Button>
                </div>
            </div>
        </div>
    </div>
)

NotFoundPage.propTypes = propTypes

export default NotFoundPage
