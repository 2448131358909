import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Segment } from 'semantic-ui-react'
import moment from 'moment'
import { LOAD_TRANSACTIONS_QUERY } from '../../../graphql/transaction'
import { LOAD_TEAMS_NAME_QUERY } from '../../../graphql/team'
import { DashboardContext } from '../../../Context'
import LoadingSpinner from '../../LoadingSpinner'
import StatsByRange from './StatsByRange'
import StatsByPeriod from './StatsByPeriod'

const getEngagedTeamIds = (transactions) => {
    const completedJobsByTeam = transactions.reduce((count, { team }) => {
        count[team] = count[team] || {
            id: team.id,
            completedJobs: 0,
        }
        count[team].completedJobs++
        return count
    }, {})

    return Object.values(completedJobsByTeam)
        .sort((a, b) => b.completedJobs - a.completedJobs)
        .map((t) => t.id)
}

const getMidDate = (date, range) => {
    switch (range) {
        case '14d':
            return new Date(moment(date).subtract(14, 'd'))
        case '4w':
            return new Date(moment(date).subtract(4, 'w'))
        case '3m':
            return new Date(moment(date).subtract(3, 'months'))
        default:
            return new Date(moment(date).subtract(7, 'd'))
    }
}

const Dashboard = () => {
    const [startDate, setStartDate] = useState(
        new Date(moment().subtract(13, 'd'))
    )
    const [endDate, setEndDate] = useState(new Date())
    const [range, setRange] = useState('7d')
    const [transactions, setTransactions] = useState([])
    const [teams, setTeams] = useState([])
    const [teamsToExclude, setTeamsToExclude] = useState([])

    const { loading: transactionsLoading } = useQuery(LOAD_TRANSACTIONS_QUERY, {
        variables: {
            startDate,
            endDate,
        },
        onCompleted: (data) => setTransactions(data.transactions.transactions),
    })

    const teamIds = [...new Set(transactions.map((tr) => tr.team.id))]

    const { loading: teamsLoading } = useQuery(LOAD_TEAMS_NAME_QUERY, {
        skip: teamIds.length === 0,
        variables: {
            ids: teamIds,
        },
        onCompleted: (data) => setTeams(data.teams),
    })

    if (transactionsLoading || teamsLoading) return <LoadingSpinner />

    const getTeamNameById = (teamId) => {
        const team = teams.find((t) => t.id === teamId)
        if (team) return team.name
        return ''
    }

    const handleDateRange = (endDate, range) => {
        const newMidDate = getMidDate(endDate, range)
        const startDate = new Date(
            moment(getMidDate(newMidDate, range)).add(1, 'd')
        )
        setRange(range)
        setStartDate(startDate)
        setEndDate(endDate)
    }

    const midDate = new Date(moment(getMidDate(endDate, range)).add(1, 'd'))

    const currentTransactions = transactions
        .filter((t) => t.createdAt >= midDate.getTime())
        .filter((t) => !teamsToExclude.includes(t.team))

    const lastTransactions = transactions
        .filter((t) => t.createdAt < midDate.getTime())
        .filter((t) => !teamsToExclude.includes(t.team))

    const engagedTeamIds = getEngagedTeamIds(
        transactions.filter(
            (t) => t.createdAt >= midDate.getTime() && t.type === 'Job Payment'
        )
    )

    return (
        <DashboardContext.Provider
            value={{
                startDate,
                endDate,
                midDate,
                range,
                getTeamNameById,
                handleDateRange,
                teamsToExclude,
                setTeamsToExclude,
                currentTransactions,
                lastTransactions,
                engagedTeamIds,
                teams,
            }}
        >
            <Segment style={{ padding: '2.5rem', width: '100%' }}>
                <StatsByRange />
            </Segment>
            <Segment style={{ padding: '2.5rem', width: '100%' }}>
                <StatsByPeriod />
            </Segment>
        </DashboardContext.Provider>
    )
}

export default Dashboard
