import { lowerCase } from 'lodash'
import React from 'react'
import { Header, Form, Label } from 'semantic-ui-react'

const Honorarium = ({ payment, updatePayment, job }) => (
    <div className="payment-info-field">
        <Header as="h6">Total honorarium</Header>
        <Form.Input
            labelPosition="right"
            type="number"
            min="0"
            width={8}
            placeholder="Amount"
            value={payment.amount}
            onChange={(e, { value }) =>
                updatePayment({
                    amount: Number(parseFloat(value).toFixed(2)),
                    description: `$${parseFloat(value).toFixed(
                        2
                    )} for ${lowerCase(job.project.type)}`,
                })
            }
        >
            <Label basic>$</Label>
            <input />
            <Label basic>USD</Label>
        </Form.Input>
    </div>
)

export default Honorarium
