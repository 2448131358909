import React from 'react'
import { Grid } from 'semantic-ui-react'
import ExpertInfo from './ExpertInfo'
import RescrapeExpert from './RescrapeExpert'

const Expert = () => (
    <Grid.Row className="panel-section">
        <Grid.Column className="panel-section-col fullwidth">
            <Grid.Row verticalAlign="middle">
                <Grid.Column
                    floated="left"
                    width={5}
                    className="panel-section-header"
                >
                    EXPERT
                </Grid.Column>
                <Grid.Column floated="right" width={5}>
                    <RescrapeExpert />
                </Grid.Column>
            </Grid.Row>
            <ExpertInfo />
        </Grid.Column>
    </Grid.Row>
)

export default Expert
