export const getInvitationPendingEmailMarkup = (title) => `
	<div>Hi,</div>
	<div>Thank you again for taking the time to speak with us! We appreciated your insights regarding ${title}.<br />
	<br />
	Your honorarium will be processed via our new in-house expert network platform,&nbsp;<a data-saferedirecturl="https://www.google.com/url?q=https://nam05.safelinks.protection.outlook.com/?url%3Dhttp%253A%252F%252Fdexter.decibio-analytics.com%252F%26data%3D02%257C01%257Cshrikant.mane%2540yale.edu%257Cf488cf180d8546d1b8cc08d724f52531%257Cdd8cbebb21394df8b4114e3e87abeb5c%257C0%257C0%257C637018507631878793%26sdata%3DOfoGSmxwb60aWvta8ed9OS5XnozUDFrX3NoWQuO6DdE%253D%26reserved%3D0&amp;source=gmail&amp;ust=1569454289216000&amp;usg=AFQjCNHj3FEV-6Nf2i6NNSS5WJ3ZViBffA" href="${process.env.REACT_APP_EXPERT_BASE_URL}">Dexter</a>. Key benefits of&nbsp;Dexter&nbsp;include:</div>
	</div>
	<div>
	<ul>
		<li style="margin-left: 47.25pt;">Easy set-up (&lt;5 min. to complete your profile and connect your bank account);</li>
		<li style="margin-left: 47.25pt;">Faster turnaround time (payments processed within 3 days of linking your account);</li>
		<li style="margin-left: 47.25pt;">Greater payment transparency (track payments in real-time and receive text updates);</li>
		<li style="margin-left: 47.25pt;">Improved security (Stripe is a PCI Service Provider Level 1, the highest grade of payment processing security)&nbsp;</li>
	</ul>
	<div style="margin-left: 47.25pt;">&nbsp;</div>
	<div>Click&nbsp;<a data-saferedirecturl="https://www.google.com/url?q=https://nam05.safelinks.protection.outlook.com/?url%3Dhttps%253A%252F%252Fdexter.decibio-analytics.com%252F%26data%3D02%257C01%257Cshrikant.mane%2540yale.edu%257Cf488cf180d8546d1b8cc08d724f52531%257Cdd8cbebb21394df8b4114e3e87abeb5c%257C0%257C0%257C637018507631878793%26sdata%3D1aDdemjLSCsG9HsF6WcCQrx5043XvUd1zy2Lbx6Yyjs%253D%26reserved%3D0&amp;source=gmail&amp;ust=1569454289216000&amp;usg=AFQjCNHCMS12ye-htMyvVtGX8rWXvbS8cQ" href="${process.env.REACT_APP_EXPERT_BASE_URL}">here</a>&nbsp;to get started on&nbsp;Dexter. For assistance with on-boarding, please&nbsp;review our&nbsp;<a data-saferedirecturl="https://www.google.com/url?q=https://nam05.safelinks.protection.outlook.com/?url%3Dhttps%253A%252F%252Fwww.decibio-analytics.com%252Ffaq%26data%3D02%257C01%257Cshrikant.mane%2540yale.edu%257Cf488cf180d8546d1b8cc08d724f52531%257Cdd8cbebb21394df8b4114e3e87abeb5c%257C0%257C0%257C637018507631888790%26sdata%3DpVmUGOJRuU5pnN0%252BopA9x%252BkGrxhDBhW2Xh%252FUvy6JS1A%253D%26reserved%3D0&amp;source=gmail&amp;ust=1569454289216000&amp;usg=AFQjCNGi2cv8PO235kfdNtBSd3khc5xoCg" href="https://www.dexterexperts.com/faq">FAQ</a> or check out our 1-minute introduction <a href="https://www.dropbox.com/s/s2ilum61o1n5pkj/Dexterv2%20expert%20onboarding%20-%20Copy.mp4?dl=0" target="_blank">video</a> to Dexter.
 If you experience any trouble signing up or connecting your bank account, please&nbsp;<a href="mailto:jessica.lee@decibio.com" target="_blank">contact</a><a href="mailto:jessica.lee@decibio.com?subject=Re%3A%20Dexter">&nbsp;</a>my colleague Jessica Lee.<br />
	<br />
	Thank you again for sharing your insights, and see you on&nbsp;Dexter!</div>
	</div>
	</div>
`
