/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { Container, Header, Form, Input, Button } from 'semantic-ui-react'
import {
    CREATE_TEAM_MUTATION,
    UPDATE_TEAM_MUTATION,
} from '../../../graphql/team'
import { phoneValidation } from '../../../utils/helpers'

const TeamControlPanel = ({ type, team, teams, setTeams, onCancel }) => {
    const [companyName, setCompanyName] = useState('')
    const [teamName, setTeamName] = useState('')
    const [phone, setPhone] = useState('')
    const [adminName, setAdminName] = useState('')
    const [adminEmail, setAdminEmail] = useState('')

    const [createTeam] = useMutation(CREATE_TEAM_MUTATION)
    const [updateTeam] = useMutation(UPDATE_TEAM_MUTATION)

    useEffect(() => {
        setCompanyName(team.company || '')
        setTeamName(team.name || '')
        setPhone(team.phone || '')
        setAdminName(team.adminName || '')
        setAdminEmail(team.adminEmail || '')
    }, [team])

    const onCreate = () => {
        if (!phoneValidation(phone)) {
            return alert('Invalid phone number')
        }

        createTeam({
            variables: {
                team: {
                    name: teamName,
                    company: companyName,
                    phone,
                },
                adminName,
                adminEmail,
            },
            update: (cache, { data: { createTeam } }) => {
                setTeams(teams.concat(createTeam))
                onCancel()
            },
        })
    }

    const onUpdate = () => {
        if (!phoneValidation(phone)) {
            return alert('Invalid phone number')
        }

        updateTeam({
            variables: {
                teamId: team.id,
                team: {
                    name: teamName,
                    company: companyName,
                    phone,
                },
            },
            update: (cache, { data: { updateTeam } }) => {
                const tempTeams = teams.slice()
                const findIndex = tempTeams
                    .map((team) => team.id)
                    .indexOf(updateTeam.id)
                if (findIndex !== -1) {
                    tempTeams[findIndex] = updateTeam
                }
                setTeams(tempTeams)
                onCancel()
            },
        })
    }

    const hasEmptyField = () => {
        const isEmpty = companyName === '' || teamName === ''
        if (type === 'CREATE_TEAM') {
            return isEmpty || adminName === '' || adminEmail === ''
        }
        return isEmpty
    }

    return (
        <Container className="side-panel">
            <Header size="huge" textAlign="center">
                <Header.Content>
                    {type === 'CREATE_TEAM' ? 'Create new team' : 'Update team'}
                </Header.Content>
            </Header>
            <Form style={{ marginTop: '5%' }}>
                <Form.Field>
                    <Input
                        placeholder="Company name"
                        value={companyName}
                        onChange={(e, { value }) => setCompanyName(value)}
                    />
                </Form.Field>
                <Form.Field>
                    <Input
                        placeholder="Team name"
                        value={teamName}
                        onChange={(e, { value }) => setTeamName(value)}
                    />
                </Form.Field>
                {type === 'CREATE_TEAM' && (
                    <Fragment>
                        <Form.Field>
                            <Input
                                placeholder="Admin name"
                                value={adminName}
                                onChange={(e, { value }) => setAdminName(value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                placeholder="Admin email address"
                                value={adminEmail}
                                onChange={(e, { value }) =>
                                    setAdminEmail(value)
                                }
                            />
                        </Form.Field>
                    </Fragment>
                )}
                <Form.Field>
                    <Input
                        placeholder="Phone number (Optional)"
                        value={phone}
                        onChange={(e, { value }) => setPhone(value)}
                    />
                </Form.Field>
                <div>
                    <Button
                        primary
                        disabled={hasEmptyField()}
                        onClick={type === 'CREATE_TEAM' ? onCreate : onUpdate}
                    >
                        {type === 'CREATE_TEAM' ? 'Create team' : 'Update team'}
                    </Button>
                    <Button onClick={onCancel} floated="right">
                        Cancel
                    </Button>
                </div>
            </Form>
        </Container>
    )
}

export default TeamControlPanel
