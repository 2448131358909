import React, { useState } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import {
    Container,
    Segment,
    List,
    Form,
    Transition,
    Header,
    Button,
} from 'semantic-ui-react'
import {
    PROJECT_BASIC,
    UPDATE_PROJECT_MUTATION,
    COMPLETE_PROJECT_MUTATION,
} from '../../../graphql/project'
import LoadingSpinner from '../../LoadingSpinner'
import { convertRichTextToPlainText } from '../../../utils/helpers'
import {
    COMPENSATION_OPTIONS,
    QUALTRICS_SURVEY_URL,
} from './ProjectControlPanel'

const EditableForm = ({ project }) => {
    const [isSaved, setIsSaved] = useState(false)
    const [description, setDescription] = useState(
        convertRichTextToPlainText(project.description)
    )
    const [compensationType, setCompensationType] = useState(
        project.compensationType
    )
    const [adminNotes, setAdminNotes] = useState(project.adminNotes || '')
    const [updateProject, { loading: updating }] = useMutation(
        UPDATE_PROJECT_MUTATION,
        {
            onCompleted: () => {
                setIsSaved(true)
                setTimeout(() => setIsSaved(false), 3000)
            },
        }
    )
    const [completeProject, { loading: completing }] = useMutation(
        COMPLETE_PROJECT_MUTATION,
        {
            onCompleted: () => {
                setIsSaved(true)
                setTimeout(() => setIsSaved(false), 3000)
            },
        }
    )
    const onSubmit = () => {
        updateProject({
            variables: {
                id: project.id,
                input: {
                    description,
                    compensationType,
                    adminNotes,
                },
            },
        })
    }
    const loading = updating || completing
    return (
        <Form onSubmit={onSubmit}>
            <Form.TextArea
                label="Description"
                value={description}
                onChange={(e, { value }) => setDescription(value)}
            />
            {project.compensationType && (
                <>
                    <Form.Dropdown
                        label="Compensation Type"
                        selection
                        options={COMPENSATION_OPTIONS}
                        value={compensationType.name}
                        onChange={(e, { value }) =>
                            setCompensationType({
                                ...compensationType,
                                name: value,
                            })
                        }
                    />
                    <p>
                        Note: The following method was used to convert hourly
                        rates in USD to credits. less than $450 = 1 credit,
                        $450-$750 = 1.5 credits, greater than $750 = 2 credits
                    </p>
                    {compensationType.name === 'Fixed' ? (
                        <Form.Input
                            type="number"
                            icon="dollar sign"
                            iconPosition="left"
                            value={compensationType.params[0]}
                            onChange={(e, { value }) =>
                                setCompensationType({
                                    ...compensationType,
                                    params: [parseInt(value)],
                                })
                            }
                        />
                    ) : (
                        <Form.Group widths="equal">
                            <Form.Input
                                type="number"
                                icon="dollar sign"
                                iconPosition="left"
                                value={compensationType.params[0]}
                                onChange={(e, { value }) => {
                                    const lower = parseInt(value)
                                    const upper = compensationType.params[1]
                                    setCompensationType({
                                        ...compensationType,
                                        params: [lower, upper],
                                    })
                                }}
                            />
                            {' - '}
                            <Form.Input
                                type="number"
                                icon="dollar sign"
                                iconPosition="left"
                                value={compensationType.params[1]}
                                onChange={(e, { value }) => {
                                    const lower = compensationType.params[0]
                                    const upper = parseInt(value)
                                    setCompensationType({
                                        ...compensationType,
                                        params: [lower, upper],
                                    })
                                }}
                            />
                        </Form.Group>
                    )}
                </>
            )}
            <Form.TextArea
                label="Admin Note"
                value={adminNotes}
                onChange={(e, { value }) => setAdminNotes(value)}
            />
            {isSaved && (
                <Transition visible={isSaved} animation="scale" duration={500}>
                    <Header color="green">Successfully saved!</Header>
                </Transition>
            )}
            <Form.Group>
                <Form.Button
                    primary
                    content="Save"
                    loading={loading}
                    disabled={loading || project.status === 'Completed'}
                />
                {project.status !== 'Draft' && (
                    <Button
                        positive
                        loading={loading}
                        disabled={loading}
                        content={
                            project.status === 'Active'
                                ? 'Mark prject complete'
                                : 'Re-active project'
                        }
                        onClick={() => {
                            if (project.status === 'Active') {
                                completeProject({
                                    variables: { id: project.id },
                                })
                            } else {
                                updateProject({
                                    variables: {
                                        id: project.id,
                                        input: { status: 'Active' },
                                    },
                                })
                            }
                        }}
                    />
                )}
            </Form.Group>
        </Form>
    )
}

const ManageProject = () => {
    const { id } = useParams()
    const { data, loading } = useQuery(PROJECT_BASIC, {
        variables: { id },
    })
    if (loading) return <LoadingSpinner />
    if (!data) return <Redirect to="/not-found-page" />
    const { project } = data

    const INFORMATION = [
        {
            name: 'ID',
            value: project.id,
        },
        {
            name: 'Title',
            value: project.title,
        },
        {
            name: 'Team',
            value: `${project.team.name} (${project.team.id})`,
        },
        {
            name: 'Project type',
            value: project.type,
        },
        {
            name: 'Service type',
            value: project.service_type,
        },
        {
            name: 'Status',
            value: project.status,
        },
        {
            name: 'Owner(s)',
            value: project.owners
                .map((owner) => owner.fullName || owner.email)
                .join(', '),
        },
        {
            name: 'Survey Link',
            value: project.qualtricsSurvey ? (
                <a
                    href={`${QUALTRICS_SURVEY_URL}/${project.qualtricsSurvey.surveyId}/edit`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Click to view survey in Qualtrics
                </a>
            ) : null,
        },
    ]

    return (
        <Container>
            <Segment>
                <List>
                    {INFORMATION.filter((info) => info.value !== null).map(
                        (info) => (
                            <List.Item key={info.name}>
                                <List.Content>
                                    <List.Header>{info.name}</List.Header>
                                    <List.Description>
                                        {info.value}
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        )
                    )}
                </List>
                <EditableForm project={project} />
            </Segment>
        </Container>
    )
}

export default ManageProject
