import React, { Fragment } from 'react'
import { Divider, Header } from 'semantic-ui-react'

const AddCredits = () => (
    <Fragment>
        <Header as="h2" name="add-credit">
            How do I add more credits?
        </Header>
        You can request more credits any time by completing this form{' '}
        <a
            href="https://forms.gle/ukxm4LXcCZ7RyTJJ7"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'black' }}
        >
            here
        </a>
        . We will get back to you with an order form shortly after we receive a
        request from you.
        <Divider />
    </Fragment>
)

export default AddCredits
