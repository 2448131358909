import React from 'react'
import { Icon, Step } from 'semantic-ui-react'

const STEPS = [
    {
        title: 'Remove',
        description: 'Select an organization to remove',
        icon: 'building outline',
        name: 'remove',
    },
    {
        title: 'Keep',
        description: 'Select an organization to keep',
        icon: 'building',
        name: 'keep',
    },
    {
        title: 'Confirm Merge',
        description: 'Verify Merge Details',
        icon: 'check circle',
        name: 'confirm',
    },
]

const Steps = ({ step }) => {
    return (
        <Step.Group vertical>
            {STEPS.map((s, i) => (
                <Step
                    key={s.name}
                    active={i === step}
                    completed={i < step}
                    disabled={i > step}
                >
                    <Icon name={s.icon} />
                    <Step.Content>
                        <Step.Title>{s.title}</Step.Title>
                        <Step.Description>{s.description}</Step.Description>
                    </Step.Content>
                </Step>
            ))}
        </Step.Group>
    )
}

export default Steps
