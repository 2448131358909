import React, { useContext } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Grid, Header, Segment, Form } from 'semantic-ui-react'
import { DashboardContext } from '../../../../Context'
import ClientOrders from './ClientOrders'
import RevenueStats from './RevenueStats'

const DATE_RANGES_OPTIONS = [
    { key: '7d', value: '7d', text: 'Last 7 days' },
    { key: '14d', value: '14d', text: 'Last 14 days' },
    { key: '4w', value: '4w', text: 'Last 4 weeks' },
    { key: '3m', value: '3m', text: 'Last 3 months' },
]

const TeamToggle = () => {
    const {
        engagedTeamIds,
        getTeamNameById,
        teamsToExclude,
        setTeamsToExclude,
    } = useContext(DashboardContext)
    return (
        <Segment style={{ marginLeft: '1rem' }}>
            <Header as="h6">Toggle Teams</Header>
            {engagedTeamIds.length === 0 ? (
                <h6>No data found during this period</h6>
            ) : (
                <Form size="mini" style={{ marginTop: '1rem' }}>
                    {engagedTeamIds.map((teamId) => (
                        <Form.Checkbox
                            key={teamId}
                            label={getTeamNameById(teamId)}
                            checked={!teamsToExclude.includes(teamId)}
                            toggle
                            onChange={(e, { checked }) => {
                                if (checked) {
                                    setTeamsToExclude(
                                        teamsToExclude.filter(
                                            (t) => t !== teamId
                                        )
                                    )
                                } else {
                                    setTeamsToExclude(
                                        teamsToExclude.concat(teamId)
                                    )
                                }
                            }}
                        />
                    ))}
                </Form>
            )}
        </Segment>
    )
}

const StatsByRange = () => {
    const {
        startDate,
        endDate,
        midDate,
        range,
        currentTransactions,
        lastTransactions,
        handleDateRange,
    } = useContext(DashboardContext)

    const currentPeriod = `${moment(midDate).format('L')} - ${moment(
        endDate
    ).format('L')}`

    const previousPeriod = `${moment(startDate).format('L')} - ${moment(midDate)
        .subtract(1, 'd')
        .format('L')}`

    return (
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <TeamToggle />
                </Grid.Column>
                <Grid.Column textAlign="right">
                    <Grid>
                        <Grid.Row textAlign="right">
                            <Grid.Column>
                                <Form>
                                    <Form.Group
                                        inline
                                        style={{ float: 'right' }}
                                    >
                                        <Form.Field>
                                            <DatePicker
                                                placeholderText="Pick an end date"
                                                showTimeSelect={false}
                                                dateFormat="MMMM d, yyyy"
                                                maxDate={new Date()}
                                                selected={endDate}
                                                onChange={(date) =>
                                                    handleDateRange(date, range)
                                                }
                                            />
                                        </Form.Field>
                                        <Form.Dropdown
                                            placeholder="Pick an range"
                                            selection
                                            options={DATE_RANGES_OPTIONS}
                                            value={range}
                                            onChange={(e, { value }) =>
                                                handleDateRange(endDate, value)
                                            }
                                        />
                                    </Form.Group>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign="right">
                                {startDate && endDate && (
                                    <>
                                        <Header.Subheader>
                                            {`Current period: ${currentPeriod}`}
                                        </Header.Subheader>
                                        <Header.Subheader>
                                            {`Previous period: ${previousPeriod}`}
                                        </Header.Subheader>
                                    </>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <RevenueStats
                        transactions={currentTransactions}
                        lastTransactions={lastTransactions}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <ClientOrders
                        transactions={currentTransactions.filter(
                            (t) => t.type === 'Credit Purchase'
                        )}
                        lastTransactions={lastTransactions.filter(
                            (t) => t.type === 'Credit Purchase'
                        )}
                        currentPeriod={currentPeriod}
                        previousPeriod={previousPeriod}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default StatsByRange
