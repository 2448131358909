/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Form, Rating } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import {
    UPDATE_JOB_MUTATION,
    COMPLETE_JOB_MUTATION,
} from '../../../graphql/job'
import { SingleProjectContext } from '../../../Context'

const CompleteModal = ({ onShow, onHide, advisor, project, job }) => {
    const singleProjectContext = useContext(SingleProjectContext)

    const [advisorComment, setAdvisorComment] = useState('')
    const [advisorRating, setAdvisorRating] = useState(0)

    const [updateJob, { loading: updating }] = useMutation(UPDATE_JOB_MUTATION)
    const [completeJob, { loading: completing }] = useMutation(
        COMPLETE_JOB_MUTATION
    )

    const isSelfService = project.service_type === 'Self-Service'

    const onComplete = () => {
        if (!isSelfService) {
            updateJob({
                variables: {
                    userId: advisor.id,
                    projectId: project.id,
                    job: {
                        overall_status: 'Completed',
                        expert_comment: advisorComment,
                        expert_rating: advisorRating,
                        completedAt: new Date(),
                        email_logs: job.email_logs.concat({
                            subject: 'Dexter: Project Complete',
                            sent_at: new Date(),
                        }),
                    },
                },
                update: (cache, { data: { updateJob } }) => {
                    if (singleProjectContext) {
                        singleProjectContext.setProject(updateJob.project)
                    }
                    onHide()
                },
            })
        } else {
            completeJob({
                variables: {
                    jobId: job.id,
                },
                update: () => onHide(),
            })
        }
    }

    return (
        <Modal show={onShow} onHide={onHide} centered style={{ zIndex: 9999 }}>
            <Modal.Header>Mark Job Complete</Modal.Header>
            <Modal.Body>
                <p>{`Are you sure you want to mark ${
                    advisor.fullName || advisor.email
                } on ${project.title} complete?`}</p>
                {!isSelfService && (
                    <Form>
                        <Form.Field>
                            <label>Advisor rating</label>
                            <Rating
                                icon="star"
                                defaultRating={advisorRating}
                                maxRating={5}
                                style={{ marginLeft: '5px' }}
                                onRate={(e, { rating }) =>
                                    setAdvisorRating(rating)
                                }
                            />
                        </Form.Field>
                        <Form.TextArea
                            row="3"
                            placeholder="Enter your comment on this advisor"
                            onChange={(e, { value }) =>
                                setAdvisorComment(value)
                            }
                        />
                    </Form>
                )}
                <br />
                <span>
                    {`Marking this job complete will trigger a status update email to ${
                        advisor.fullName || advisor.email
                    }`}
                </span>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    basic
                    loading={completing || updating}
                    disabled={completing || updating}
                    onClick={onHide}
                >
                    Cancel
                </Button>
                <Button
                    color="green"
                    loading={completing || updating}
                    disabled={completing || updating}
                    onClick={onComplete}
                >
                    Complete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CompleteModal
