// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-info-field {
  margin-top: 1.5rem;
}
.payment-tags-section {
  margin-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modals/FullServicePayment/PaymentInfo/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".payment-info-field {\n  margin-top: 1.5rem;\n}\n.payment-tags-section {\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
