import React, { useState, useContext } from 'react'
import moment from 'moment'
import { Grid, Dropdown } from 'semantic-ui-react'
import { DashboardContext } from '../../../../Context'
import StatsGraph from './StatsGraph'

const PERIOD_OPTIONS = [
    { key: 'weekly', value: 'weekly', text: 'Weekly' },
    { key: 'biweekly', value: 'biweekly', text: 'Biweekly' },
    { key: 'monthly', value: 'monthly', text: 'Monthly' },
]

const StatsByPeriod = () => {
    const { midDate, endDate, currentTransactions } =
        useContext(DashboardContext)
    const [period, setPeriod] = useState('weekly')

    const getDateFromPeriodAgo = (date) => {
        switch (period) {
            case 'biweekly':
                return new Date(moment(date).subtract(2, 'w'))
            case 'monthly':
                return new Date(moment(date).subtract(1, 'month'))
            default:
                return new Date(moment(date).subtract(1, 'w'))
        }
    }

    const getCurrentPeriod = (before, now) => {
        if (period === 'monthly') return `${moment(now).format('MMM YYYY')}`
        return `${moment(before).format('M/D')}-${moment(now).format('M/D/YY')}`
    }

    const transactionsByPeriod = {}
    let now = endDate
    let before = getDateFromPeriodAgo(endDate)
    while (
        new Date(now.getFullYear(), now.getMonth(), now.getDate()) >
        new Date(midDate.getFullYear(), midDate.getMonth(), midDate.getDate())
    ) {
        const currentPeriod = getCurrentPeriod(before, now)
        transactionsByPeriod[currentPeriod] = currentTransactions.filter(
            // eslint-disable-next-line
            (t) =>
                t.createdAt <= now.getTime() && t.createdAt > before.getTime()
        )
        now = getDateFromPeriodAgo(now)
        before = getDateFromPeriodAgo(before)
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column textAlign="right">
                    <Dropdown
                        selection
                        options={PERIOD_OPTIONS}
                        value={period}
                        onChange={(e, { value }) => setPeriod(value)}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <StatsGraph transactionsByPeriod={transactionsByPeriod} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default StatsByPeriod
