import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
    Container,
    Header,
    Form,
    Input,
    Button,
    Dropdown,
} from 'semantic-ui-react'
import { UPDATE_TEAM_MUTATION } from '../../../../graphql/team'
import { phoneValidation } from '../../../../utils/helpers'

const STATUS_OPTIONS = ['Active', 'Suspended'].map((status) => ({
    key: status,
    value: status,
    text: status,
}))

const SidePanel = ({ team }) => {
    const [company, setCompany] = useState(team.company)
    const [name, setName] = useState(team.name)
    const [phone, setPhone] = useState(team.phone)
    const [status, setStatus] = useState(team.status)

    const [updateTeam, { loading }] = useMutation(UPDATE_TEAM_MUTATION, {
        onCompleted: () => alert('Updated'),
    })

    const onUpdate = () => {
        if (!phoneValidation(phone)) {
            return alert('Invalid phone number')
        }

        updateTeam({
            variables: {
                teamId: team.id,
                team: { name, company, phone, status },
            },
            onError: (error) => alert(error),
        })
    }

    const hasEmptyField = () => {
        return company.trim().length === 0 || name.trim().length === 0
    }

    return (
        <Container className="side-panel">
            <Header size="huge" textAlign="center">
                <Header.Content>
                    {team.company}
                    <Header.Subheader>{team.name}</Header.Subheader>
                </Header.Content>
            </Header>
            <Form style={{ marginTop: '5%' }}>
                <Form.Field>
                    <Input
                        placeholder="Company name"
                        value={company}
                        onChange={(e, { value }) => setCompany(value)}
                    />
                </Form.Field>
                <Form.Field>
                    <Input
                        placeholder="Team name"
                        value={name}
                        onChange={(e, { value }) => setName(value)}
                    />
                </Form.Field>
                <Form.Field>
                    <Input
                        placeholder="Phone number (Optional)"
                        value={phone}
                        onChange={(e, { value }) => setPhone(value)}
                    />
                </Form.Field>
                {team.name !== 'Dexter' && (
                    <Form.Field>
                        <Dropdown
                            fluid
                            selection
                            options={STATUS_OPTIONS}
                            value={status}
                            onChange={(e, { value }) => setStatus(value)}
                        />
                    </Form.Field>
                )}
                <div>
                    <Button
                        primary
                        loading={loading}
                        disabled={hasEmptyField() || loading}
                        onClick={onUpdate}
                    >
                        Save
                    </Button>
                </div>
            </Form>
        </Container>
    )
}

export default SidePanel
