// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-advisors-page {
  width: 60% !important;
  margin: 0 auto;
  margin-top: 5%;
}

.project-advisors-page .ui.card,
.ui.cards > .card {
  width: 97% !important;
  margin: auto;
}

.tags-section .customize-tags {
  margin: 5px;
}

.schedule-section {
  margin-left: 5%;
}

.schedule-section a {
  margin-left: 1%;
  font-size: 14px;
  color: rgb(73, 135, 202) !important;
  text-decoration: underline;
}

.email-logs {
  color: grey !important;
  font-size: 14px;
}

.email-logs-title {
  margin-bottom: 0 !important;
}

.email-logs-list {
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/components/Advisors/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;EACd,cAAc;AAChB;;AAEA;;EAEE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,mCAAmC;EACnC,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".project-advisors-page {\n  width: 60% !important;\n  margin: 0 auto;\n  margin-top: 5%;\n}\n\n.project-advisors-page .ui.card,\n.ui.cards > .card {\n  width: 97% !important;\n  margin: auto;\n}\n\n.tags-section .customize-tags {\n  margin: 5px;\n}\n\n.schedule-section {\n  margin-left: 5%;\n}\n\n.schedule-section a {\n  margin-left: 1%;\n  font-size: 14px;\n  color: rgb(73, 135, 202) !important;\n  text-decoration: underline;\n}\n\n.email-logs {\n  color: grey !important;\n  font-size: 14px;\n}\n\n.email-logs-title {\n  margin-bottom: 0 !important;\n}\n\n.email-logs-list {\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
