import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Header, List, Button, Icon } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import { SEND_EMAIL_MUTATION } from '../../../graphql/email'
import { PAY_EXPERT, DEDUCT_CREDIT } from '../../../graphql/transaction'
import { LOAD_MANAGE_JOBS_QUERY } from '../../../graphql/admin'
import Alert from '../../Alert'

const Confirm = ({ job, payment, updatePage }) => {
    const [error, setError] = useState(null)

    const [payExpert, { loading: paying }] = useMutation(PAY_EXPERT, {
        refetchQueries: [
            {
                query: LOAD_MANAGE_JOBS_QUERY,
            },
        ],
        onError: (err) => {
            setError({
                header: 'Unsuccessful payment',
                body: `
                    Failed to process the payment due to the following reason: ${err.message}. 
                    Please try again or contact your site administrator if the problem persists
                `,
            })
        },
    })
    const [deductCredit, { loading: deducting }] = useMutation(DEDUCT_CREDIT, {
        onError: (err) => {
            setError({
                body: `Failed to process credit deduction due to the following reason: ${err.message}.`,
            })
        },
    })
    const [sendEmail] = useMutation(SEND_EMAIL_MUTATION)

    const deductCreditOnly = () => {
        const {
            additionalMessage,
            callMinutes,
            hourlyRate,
            checkPaymentInfo,
            ...rest
        } = payment
        const paymentInput = {
            ...rest,
            type: 'Credit Deduction',
            method: 'Internal',
        }
        deductCredit({
            variables: { jobId: job.id, payment: paymentInput },
            onCompleted: () => updatePage('SUCCESS'),
        })
    }

    const onPay = () => {
        const { additionalMessage, callMinutes, hourlyRate, ...rest } = payment
        const paymentInput = { ...rest }
        if (payment.checkPaymentInfo) {
            paymentInput.checkPaymentInfo = JSON.stringify(
                payment.checkPaymentInfo
            )
        }
        payExpert({
            variables: {
                jobId: job.id,
                payment: paymentInput,
            },
            onCompleted: () => {
                updatePage('SUCCESS')
                // Send addtional message
                if (additionalMessage) {
                    sendEmail({
                        variables: {
                            input: {
                                to: {
                                    email: job.user.email,
                                },
                                from: {
                                    email: 'info@dexterexperts.com',
                                    name: 'Dexter Team',
                                },
                                subject: `Re: Your Dexter engagement on ${job.project.title}`,
                                text: 'After payment email',
                                html: additionalMessage,
                            },
                        },
                    })
                }
            },
        })
    }
    return (
        <Modal.Body>
            {error && (
                <Alert
                    show={true}
                    header={error.header}
                    body={error.body}
                    onClose={() => setError(null)}
                />
            )}
            <Header as="h6">To be processed:</Header>
            <List>
                {(payment.credits || payment.credits === 0) && (
                    <List.Item>
                        {`- Deduct ${payment.credits} credit(s) from ${job.project.team.name}'s account`}
                    </List.Item>
                )}
                <List.Item>
                    {`${
                        payment.method === 'Stripe'
                            ? `- Pay ${job.user.fullName || job.user.email} $${
                                  payment.amount
                              }`
                            : `- Record an external payment of $${
                                  payment.amount
                              } to ${job.user.fullName || job.user.email}`
                    } with the payment method, ${payment.method}`}
                </List.Item>
                {job.creditDeduction && (
                    <List.Item>
                        <b>{`A credit deduction of ${-job.creditDeduction
                            .credits} credits for the intended amount of $${
                            job.creditDeduction.amount
                        } has already been made`}</b>
                    </List.Item>
                )}
            </List>
            <Modal.Footer>
                <Button icon basic onClick={() => updatePage('CREDIT')}>
                    <Icon name="angle left" />
                </Button>
                {payment.method !== 'Stripe' && (
                    <Button
                        basic
                        loading={deducting}
                        disabled={deducting}
                        onClick={deductCreditOnly}
                    >
                        Deduct Credit Only
                    </Button>
                )}
                <Button loading={paying} disabled={paying} onClick={onPay}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal.Body>
    )
}

export default Confirm
