import React from 'react'
import { Form, Header } from 'semantic-ui-react'

const Description = ({ payment, updatePayment }) => (
    <div className="payment-info-field">
        <Header as="h6">Payment Description</Header>
        <div>
            <Form.Input
                className="payment-description"
                width={16}
                value={payment.description}
                onChange={(e, { value }) =>
                    updatePayment({
                        description: value,
                    })
                }
            />
        </div>
    </div>
)

export default Description
