import React from 'react'

const Shortcut = ({ shortcut, active = null, activeClass = null }) => {
    return (
        <div className={`shortcut ${active ? activeClass : ''}`}>
            {shortcut}
        </div>
    )
}

export default Shortcut
