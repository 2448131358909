// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-menu {
  max-height: 200px;
  width: auto;
  overflow: auto;
}

.dropdown-search {
  background: white !important;
  width: 100%;
}

.sliders {
  display: inline-block;
  border-radius: 3px;
  padding: 3px;
  color: #777;
}

.end_range {
  font-size: 12px;
  border: none;
  border-bottom: 1px solid lightgrey;
  width: auto;
}

.end_range_text {
  margin-left: 30px;
}

.enrollment_volume-slider .start_range {
  margin-right: 0px;
}

.noUi-target {
  width: 240px;
  display: inline-block;
}

.noUi-horizontal {
  height: 5px !important;
}

.noUi-connect {
  background: lightgrey !important;
}

.noUi-handle:before,
.noUi-handle:after {
  display: none !important;
}

.noUi-handle {
  width: 15px !important;
  height: 15px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Filters/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,4BAA4B;EAC5B,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,kCAAkC;EAClC,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;;EAEE,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".dropdown-menu {\n  max-height: 200px;\n  width: auto;\n  overflow: auto;\n}\n\n.dropdown-search {\n  background: white !important;\n  width: 100%;\n}\n\n.sliders {\n  display: inline-block;\n  border-radius: 3px;\n  padding: 3px;\n  color: #777;\n}\n\n.end_range {\n  font-size: 12px;\n  border: none;\n  border-bottom: 1px solid lightgrey;\n  width: auto;\n}\n\n.end_range_text {\n  margin-left: 30px;\n}\n\n.enrollment_volume-slider .start_range {\n  margin-right: 0px;\n}\n\n.noUi-target {\n  width: 240px;\n  display: inline-block;\n}\n\n.noUi-horizontal {\n  height: 5px !important;\n}\n\n.noUi-connect {\n  background: lightgrey !important;\n}\n\n.noUi-handle:before,\n.noUi-handle:after {\n  display: none !important;\n}\n\n.noUi-handle {\n  width: 15px !important;\n  height: 15px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
