import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Loader } from 'semantic-ui-react'
import { UPDATE_JOB_MUTATION } from '../../../graphql/job'
import { getInviteReminderEmail } from '../../Emails'
import EmailModal from './EmailModal'
import { SingleProjectContext } from '../../../Context'
import AuthContext from '../../../contexts/AuthContext'

const InviteReminderModal = ({
    onShow,
    onHide,
    advisor,
    project,
    job,
    onSave,
}) => {
    const { authUser } = useContext(AuthContext)
    const singleProjectContext = useContext(SingleProjectContext)

    const [adding, setAdding] = useState(false)

    const [updateJob] = useMutation(UPDATE_JOB_MUTATION)

    const onRemind = (email_content, sendEmail, email_subject) => {
        const emailInfo = {
            recipient: {
                email: advisor ? advisor.email : '',
            },
            sender: {
                email: authUser.email,
                name: 'Dexter',
            },
            cc: project.owners.map((owner) => owner.email),
            text: 'Invitation Reminder Email',
            html: getInviteReminderEmail(
                `${advisor.expert.salutation} ${
                    advisor.fullName || advisor.email
                }`,
                email_content
            ),
        }

        setAdding(true)

        updateJob({
            variables: {
                userId: advisor.id,
                projectId: project.id,
                job: {
                    invitedReminderAt: new Date(),
                    email_logs: job.email_logs.concat({
                        subject: email_subject,
                        sent_at: new Date(),
                    }),
                },
            },
            update: (cache, { data: { updateJob } }) => {
                sendEmail(
                    emailInfo.recipient,
                    emailInfo.sender,
                    emailInfo.cc,
                    emailInfo.text,
                    emailInfo.html
                )
                if (singleProjectContext) {
                    singleProjectContext.setProject(updateJob.project)
                }
                if (onSave) {
                    onSave()
                }
                setAdding(false)
            },
        })
    }

    if (adding) return <Loader active />

    return (
        <EmailModal
            onShow={onShow}
            onHide={onHide}
            project={project}
            type="InviteReminder"
            title={`Send Reminder email to expert`}
            buttonText={`Send email`}
            onSend={onRemind}
        />
    )
}

export default InviteReminderModal
