import React from 'react'
import { Modal } from 'react-bootstrap'

const ProcessSuccess = () => (
    <Modal.Body>
        <span>Success</span>
        <br />
        <span>Your request has been processed</span>
    </Modal.Body>
)

export default ProcessSuccess
