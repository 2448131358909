import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import FAQMenu from '../components/FAQ/FAQMenu'
import FAQContent from '../components/FAQ/FAQContent'

const FAQ = () => {
    return (
        <Container style={{ margin: 20 }}>
            <Header as="h1">Frequently asked questions </Header>
            <FAQMenu />
            <FAQContent />
        </Container>
    )
}

export default FAQ
