import React, { useState } from 'react'
import { Popup } from 'semantic-ui-react'

const SocialIcon = ({ advisor }) => {
    const [emailHover, setEmailHover] = useState(false)
    const [linkedinHover, setLinkedinHover] = useState(false)

    //functions
    const copyItem = (e, type) => {
        e.preventDefault()
        let aux = document.createElement('input')
        const attr = type === 'email' ? advisor[type] : advisor.expert[type]
        aux.setAttribute('value', attr)
        document.body.appendChild(aux)
        aux.select()
        aux.setSelectionRange(0, 99999)
        document.execCommand('copy')
        document.body.removeChild(aux)
    }

    const renderEmailIcon = () => {
        return (
            <Popup
                trigger={
                    <a
                        href="# "
                        style={{ float: 'right', marginRight: '5px' }}
                        onMouseEnter={() => setEmailHover(true)}
                        onMouseLeave={() => setEmailHover(false)}
                        onClick={(e) => {
                            copyItem(e, 'email')
                        }}
                    >
                        <Popup
                            trigger={
                                <img
                                    src={
                                        emailHover
                                            ? '/logos/email.svg'
                                            : '/logos/email-grey.svg'
                                    }
                                    className="social_pic"
                                    alt="email"
                                />
                            }
                            content="Copy"
                            style={{ zIndex: 9999 }}
                        />
                    </a>
                }
                content="Copied!"
                on="click"
                hideOnScroll
                style={{ zIndex: 9999 }}
            />
        )
    }

    const renderLinkedinIcon = () => {
        const checkInfoEmpty = (info) =>
            !info || info === '(none)' || info === '(unknown)'

        const isValidatedLinkedin = (url) => {
            const pattern = new RegExp(
                'https://www.linkedin.com/in/(\\?[;&a-z\\d%_.~+=-]*)?'
            )
            return !!pattern.test(url)
        }

        if (
            checkInfoEmpty(advisor.expert.linkedin_profile) ||
            !isValidatedLinkedin(advisor.expert.linkedin_profile)
        ) {
            return null
        }

        return (
            <Popup
                trigger={
                    <a
                        href="# "
                        style={{ float: 'right' }}
                        onMouseEnter={() => setLinkedinHover(true)}
                        onMouseLeave={() => setLinkedinHover(false)}
                        onClick={(e) => {
                            copyItem(e, 'linkedin_profile')
                        }}
                    >
                        <Popup
                            trigger={
                                <img
                                    src={
                                        linkedinHover
                                            ? '/logos/social.svg'
                                            : '/logos/social-grey.svg'
                                    }
                                    className="social_pic"
                                    alt="linkedin"
                                />
                            }
                            content="Copy"
                            style={{ zIndex: 9999 }}
                        />
                    </a>
                }
                content="Copied!"
                on="click"
                hideOnScroll
                style={{ zIndex: 9999 }}
            />
        )
    }

    return (
        <div style={{ float: 'right' }}>
            {renderEmailIcon()}
            {renderLinkedinIcon()}
        </div>
    )
}

export default SocialIcon
