export const FIELDS = [
    'id',
    'name',
    'location',
    'type',
    'size',
    'url',
    'status',
    'experts',
    'description',
]
