import React from 'react'
import { Modal } from 'react-bootstrap'
import { Header, Button } from 'semantic-ui-react'

const SuccessConfirmation = ({ title, content, onHide }) => (
    <Modal show={true} onHide={onHide} centered>
        <Modal.Header closeButton>Success</Modal.Header>
        <Modal.Body>
            <div style={{ padding: '1.5rem' }}>
                <Header>{title}</Header>
                <p>{content}</p>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button primary onClick={onHide}>
                Done
            </Button>
        </Modal.Footer>
    </Modal>
)

export default SuccessConfirmation
