// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-to-project {
  position: fixed;
  margin-left: 60%;
}

.add-to-project .title {
  margin-left: 15% !important;
}

.add-to-project button {
  margin-bottom: 5px !important;
  width: 100%;
}

.add-new-expert {
  position: fixed;
  margin-left: 60%;
  margin-right: 10px;
}

.add-new-expert label {
  font-size: 12px;
}

.filter-tags {
  position: fixed;
  width: 20%;
  max-height: 60% !important;
  overflow: auto;
  margin-left: -22%;
  z-index: 9999 !important;
}

.advisor-box {
  position: fixed;
  width: 20%;
  margin-top: 5%;
  margin-left: 18%;
}

.filter-tags .tag-section .customize-tags {
  margin: 5px;
}

.filter-tags.box {
  padding: 1%;
  background: rgb(242, 242, 242);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.filter-tags.box::-webkit-scrollbar {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Advisors/Toolbox/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,0BAA0B;EAC1B,cAAc;EACd,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,UAAU;EACV,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,8BAA8B;EAC9B,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".add-to-project {\n  position: fixed;\n  margin-left: 60%;\n}\n\n.add-to-project .title {\n  margin-left: 15% !important;\n}\n\n.add-to-project button {\n  margin-bottom: 5px !important;\n  width: 100%;\n}\n\n.add-new-expert {\n  position: fixed;\n  margin-left: 60%;\n  margin-right: 10px;\n}\n\n.add-new-expert label {\n  font-size: 12px;\n}\n\n.filter-tags {\n  position: fixed;\n  width: 20%;\n  max-height: 60% !important;\n  overflow: auto;\n  margin-left: -22%;\n  z-index: 9999 !important;\n}\n\n.advisor-box {\n  position: fixed;\n  width: 20%;\n  margin-top: 5%;\n  margin-left: 18%;\n}\n\n.filter-tags .tag-section .customize-tags {\n  margin: 5px;\n}\n\n.filter-tags.box {\n  padding: 1%;\n  background: rgb(242, 242, 242);\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n\n.filter-tags.box::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
