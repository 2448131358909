import React from 'react'
import { Grid } from 'semantic-ui-react'
import AcceptanceStats from '../AcceptanceStats'
import ResultsCard from './ResultsCard'
import { DECISIONS } from '../../../../constants/review'

const Results = ({ history, numTagExtractions }) => {
    return (
        <Grid.Column className="results fullwidth">
            RESULTS
            <Grid.Row>
                <Grid.Column width={1} className="results-ring">
                    <AcceptanceStats
                        radius={60}
                        stroke={10}
                        progress={
                            (history.filter((h) => h.decision === 'accepted')
                                .length /
                                numTagExtractions) *
                            100
                        }
                    />
                    {history.filter((h) => h.decision === 'accepted').length}{' '}
                    extractions accepted
                </Grid.Column>
                <Grid.Column width={14} className="results-cards">
                    <Grid.Row>
                        <Grid.Column
                            floated="left"
                            style={{ marginBottom: -16 }}
                        >
                            Displayed data
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="results-cards-group">
                        {DECISIONS.filter((c) =>
                            ['medical_specialties', 'technology_tags'].includes(
                                c.key
                            )
                        ).map((category, index) => {
                            return (
                                <ResultsCard
                                    key={index}
                                    category={category}
                                    history={history}
                                />
                            )
                        })}
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column
                            floated="left"
                            style={{ marginBottom: -16 }}
                        >
                            Other metadata
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="results-cards-group">
                        {DECISIONS.filter((c) =>
                            [
                                'title',
                                'organization',
                                'education',
                                'function',
                            ].includes(c.key)
                        ).map((category, index) => {
                            return (
                                <ResultsCard
                                    key={index}
                                    category={category}
                                    history={history}
                                />
                            )
                        })}
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid.Column>
    )
}

export default Results
